import { computed, unref } from 'vue';

/*
 * normalize ids to a string so that we can normalize a number or
 * string type (generally from parsing URL or from data)
 *
 * @param{Number|String}: numberOrString
 * @returns{ComputedRef<string>}
 */
const normalizeId = numberOrString => {
  return computed(() => {
    const idValue = unref(numberOrString);

    if (!idValue) return idValue;

    if (!(typeof idValue === 'number') && !(typeof idValue === 'string')) {
      console.warn(idValue, 'was not a Number _or_ a String.');
      return idValue;
    }

    return idValue.toString();
  });
};

const account = accountId => ['account', normalizeId(accountId)];

const discovery = () => ['discovery'];
const media = (subjectType, fileId, params) => {
  const result = [subjectType, normalizeId(fileId), 'media'];
  if (params) result.push(params);
  return result;
};
const mediaReservationFile = (fileId, params) => {
  return media('reservation_file', fileId, params);
};

const order = orderId => {
  const result = ['order'];
  if (orderId) {
    result.push(normalizeId(orderId));
  }
  return result;
};
const products = params => ['products', params];
const reservation = reservationId => [
  'reservations',
  normalizeId(reservationId),
];
const reservationShots = reservationId => [
  ...reservation(reservationId),
  'shots',
];
const socialMedias = () => ['social-medias'];
const tag = tagId => ['tag', normalizeId(tagId)];
const user = userId => {
  const result = ['user'];
  if (userId) {
    result.push(normalizeId(userId));
  }

  return result;
};

/*
 * this MUST be the first string in a query key array if the data it returns has
 * anything to do with any type of digital asset.
 */
const digitalAssets = ['digital-assets'];
const collectionsDAStyle = ['collections-da-style'];

export const queryKeys = {
  accountExternalIdentifierTypes: accountId => {
    return [...account(accountId), 'external-identifier-types'];
  },
  accountOrders: (accountId, params) => {
    const result = [...account(accountId), 'accounts-orders'];

    if (params) {
      result.push(params);
    }

    return result;
  },
  accountOrder: (accountId, params) => [
    ...account(accountId),
    ...order(params.orderId),
    'account-order',
  ],
  accountStorageUsage: accountId => [...account(accountId), 'storage-usage'],
  account,
  accountCreditInfo: accountId => [...account(accountId), 'credit-info'],
  actionItems: (accountId, params) => {
    const result = [...account(accountId), 'actionItems'];
    if (params) result.push(params);
    return result;
  },
  accountsRoles: () => ['accounts-roles'],
  accountsUsers: params => {
    const result = ['accounts-users'];
    if (params) result.push(params);
    return result;
  },
  activeListing: (integrationId, externalProductId) => {
    return [normalizeId(integrationId), normalizeId(externalProductId)];
  },
  addresses: accountId => {
    return [...account(accountId), 'addresses'];
  },
  allEditHistory: reservationId => [reservationId, 'all-edit-history'],
  assignedReservations: (userId, params) => {
    const result = ['assignedReservations'];
    if (userId) result.push(...user(userId));
    if (params) result.push(params);

    return result;
  },
  bag: (accountId, params) => {
    const result = ['bag'];
    if (accountId) {
      result.push(...account(accountId));
    }

    if (params) {
      result.push(params);
    }

    return result;
  },
  bagCount(accountId) {
    return [...queryKeys.bag(accountId), 'count'];
  },
  // bagCollection old style
  bagCollection: (accountId, params) => {
    const result = ['bag', 'bagCollection'];
    if (accountId) {
      result.push(...account(accountId));
    }

    if (params) {
      result.push(params);
    }

    return result;
  },
  bestAutoApplyCoupon: (reservationId, products = [], orderType) => [
    ...reservation(reservationId),
    products,
    orderType,
    'bestAutoApplyCoupon',
  ],
  capability: params => {
    const result = [...user(), 'capability'];
    if (params) result.push(params);
    return result;
  },
  catalog: (accountId, params) => {
    const result = [...account(accountId), 'catalog'];
    if (params) result.push(params);
    return result;
  },
  catalogItem: (accountId, itemId) => {
    return [...queryKeys.catalog(accountId), normalizeId(itemId)];
  },
  catalogItemAmazonListing: (accountId, itemId) => {
    return [...queryKeys.catalogItem(accountId, itemId), 'amazon-listing'];
  },
  catalogItemShopifyProducts: (accountId, itemId) => {
    return [...queryKeys.catalogItem(accountId, itemId), 'shopify-product'];
  },
  catalogItemActiveListings: (accountId, itemId) => {
    return [...queryKeys.catalogItem(accountId, itemId), 'active-listings'];
  },
  catalogItemReservations: (accountId, itemId, params) => {
    const result = [
      ...queryKeys.catalogItem(accountId, itemId),
      'reservations',
    ];
    if (params) result.push(params);
    return result;
  },
  catalogItemVariants: (accountId, itemId, params) => {
    const result = [...queryKeys.catalogItem(accountId, itemId), 'variants'];
    if (params) result.push(params);
    return result;
  },
  collaborators: (accountId, params) => {
    const result = [...account(accountId), 'collaborators'];

    if (params) {
      result.push(params);
    }

    return result;
  },
  // collections RF style
  collections: params => {
    const result = ['collections'];
    if (params) result.push(params);
    return result;
  },
  // collection DA style
  collectionsDA: (accountId, collectionType, params) => {
    const result = [...collectionsDAStyle, 'account', normalizeId(accountId)];
    if (collectionType) {
      result.push('collection-type', collectionType);
    }
    if (params) {
      result.push(params);
    }
    return result;
  },
  collectionDA: (accountId, collectionType, collectionId) => {
    const result = [
      ...queryKeys.collectionsDA(accountId, collectionType),
      'collection',
    ];
    if (collectionId) {
      result.push(normalizeId(collectionId));
    }
    return result;
  },
  collectionAssets: (collectionId, params) => {
    const result = [...digitalAssets, ...collectionsDAStyle];
    if (collectionId) result.push(normalizeId(collectionId));
    if (params) {
      result.push(params);
    }
    return result;
  },
  collectionAsset: (collectionId, collectionType, cdaId, params) => {
    const result = [
      ...queryKeys.collectionAssets(collectionId),
      collectionType,
      normalizeId(cdaId),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  collectionAssetByDigitalAsset: (
    collectionId,
    collectionType,
    digitalAssetId,
    params
  ) => {
    const result = [
      ...queryKeys.collectionAssets(collectionId),
      collectionType,
      'by_digital_asset',
      normalizeId(digitalAssetId),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  competitor: (accountId, competitorId) => {
    return [...account(accountId), normalizeId(competitorId)];
  },
  competitors: (accountId, params) => {
    const result = [...account(accountId), 'competitors'];
    if (params) result.push(params);
    return result;
  },
  competitorsComparison: (listingId, competitorId) => {
    return [
      'competitors-comparison',
      normalizeId(listingId),
      normalizeId(competitorId),
    ];
  },
  creditReasons: params => {
    const result = ['credit-reasons'];
    if (params) result.push(params);
    return result;
  },
  crewAssignments: (reservationId, params) => {
    const result = [...reservation(reservationId), 'crew-assignments'];
    if (params) result.push(params);
    return result;
  },
  crewMember: userId => {
    return [...user(userId), 'crew-assignments'];
  },
  defaultPayment: accountId => {
    return [...account(accountId), 'default-payment'];
  },
  digitalAsset: (accountId, assetId, params) => {
    const result = [
      ...queryKeys.digitalAssets(accountId),
      normalizeId(assetId),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  digitalAssets: (accountId, params) => {
    const result = [...digitalAssets, ...account(accountId), 'digital_assets'];
    if (params) {
      result.push(params);
    }
    return result;
  },
  // only use this when you have none of the necessary arguments for the other related keys
  digitalAssetsObliterateAllKeys: () => digitalAssets,
  digitalAssetCatalogItems: (accountId, assetId) => {
    return [
      ...account(accountId),
      'digital_assets',
      assetId,
      'catalog_items_digital_assets',
    ];
  },
  // album collections assigned to a digital asset
  digitalAssetCollections: (accountId, assetId, params) => {
    const result = [
      ...queryKeys.digitalAsset(accountId, assetId),
      'digital_asset_collections',
    ];
    if (params) result.push(params);
    return result;
  },
  digitalAssetDerivatives: assetId => {
    return [...queryKeys.digitalAssets(assetId), 'derivatives'];
  },
  discoveryBlogCategories: () => [...discovery(), 'blog-categories'],
  discoveryBlogPosts: () => [...discovery(), 'blog-posts'],
  discoveryBlogPostsByCategory: category => [
    ...discovery(),
    'blog-posts',
    'category',
    category,
  ],
  discoveryGetInspiredItems: () => [...discovery(), 'get-inspired'],
  discoveryHeroItems: () => [...discovery(), 'hero-items'],
  duplicatePackages: (accountId, trackingNumber) => {
    const result = [...account(accountId), 'duplicate-packages'];
    if (trackingNumber) result.push(trackingNumber);
    return result;
  },
  editHistory: (reservationId, editHistoryId) => [
    reservationId,
    editHistoryId,
    'edit-history',
  ],
  embeddingsMetrics: accountId => {
    return [...account(accountId), 'embeddings-metrics'];
  },
  exampleCompetitorStaffPick: (accountId, competitorId) => {
    return [...account(accountId), normalizeId(competitorId)];
  },
  exampleCompetitorStaffPicks: accountId => {
    return [...account(accountId), 'example-competitor-staff-picks'];
  },
  exampleListing: listingId => {
    return ['example_listing', normalizeId(listingId)];
  },
  exampleListings: (accountId, params) => {
    const result = [...account(accountId), 'example_listings'];
    if (params) result.push(params);
    return result;
  },
  feedbackOptions: context => ['feedbackOptions', context],
  galleryFilterBy: (params = {}) => ['galleryFilterBy', params],
  highlightedPacks: params => {
    const result = ['highlighted-packs'];
    if (params) result.push(params);
    return result;
  },
  industries: () => ['industries'],
  integrations: accountId => {
    return [...account(accountId), 'integrations'];
  },
  inventoryItems: (accountId, params) => {
    const result = [...account(accountId), 'inventory-items'];
    if (params) result.push(params);
    return result;
  },
  inventoryItemsUnidentifiedCount: accountId => {
    return [...account(accountId), 'inventory-items-unidentified-count'];
  },
  inventoryPackage: packageId => {
    return ['inventory-package', normalizeId(packageId)];
  },
  inventoryPackages: params => {
    const result = ['inventory-packages'];
    if (params) result.push(params);
    return result;
  },
  inventoryPackagesSummary: params => {
    const result = [...queryKeys.inventoryPackages(), 'summary'];
    if (params) result.push(params);
    return result;
  },
  invitations: (accountId, params) => {
    const result = [...account(accountId), 'invitations'];
    if (params) result.push(params);
    return result;
  },
  invitationAcceptable: (accountId, invitationId, params) => {
    const result = [
      ...queryKeys.invitations(accountId),
      normalizeId(invitationId),
      'acceptable',
    ];
    if (params) result.push(params);
    return result;
  },
  lastSupportedLocation: reservationId => [
    ...reservation(reservationId),
    'lastSupportedLocation',
  ],
  listingAction: (accountId, listingActionId) => {
    return [
      ...account(accountId),
      'listingAction',
      normalizeId(listingActionId),
    ];
  },
  listingActions: (accountId, params) => {
    const result = [...account(accountId), 'listingActions'];
    if (params) result.push(params);
    return result;
  },
  listingActionsMissingContentOpportunities: accountId => {
    return [...account(accountId), 'listingActionsMissingContentOpportunities'];
  },
  listingActionsTopOpportunities: accountId => {
    return [...account(accountId), 'listingActionsTopOpportunities'];
  },
  listing: (accountId, listingId) => {
    return [...account(accountId), normalizeId(listingId)];
  },
  listings: (accountId, params) => {
    const result = [normalizeId(accountId), 'listings'];
    if (params) result.push(params);
    return result;
  },
  listingInsights: (accountId, integrationId) => [
    'listingInsights',
    accountId,
    integrationId,
  ],
  locations: locationTypes => ['locations', locationTypes],
  locationAvailability: (params = {}) => ['locationAvailability', params],
  locationVideoTypes: locationId => {
    return [locationId, 'locationVideoTypes'];
  },
  media,
  mediaCollectionFile: (collectionId, fileId, params) => {
    const result = [...mediaReservationFile(fileId, params)];
    result.push('collection', collectionId);
    return result;
  },
  mediaReservationFile,
  mokkerAITemplatesRedirect: (accountId, templateId) => {
    return [...account(accountId), 'mokker-templates-redirect', templateId];
  },
  mokkerAvailableCredits: accountId => {
    return [...account(accountId), 'mokker-available-credits'];
  },
  mokkerDigitalAsset: (assetUuid, params) => {
    const result = [
      ...digitalAssets,
      'mokker_digital_assets',
      normalizeId(assetUuid),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  mokkerChildGenerations: (assetUuid, params) => {
    const result = [
      ...queryKeys.mokkerDigitalAsset(assetUuid),
      'child_generations',
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  notes: (subjectType, subjectId, params) => {
    const result = ['notes', subjectType, normalizeId(subjectId)];
    if (params) result.push(params);
    return result;
  },
  noteTypes: () => ['note-types'],
  order,
  orders: (accountId, params) => {
    const result = ['orders', accountId];

    if (params) result.push(params);
    return result;
  },
  orderAddOns: (orderId, params) => {
    const result = [...order(orderId), 'addOns'];

    if (params) result.push(params);

    return result;
  },
  orderAddOn: (orderId, addOnLineItemId) => [
    ...order(orderId),
    'addOns',
    normalizeId(addOnLineItemId),
  ],
  orderReservationFile: (orderId, reservationFileId = null) => {
    const result = [...order(orderId), 'reservation_file'];

    if (reservationFileId) result.push(normalizeId(reservationFileId));

    return result;
  },
  packConfigurations: params => {
    const result = ['pack-configurations'];
    if (params) result.push(params);
    return result;
  },
  packConfiguration: packId => [
    ...queryKeys.packConfigurations(),
    'pack-id',
    packId,
  ],
  productsAndBundles: locationId => {
    return ['products', 'products_and_bundles', normalizeId(locationId)];
  },
  promotion: promotionId => ['promotion', promotionId],
  promotions: promotionId => ['promotions', promotionId],
  promotionWidgets: widgetType => ['promotion-widgets', widgetType],
  defaultSubscriptionPaymentMethod: () => [
    'default-subscription-payment-method',
  ],
  proServiceProducts: () => ['pro-service-products'],
  proServiceProfiles: params => {
    const result = ['pro-service-profiles'];
    if (params) result.push(params);
    return result;
  },
  proServiceProfile: proServiceProfileId => [
    'pro-service-profile-id',
    proServiceProfileId,
  ],
  products,
  quest: questId => ['quest', questId],
  recentlyWrappedReservations: accountId => {
    return [...account(accountId), 'recently-wrapped-reservations'];
  },
  reEditsCollectionDigitalAsset: (collectionId, cdaId) => {
    return [
      ...queryKeys.collectionAssets(collectionId),
      're_edits_collection_digital_assets',
      normalizeId(cdaId),
    ];
  },
  reservation,
  reservationAvailabilityErrors: reservationId => [
    'reservationAvailabilityErrors',
    reservationId,
  ],
  reservationDigitalAssetByDigitalAssetId: (
    reservationId,
    digitalAssetId,
    params
  ) => {
    const result = [
      ...queryKeys.reservationDigitalAssets(reservationId),
      'by_digital_asset',
      normalizeId(digitalAssetId),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  reservationDigitalAsset: (reservationId, rdaId, params) => {
    const result = [
      ...queryKeys.reservationDigitalAssets(reservationId),
      normalizeId(rdaId),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  reservationDigitalAssets: (reservationId, params) => {
    const result = [...digitalAssets, 'reservation_digital_assets'];
    if (reservationId) {
      result.push(normalizeId(reservationId));
    }
    if (params) {
      result.push(params);
    }
    return result;
  },
  reservationDigitalAssetMediaAddOns: (
    reservationId,
    reservationDigitalAssetId = null
  ) => {
    const result = [
      ...reservation(reservationId),
      'reservationFileMediaAddOns',
    ];
    if (reservationDigitalAssetId) result.push(reservationDigitalAssetId);
    return result;
  },
  reservationDownPaymentCredits: (reservationId, params) => {
    const result = [...reservation(reservationId), 'down-payment-credits'];
    if (params) result.push(params);
    return result;
  },
  reservationFile: (reservationId, reservationFileId) => {
    return [
      ...reservation(reservationId),
      'reservation_file',
      normalizeId(reservationFileId),
    ];
  },
  reservationFiles: params => {
    const result = ['reservation_file'];
    if (params) {
      result.push(params);
    }
    return result;
  },
  reservationFileCatalogItems: reservationFileId => {
    return [reservationFileId, 'reservationFileCatalogItems'];
  },
  reservationFilePurchasedOriginals: reservationId => {
    const result = [
      ...reservation(reservationId),
      'reservationFilePurchasedOriginals',
    ];
    return result;
  },
  reservationFileMediaAddOns: (reservationId, reservationFileId = null) => {
    const result = [
      ...reservation(reservationId),
      'reservationFileMediaAddOns',
    ];
    if (reservationFileId) result.push(reservationFileId);
    return result;
  },
  reservationFileVariations: (reservationId, reservationFileId = null) => {
    const result = [...reservation(reservationId), 'reservationFileVariations'];
    if (reservationFileId) result.push(normalizeId(reservationFileId));
    return result;
  },
  reservationFileVideoTypes: locationId => {
    return [locationId, 'reservationFileVideoTypes'];
  },
  reservationLineItems: (reservationId, orderId, digitalAssetId) => {
    return [
      'reservation-line-items',
      normalizeId(reservationId),
      normalizeId(orderId),
      normalizeId(digitalAssetId),
    ];
  },
  reservationLocations: (reservationId, locationTypes) => [
    ...reservation(reservationId),
    'locationTypes',
    locationTypes,
  ],
  reservationOrders: reservationId => [...reservation(reservationId), 'orders'],
  reservationPaidOrderSummary: reservationId => [
    ...reservation(reservationId),
    'reservation_paid_order_summary',
  ],
  reservationReadinessSteps: reservationId => [
    ...reservation(reservationId),
    'reservationReadinessSteps',
  ],
  reservationSuggestedLocation: reservationId => [
    ...reservation(reservationId),
    'suggestedLocation',
  ],
  reservationTagCategories: (
    reservationId,
    tagCategorySlug,
    tagSubcategory = null,
    discountId = null
  ) => [
    ...reservation(reservationId),
    'tagCategory',
    tagCategorySlug,
    'tagSubcategory',
    tagSubcategory,
    'discountId',
    discountId,
  ],
  reservationTimeline: (reservationId, timezone = null) => {
    const result = [...reservation(reservationId), 'reservationTimeline'];
    if (timezone) result.push(timezone);
    return result;
  },
  reservations: params => {
    const result = ['reservations'];
    if (params) result.push(params);
    return result;
  },
  salesTax: ({ orderTotal, billingAddressId, orderTaxBreakdown }) => {
    // payments_todo: look into simplifying orderTaxBreakdown
    return [
      'sales-tax',
      'order-total',
      orderTotal,
      'address-id',
      billingAddressId,
      'tax-breakdown',
      orderTaxBreakdown,
    ];
  },
  sharedGallery: (hash, params) => {
    const result = ['shared-gallery'];
    if (hash) {
      result.push('hash');
      result.push(hash);
    }

    if (params) {
      result.push(params);
    }

    return result;
  },
  schedulingPreference: reservationId => {
    return [...reservation(reservationId), 'scheduling-preference'];
  },
  shopMoreDigitalAssets: (accountId, params) => {
    const result = [...queryKeys.digitalAssets(accountId), 'shop_more'];
    if (params) {
      result.push(params);
    }
    return result;
  },
  shopMoreDigitalAsset: (accountId, digitalAssetId, params) => {
    const result = [
      ...queryKeys.shopMoreDigitalAssets(accountId),
      'by_digital_asset',
      normalizeId(digitalAssetId),
    ];
    if (params) {
      result.push(params);
    }
    return result;
  },
  reservationShots,
  reservationShotList: reservationId => [
    ...reservationShots(reservationId),
    'list',
  ],
  reservationShot: (reservationId, shotId) => [
    ...reservationShots(reservationId),
    normalizeId(shotId),
  ],
  reservationActiveShot: reservationId => [
    ...reservationShots(reservationId),
    'activeShot',
  ],
  shotTags: (params = {}) => ['shot-tags', params],
  socialMedias,
  socialMediaSizes: () => [...socialMedias(), 'sizes'],
  subscriptionCancellationReasons: () => ['subscription-cancellation-reasons'],
  tiers: () => ['tiers'],
  tierBySlug: (tierSlug, params) => {
    const result = [...queryKeys.tiers(), 'slug', tierSlug];
    if (params) result.push(params);
    return result;
  },
  subscriptionProratedAmount: (subscriptionId, params) => [
    'subscription-prorated-amount',
    subscriptionId,
    params,
  ],
  tag,
  tagCategories: (params = {}) => ['tag-categories', params],
  tagCategoriesProducts: (locationId, shootType, discountId) => {
    const result = [
      'tag-categories-products',
      normalizeId(locationId),
      shootType,
    ];
    if (discountId) result.push(normalizeId(discountId));
    return result;
  },
  tagFollowUpCategories: tagId => [...tag(tagId), 'tag-follow-up-categories'],
  tags: (params = {}) => ['tags', params],
  invoices: (accountId, params) => {
    const result = [...account(accountId), 'invoices'];

    if (params) {
      result.push(params);
    }

    return result;
  },
  temporaryListingScore: (images, external_provider) => {
    return ['temporary_listing_score', images, external_provider];
  },
  topChoicesOnReservation: (reservationId, params = {}) => [
    ...reservation(reservationId),
    params,
  ],
  transferPackageRequirements: (params = {}) => [
    'transfer-package-requirements',
    params,
  ],
  unBaggedTotal: (accountId, collectionId, reservationId) => {
    return [
      ...account(accountId),
      'collection',
      collectionId,
      'reservation',
      reservationId,
      'un_bagged_total',
    ];
  },
  user,
  userChatAuth: userId => [...user(userId), 'chat-auth'],
  userAuthToken: userId => [...user(userId), 'auth-token'],
};
