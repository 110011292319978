<script setup>
import { computed } from 'vue';
import { convertToMMDDYYYY } from '@/lib/date-formatters';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
// TODO: APL-1207 apollo_subscription_upgrades
import { useFlag } from '@/composables/useFlag';
import { PeriwinkBlue20, PeriwinkBlue80 } from 'src/variables.module.scss';
import BillingCycleToggle from '@/components/modal-payment-flows/subscriptions/BillingCycleToggle.vue';
import ChatBanner from '@/components/modal-payment-flows/subscriptions/ChatBanner.vue';
import ChatBannerV2 from '@/components/modal-payment-flows/subscriptions/ChatBannerV2.vue';
import InfoVideo from '@/components/modal-payment-flows/subscriptions/InfoVideo.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import StepLayout from '@/components/modal-payment-flows/subscriptions/StepLayout.vue';
import SubscriptionProductTierCard from '@/components/subscriptions/SubscriptionProductTierCard.vue';
import SubscriptionProductTierCardV2 from '@/components/subscriptions/SubscriptionProductTierCardV2.vue';

const props = defineProps({
  allTiers: {
    default: () => [],
    type: Array,
  },
  accountId: {
    required: true,
    type: [Number, String],
  },
  activeSubscription: {
    default: () => ({}),
    type: Object,
  },
  context: {
    default: null,
    type: String,
  },
  headingId: {
    default: 'subscriptions-dialog-label',
    type: String,
  },
  isLoading: {
    default: false,
    type: Boolean,
  },
  isTrialing: {
    default: false,
    type: Boolean,
  },
  price: {
    default: () => ({}),
    required: true,
  },
  selectedTier: {
    default: () => ({}),
    type: Object,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    required: true,
    type: String,
  },
  offerTrial: {
    default: false,
    type: Boolean,
  },
});

const emits = defineEmits([
  'back',
  'close',
  'next',
  'update:price',
  'select-tier',
]);

const { inputChanged, linkClicked } = useBaseEvents();

const pegasusTrialSubscriptionsFlag = useFlag(
  'pegasus_accept_trial_subscriptions'
);
const apolloV2CardsFlag = useFlag('apollo_subscription_tier_cards_v2');

const isLoading = computed(() => props.isLoading);
const selectedTier = computed(() => props.selectedTier);
const showBackButton = computed(() => props.showBackButton);

const trackLinkClicked = (label, href = null) => {
  linkClicked({
    context: props.context,
    subContext: props.subContext,
    linkLabel: label,
    linkHref: href,
  });
};

const handleBillingCycleChange = price => {
  emits('update:price', price);

  inputChanged({
    context: props.context,
    subContext: props.subContext,
    inputLabel: price.recurring_interval,
    inputType: 'radio',
    inputValue: price,
  });
};

const handleManagePlanClick = (label, href) => {
  emits('close');
  trackLinkClicked(label, href);
};

const handleUpgradeClick = (tier, label) => {
  emits('select-tier', tier);
  emits('next', 'checkout');
  trackLinkClicked(label);
};

const subscriptionButtonCopy = () => {
  if (props.offerTrial) {
    return 'try it free for 7 days';
  } else {
    return 'choose plan';
  }
};
</script>

<template>
  <StepLayout
    class="pricing-step"
    :heading-id="headingId"
    :is-two-column="false"
    :show-back-button="showBackButton"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template v-if="!activeSubscription?.id" #header>
      <BillingCycleToggle
        :selected-billing-interval="price?.recurring_interval"
        :prices="selectedTier?.product?.prices"
        @change="handleBillingCycleChange"
      />
      <InfoVideo :context="context" :sub-context="subContext" />
    </template>
    <template #heading>plans designed to grow your biz</template>
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <img
      class="pricing-step__star"
      src="@images/subscriptions/north_star.svg"
      alt=""
    />
    <div
      v-if="apolloV2CardsFlag"
      class="pricing-step__card-container pricing-step--clean"
    >
      <SubscriptionProductTierCardV2
        v-for="tier in allTiers"
        :key="tier.title"
        :selected-billing-interval="price?.recurring_interval"
        :tier="tier"
        :best-offer-border="tier.slug === 'tier-two'"
      >
        <SoonaButton
          v-if="
            (pegasusTrialSubscriptionsFlag && isTrialing) ||
            (activeSubscription?.tier?.slug !== tier.slug && !isTrialing)
          "
          variation="solid-black"
          :data-cypress="`button-subscription-card-${tier.slug}`"
          @on-click="
            handleUpgradeClick(tier, subscriptionButtonCopy(tier.slug))
          "
        >
          {{ subscriptionButtonCopy(tier.slug) }}
        </SoonaButton>
        <SoonaButton
          v-else
          element="router-link"
          :to="`/account/${accountId}/subscriptions`"
          variation="secondary-black"
          :data-cypress="`link-subscription-card-${tier.slug}`"
          @on-click="
            handleManagePlanClick(
              tier,
              'manage plan',
              `/account/${accountId}/subscriptions`
            )
          "
        >
          manage plan
        </SoonaButton>
      </SubscriptionProductTierCardV2>
    </div>
    <div v-else class="pricing-step__card-container">
      <SubscriptionProductTierCard
        v-for="tier in allTiers"
        :key="tier.title"
        :selected-billing-interval="price?.recurring_interval"
        :tier="tier"
      >
        <SoonaFlag
          v-if="isTrialing && activeSubscription?.tier?.slug === tier.slug"
          class="pricing-step__trial-flag"
          :background-color="PeriwinkBlue20"
          :text-color="PeriwinkBlue80"
          title="free trial"
        />
        <p
          v-if="isTrialing && activeSubscription?.tier?.slug === tier.slug"
          class="u-label--small pricing-step__renewal-date"
        >
          expires {{ convertToMMDDYYYY(activeSubscription?.trial_end) }}
        </p>
        <SoonaButton
          v-if="
            (pegasusTrialSubscriptionsFlag && isTrialing) ||
            (activeSubscription?.tier?.slug !== tier.slug && !isTrialing)
          "
          style="margin-top: auto"
          variation="solid-black"
          :data-cypress="`button-subscription-card-${tier.slug}`"
          @on-click="
            handleUpgradeClick(tier, subscriptionButtonCopy(tier.slug))
          "
        >
          {{ subscriptionButtonCopy(tier.slug) }}
        </SoonaButton>
        <SoonaButton
          v-else
          style="margin-top: auto"
          element="router-link"
          :to="`/account/${accountId}/subscriptions`"
          variation="secondary-black"
          :data-cypress="`link-subscription-card-${tier.slug}`"
          @on-click="
            handleManagePlanClick(
              tier,
              'manage plan',
              `/account/${accountId}/subscriptions`
            )
          "
        >
          manage plan
        </SoonaButton>
      </SubscriptionProductTierCard>
    </div>
    <ChatBannerV2
      v-if="apolloV2CardsFlag"
      :context="context"
      :sub-context="subContext"
    />
    <ChatBanner v-else :context="context" :sub-context="subContext" />
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.pricing-step {
  &__star {
    display: none;

    @media (min-width: variables.$screen-sm-min) {
      display: block;
      position: absolute;
      top: 3rem;
      right: 3rem;
    }
  }

  &__card-container {
    display: grid;
    gap: 2.5rem;
    grid-template-columns: repeat(auto-fit, minmax(min(14rem, 100%), 1fr));
    padding-bottom: 0.5rem;
    padding-top: 1rem;

    @media (min-width: variables.$screen-sm-min) {
      gap: 1rem;
    }
  }

  &--clean {
    padding-top: 1.5rem;
  }

  &__trial-flag {
    margin: 0 auto;
  }

  &__renewal-date {
    align-items: center;
    color: variables.$gray-60;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1rem 0;
  }
}

.tier-card--clean .tier-card__bottom .soona-button {
  margin: auto 5rem;
}
</style>
