<script setup>
import { ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useTimeoutFn, onKeyStroke } from '@vueuse/core';
import uniqueId from 'lodash/uniqueId';
import { useDialogContext } from '@/composables/useDialog';

const emits = defineEmits(['close']);

const popoverRef = ref(null);
const id = uniqueId('competitors-popover-dialog-');
const { checkAllowEscapeClose } = useDialogContext({ id });

const closingState = ref(false);

const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  () => {
    closingState.value = true;
    emits('close');
  },
  150,
  { immediate: false }
);

const close = () => {
  if (!isClosing.value) beginClose();
};

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    close();
  }
});
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="competitors-popover"
      :class="{
        'competitors-popover--closing': closingState,
      }"
      role="dialog"
      aria-modal="true"
    >
      <div class="competitors-popover__header--wrapper">
        <div class="competitors-popover__header">
          <slot name="header"></slot>
          <SoonaButton
            class="competitors-popover__close"
            variation="icon-gray-outline"
            @click="close"
          >
            <SoonaIcon name="xmark" />
            <span class="u-a11y-only">Close</span>
          </SoonaButton>
        </div>
        <div class="competitors-popover__subheader">
          <slot name="subheader"></slot>
        </div>
      </div>
      <div class="competitors-popover__content">
        <slot name="content"></slot>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.competitors-popover {
  position: relative;
  background: variables.$gradient-peri-background;
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-y: scroll;
  inset: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: variables.$white-default;
    padding: 1rem;

    &--wrapper {
      position: sticky;
      top: 0;
      background-color: variables.$white-default;
      box-shadow: variables.$elevation-0;
      z-index: 1;
    }
  }
}
</style>
