<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingActionTemplate from './ListingActionTemplate.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import ListingActionUploadDialog from './ListingActionUploadDialog.vue';
import { useCreateListingActionDigitalAsset } from '@/queries/useListingActionDigitalAssets';
import { useDeleteListingActionDigitalAssets } from '@/queries/useListingActions';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { usePriorityError } from '@/composables/usePriorityError';
import { useUpdateListingAction } from '@/queries/useListingActions';
import { useCreateBulkActionTask } from '@/queries/useDigitalAssetBulkActionTasks';
import { useCreatePublishTask } from '@/queries/useDigitalAssetPublishTasks';
import ComparisonViewer from './ComparisonViewer.vue';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePlatformName } from './usePlatformName';
import { useTargetSize } from '@/components/user/anytime/listing_insights/listing_actions/actions/useTargetSize';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  listingAction: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['showSubscriptionsDialog']);

const { accountId, listingAction } = toRefs(props);
const listingActionId = computed(() => listingAction.value.id);

const { disablePublishFlow } = useDisablePublishFlow({
  accountId: accountId,
});

const { buttonClicked } = useBaseEvents();

const hasReplacementAsset = computed(() => {
  return listingAction.value.replacement_asset_preview_url;
});

const { platform } = usePlatformName(listingAction);
const { targetSize } = useTargetSize(listingAction);

// loading state
const optimizeTriggered = ref(false);
const isOptimizing = computed(() => {
  if (listingAction.value.most_recent_bulk_action) {
    return listingAction.value.most_recent_bulk_action.processing;
  } else {
    return optimizeTriggered.value;
  }
});

const publishTriggered = ref(false);
const isPublishing = computed(() => {
  if (listingAction.value.most_recent_publish_task) {
    return listingAction.value.most_recent_publish_task.processing;
  } else {
    return publishTriggered.value;
  }
});

// optimize module
const {
  canTakeAction,
  availableCreditsError,
  optimizeButtonVariation,
  isBasicUserAndOutOfCredits,
  userCanUpgrade,
} = useSubscriptionActionPermissions(accountId);

// upload image module
const isProcessing = ref(false);
const showUploadDialog = ref(false);

const {
  mutate: createListingActionDigitalAsset,
  error: createListingActionDigitalAssetError,
} = useCreateListingActionDigitalAsset(accountId);

const { mutate: updateListingAction } = useUpdateListingAction(
  accountId,
  listingActionId
);
const { mutate: createBulkActionTask } = useCreateBulkActionTask(accountId);
const { mutate: createPublishTask } = useCreatePublishTask(accountId);

const linkUploadedAsset = digitalAssetId => {
  createListingActionDigitalAsset(
    {
      digital_asset_id: digitalAssetId,
      listing_action_id: listingAction.value.id,
    },
    {
      onSuccess: () => {
        isProcessing.value = false;
      },
      onError: () => {
        isProcessing.value = false;
      },
    }
  );
};

const processBulkAction = sourceAssetId => {
  createBulkActionTask(
    {
      digital_asset_ids: [sourceAssetId],
      bulk_action: 'upscale_resolution',
    },
    {
      onSuccess: () => {
        optimizeTriggered.value = false;
      },
    }
  );
};
const linkSourceAsset = digitalAssetId => {
  updateListingAction(
    {
      source_asset_id: digitalAssetId,
    },
    {
      onSuccess: data => {
        processBulkAction(data.source_asset_id);
      },
    }
  );
};

const { mutate: createDigitalAsset, error: createDigitalAssetError } =
  useCreateDigitalAsset(accountId);

const handleOptimizeClick = () => {
  if (canTakeAction.value) {
    optimizeTriggered.value = true;
    linkSourceAsset(listingAction.value.original_asset.id);
    buttonClicked({
      context: 'listing insights',
      subContext: 'optimize image size',
      buttonLabel: 'optimize',
      buttonAction: 'upscale resolution',
    });
  } else {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: 'optimize image size',
      buttonLabel: 'optimize',
      buttonAction: 'upgrade',
    });
  }
};

const handleUploadComplete = blob => {
  isProcessing.value = true;
  showUploadDialog.value = false;

  const digitalAsset = {
    asset_type: 'original',
    file: blob.signed_id,
    title: blob.filename,
    media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
    ownership: 'customer',
    origin: 'customer',
    origin_source: 'manual_upload',
    visibility: 'all',
  };

  createDigitalAsset(digitalAsset, {
    onSuccess: data => {
      linkUploadedAsset(data.id);
    },
  });
};

// undo changes module
const {
  mutate: deleteListingDigitalAssets,
  error: deleteListingDigitalAssetsError,
} = useDeleteListingActionDigitalAssets(accountId);

const undoChanges = () => {
  deleteListingDigitalAssets(listingAction.value.id);
  buttonClicked({
    context: 'listing insights',
    subContext: 'optimize image size',
    buttonLabel: 'undo changes',
    buttonAction: 'undo changes',
  });
};

// publish module
const handlePublish = () => {
  publishTriggered.value = true;

  buttonClicked({
    context: 'listing insights',
    subContext: 'optimize image size',
    buttonLabel:
      listingAction.value === 'failed_to_publish'
        ? 'retry'
        : `publish to ${platform.value}`,
    buttonAction: 'publish',
  });

  createPublishTask(
    {
      publish_assets: [
        {
          digital_asset_id: listingAction.value.replacement_asset.id,
          external_product_id: listingAction.value.external_product_id,
          image_position: listingAction.value.original_asset_image_position,
        },
      ],
    },
    {
      onSuccess: () => {
        publishTriggered.value = false;
      },
    }
  );
};

// comparison viewer module
const showComparison = ref(false);

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: 'optimize image size',
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: 'optimize image size',
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

const resetListingAction = () => {
  updateListingAction({
    status: 'pending_approval',
  });
  buttonClicked({
    context: 'listing insights',
    subContext: 'optimize image size',
    buttonLabel: 'cancel',
    buttonAction: 'reset listing action',
  });
};

const handleDownload = () => {
  if (listingAction.value?.replacement_asset?.file_url) {
    window.open(listingAction.value.replacement_asset.file_url, '_blank');
  }
  buttonClicked({
    context: 'listing insights',
    subContext: 'optimize image size',
    buttonLabel: 'download',
    buttonAction: 'download replacement asset',
  });
};

// error handling
const priorityError = usePriorityError(
  createListingActionDigitalAssetError,
  createDigitalAssetError,
  deleteListingDigitalAssetsError,
  availableCreditsError
);
</script>

<template>
  <ListingActionTemplate
    :is-optimizing="isOptimizing"
    :is-publishing="isPublishing"
    :is-processing="isProcessing"
    :listing-action="listingAction"
    @open-comparison-viewer="showComparison = true"
  >
    <template #error>
      <SoonaError v-if="priorityError" no-margin>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #listing-rule>
      <div class="listing-action-rule">
        <SoonaIcon :name="hasReplacementAsset ? 'image-square' : 'ruler'" />
        <div class="listing-action-rule__text">
          <dl v-if="hasReplacementAsset">
            <div class="listing-action-rule__text--replacement">
              <dt>optimized image</dt>
              <dd>{{ listingAction.replacement_asset.resolution }}</dd>
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
            <div class="listing-action-rule__text--original">
              <dt>original image</dt>
              <dd>{{ listingAction.original_asset.resolution }}</dd>
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
          </dl>
          <dl v-else>
            <div>
              <dt>current size</dt>
              <dd>{{ listingAction.original_asset.resolution }}</dd>
            </div>
            <div>
              <dt>target size</dt>
              <dd>{{ targetSize }}</dd>
            </div>
          </dl>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="listing-action-active__buttons">
        <template v-if="listingAction.status === 'active'">
          <SoonaButton
            v-if="isBasicUserAndOutOfCredits"
            variation="pizzazz"
            @click="handleUpgradeClick"
          >
            upgrade for more
          </SoonaButton>
          <template v-else>
            <SoonaButton
              :variation="optimizeButtonVariation"
              size="medium"
              @click="handleOptimizeClick"
            >
              optimize
            </SoonaButton>
            <SoonaButton
              v-if="!disablePublishFlow && canTakeAction"
              variation="tertiary"
              size="medium"
              @click="showUploadDialog = true"
            >
              upload new
            </SoonaButton>
          </template>
        </template>
        <template v-else-if="listingAction.status === 'failed_to_publish'">
          <SoonaButton variation="primary" size="medium" @click="handlePublish">
            retry
          </SoonaButton>
          <SoonaButton
            variation="tertiary"
            size="medium"
            @click="resetListingAction"
          >
            cancel
          </SoonaButton>
        </template>
        <template v-else-if="disablePublishFlow">
          <SoonaButton
            :variation="optimizeButtonVariation"
            size="medium"
            @click="handleDownload"
          >
            download
          </SoonaButton>
        </template>
        <template v-else>
          <SoonaButton
            variation="solid-black"
            size="medium"
            @click="handlePublish"
          >
            publish to {{ platform }}
          </SoonaButton>
          <SoonaButton variation="tertiary" size="medium" @click="undoChanges">
            undo changes
          </SoonaButton>
        </template>
      </div>
    </template>
  </ListingActionTemplate>
  <ListingActionUploadDialog
    v-if="showUploadDialog"
    :account-id="accountId"
    :platform="platform"
    @upload-complete="handleUploadComplete"
    @close="showUploadDialog = false"
  />
  <ComparisonViewer
    v-if="showComparison"
    :listing-action-slug="listingAction.listing_action_slug"
    :original-asset="listingAction.original_asset"
    :replacement-asset="listingAction.replacement_asset"
    @close="showComparison = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-action-rule {
  display: flex;
  gap: 0.5rem;

  &__text {
    dl {
      div {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        dt {
          font-weight: bold;
        }
      }
    }

    &--original {
      color: variables.$gray-50;
    }
  }
}

.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>
