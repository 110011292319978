<script setup>
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref } from 'vue';
import { queryKeys } from 'src/queries/query-keys';
import { toCurrency } from '@/lib/currency';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useBulkCreditEvents } from '@/composables/segment/useBulkCreditEvents';
import { useIntegrations } from '@/composables/useIntegrations';
import { useStore } from 'vuex';
import { useFlag } from '@/composables/useFlag';
import { Roses30 } from 'src/variables.module.scss';
import SoonaPayment from 'src/components/SoonaPayment.vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';
import BillingInfo from 'src/components/checkout/BillingInfo.vue';
import PlanSelect from 'src/components/ui_library/PlanSelect.vue';
import StepLayout from 'src/components/modal-payment-flows/preferred/StepLayout.vue';
import { useQueryClient } from '@tanstack/vue-query';
import { useSalesTax } from '@/composables/sales_tax/useSalesTax';
import EstimatedSalesTax from '@/components/sales_tax/EstimatedSalesTax.vue';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';

const props = defineProps({
  accountId: {
    type: [Number, String],
    default: undefined,
  },
  allPlans: {
    type: Array,
  },
  context: {
    type: String,
  },
  currentCreditProduct: {
    type: Object,
  },
  featureList: {
    type: Array,
  },
  featureSubtitle: {
    type: String,
  },
  selectedPlan: {
    type: Object,
  },
  showBackButton: {
    default: false,
    type: Boolean,
  },
  subContext: {
    type: String,
  },
});

const emits = defineEmits(['back', 'close', 'next', 'select-plan']);

const salesTaxStore = useSalesTaxStore();

const queryClient = useQueryClient();

const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
const paymentsSalesTaxFlag = useFlag('payments_sales_tax');

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

const { inputChanged, linkClicked } = useBaseEvents();
const { bulkCreditsCheckoutStarted } = useBulkCreditEvents();
const store = useStore();

const isProcessing = ref(false);

const allPlans = computed(() => props.allPlans);
const currentCreditProduct = computed(() => props.currentCreditProduct);
const selectedPlan = computed(() => props.selectedPlan);
const showBackButton = computed(() => props.showBackButton);
const accountId = computed(() => props.accountId);
const pricePlanSubtotal = computed(() => +selectedPlan.value?.price || 0);

const dropdownOptions = computed(() => {
  return (
    allPlans.value.map(p => ({
      label: p.dropdown_label,
      value: p,
    })) || []
  );
});

const { hasShopifyIntegration } = useIntegrations(accountId);

const orderTaxBreakdown = computed(() => {
  return [
    {
      discounted_rate: pricePlanSubtotal.value,
      product_id: currentCreditProduct.value?.id,
      product_tax_code: currentCreditProduct.value?.tax_code,
    },
  ];
});

const {
  totalWithSurchargeDisplay,
  taxAmountExclusiveDisplay,
  salesTaxBillingAddressId,
  stripeSalesTaxCalculationId,
  taxAmountExclusiveInCents,
  isLoading: isCalculatingSalesTax,
} = useSalesTax({
  accountId,
  preSalesTaxSubtotal: pricePlanSubtotal,
  orderTaxBreakdown,
});

const clearAdditionalChargeItems = () => {
  return store.dispatch('account/clearAdditionalChargeItems');
};

const createAdditionalChargeOrder = args => {
  return store.dispatch('account/createAdditionalChargeOrder', args);
};

const createPaymentMethodAdditionalChargeOrder = args => {
  return store.dispatch(
    'account/createPaymentMethodAdditionalChargeOrder',
    args
  );
};

const setAdditionalChargeImmediately = () => {
  return store.dispatch('account/setAdditionalChargeImmediately', true);
};

const completeCheckout = async (stripe, card, saveCard, elements) => {
  isProcessing.value = true;

  try {
    if (!hasShopifyIntegration.value) await setAdditionalChargeImmediately();

    await createAdditionalChargeOrder({
      isShopifyOrder: hasShopifyIntegration.value,
      stripe: stripe,
      card: selectedPlan.value ? card : null,
      saveCard: saveCard,
      elements: elements,
    });
    await clearAdditionalChargeItems();

    linkClicked({
      context: props.context,
      subContext: props.subContext,
      linkLabel: 'confirm & pay',
      linkHref: null,
    });

    const invalidations = [
      queryClient.invalidateQueries({ queryKey: queryKeys.bag() }),
      queryClient.invalidateQueries({ queryKey: queryKeys.capability() }),
    ];

    if (accountId.value) {
      invalidations.push(
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        })
      );
    }

    await Promise.all(invalidations);

    emits('next');
  } finally {
    isProcessing.value = false;
  }
};

const completePaymentMethodCheckout = async (
  paymentMethod,
  paymentMethodType,
  savePaymentMethod
) => {
  isProcessing.value = true;

  try {
    if (!hasShopifyIntegration.value) await setAdditionalChargeImmediately();

    await createPaymentMethodAdditionalChargeOrder({
      isShopifyOrder: hasShopifyIntegration.value,
      paymentMethod: paymentMethod,
      paymentMethodType: paymentMethodType,
      savePaymentMethod: savePaymentMethod,
      salesTax: {
        id: stripeSalesTaxCalculationId.value,
        soona_billing_address_id: salesTaxBillingAddressId.value,
        tax_amount_exclusive: taxAmountExclusiveInCents.value,
      },
    });
    await clearAdditionalChargeItems();

    linkClicked({
      context: props.context,
      subContext: props.subContext,
      linkLabel: 'confirm & pay',
      linkHref: null,
    });

    const invalidations = [
      queryClient.invalidateQueries({ queryKey: queryKeys.bag() }),
      queryClient.invalidateQueries({ queryKey: queryKeys.capability() }),
    ];

    if (accountId.value) {
      invalidations.push(
        queryClient.invalidateQueries({
          queryKey: queryKeys.account(accountId),
        })
      );
    }

    await Promise.all(invalidations);

    emits('next');
  } finally {
    isProcessing.value = false;
  }
};

const onSelect = option => {
  emits('select-plan', option.value);

  inputChanged({
    context: props.context,
    subContext: props.subContext,
    inputLabel: option.label,
    inputType: 'select',
    inputValue: currentCreditProduct.value,
  });
};

const totalDisplay = computed(() => {
  if (paymentsSalesTaxFlag.value) {
    return totalWithSurchargeDisplay;
  } else {
    return toCurrency(selectedPlan?.value.price);
  }
});

// lifecycle hooks
onMounted(() => {
  if (!selectedPlan.value) emits('select-plan', props.allPlans[0]);

  bulkCreditsCheckoutStarted({
    context: props.context,
    subContext: props.subContext,
    products: currentCreditProduct.value,
  });
});

onBeforeUnmount(() => {
  clearAdditionalChargeItems();
});

onUnmounted(() => {
  salesTaxStore.$reset();
});
</script>

<template>
  <StepLayout
    class="checkout-step"
    :right-column-bg-color="Roses30"
    :show-back-button="showBackButton"
    :show-right-column-border="true"
    @back="emits('back')"
    @close="emits('close')"
    @next="emits('next')"
  >
    <template #heading>add payment info</template>
    <div class="checkout-step__select-container">
      <PlanSelect
        v-model:model-value="selectedPlan"
        placeholder="select plan"
        :options="dropdownOptions"
        @option:selecting="onSelect"
      />
    </div>
    <BillingInfo class="checkout-step__billing-info" :account-id="accountId" />
    <SoonaPaymentMethods
      v-if="paymentsPaymentMethodsFlag && !hasShopifyIntegration"
      :on-payment-action="completePaymentMethodCheckout"
      :stripe-payment-required="!hasShopifyIntegration"
      :is-stripe-disclaimer-below-checkout-button="!hasShopifyIntegration"
      :total="selectedPlan?.price"
      :account-id="accountId"
      :disable-confirm-button="isCalculatingSalesTax"
      :payment-method-types="['card', 'us_bank_account']"
      action-text="confirm & pay"
    >
      <template #action-block>
        <div class="checkout-step__info-container">
          <p v-if="selectedPlan?.save_badge" class="checkout-step__savings">
            <span>savings</span>
            <span>{{
              toCurrency(
                +(
                  selectedPlan?.price *
                  (selectedPlan?.save_badge.replace(/%/g, '') / 100)
                ) || 0,
                'USD',
                2
              )
            }}</span>
          </p>
          <p v-if="paymentsSalesTaxFlag" class="checkout-step__surcharge">
            <EstimatedSalesTax class="checkout-step__surcharge-text" />
            <span v-if="isCalculatingSalesTax">calculating...</span>
            <span v-else>
              {{ taxAmountExclusiveDisplay }}
            </span>
          </p>
          <p class="checkout-step__total">
            <span class="u-subheader--all-caps-black">total</span>
            <span
              class="u-headline--heavy"
              data-cypress="text-bulk-credits-modal-total"
            >
              {{ totalDisplay }}
            </span>
          </p>
        </div>
      </template>
    </SoonaPaymentMethods>
    <SoonaPayment
      v-else
      :on-payment-action="completeCheckout"
      :stripe-payment-required="!hasShopifyIntegration"
      :is-stripe-disclaimer-below-checkout-button="!hasShopifyIntegration"
      action-text="confirm & pay"
    >
      <template #action-block>
        <div class="checkout-step__info-container">
          <p v-if="selectedPlan?.save_badge" class="checkout-step__savings">
            <span>savings</span>
            <span>{{
              toCurrency(
                +(
                  selectedPlan?.price *
                  (selectedPlan?.save_badge.replace(/%/g, '') / 100)
                ) || 0,
                'USD',
                2
              )
            }}</span>
          </p>
          <p class="checkout-step__total">
            <span class="u-subheader--all-caps-black">total</span>
            <span
              class="u-headline--heavy"
              data-cypress="text-bulk-credits-modal-total"
            >
              {{ toCurrency(+selectedPlan?.price || 0, 'USD', 0) }}
            </span>
          </p>
        </div>
      </template>
    </SoonaPayment>
    <p class="u-small--regular checkout-step__tos">
      by continuing you agree to our
      <a href="https://soona.co/terms" target="_blank" rel="noopener noreferrer"
        >terms of service</a
      >

      ({{ termsOfServiceDate }}) and
      <a
        href="https://soona.co/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        >privacy policy</a
      >
      for soona preferred. actual savings may be greater. credits are valid for
      one year after date of purchase.
    </p>
    <template #main-image>
      <img src="@images/bulk-credits/payment@2x.jpg" alt="" />
    </template>
    <template #right-col-content>
      <p class="u-body--heavy checkout-step__testimonial">
        soona preferred has saved me so much time. my life is easier, less
        stressful, and has empowered my team to purchase assets or anything they
        need. I would recommend soona and soona preferred to other businesses!
      </p>
      <div class="checkout-step__citation">
        <img src="@images/bulk-credits/headshot@2x.png" alt="" />
        <div class="checkout-step__citation-right">
          <p class="u-button--large-caps">Emelia DiBello</p>
          <p class="u-badge--small">co-founder | The Earthling Co</p>
        </div>
      </div>
    </template>
  </StepLayout>
</template>

<style lang="scss" scoped>
@use '@/variables';

.checkout-step {
  &__select-container {
    margin-bottom: 1rem;
    position: relative;

    :deep(.vs__dropdown-menu) {
      top: 100% !important;
      left: 0 !important;
      width: 100% !important;
    }
  }

  &__billing-info {
    margin-bottom: 1rem;
  }

  &__info-container {
    margin-top: 2rem;
  }

  &__savings {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25rem;
  }

  &__surcharge {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.25rem;

    &-text {
      display: flex;
      gap: 0.25rem;
    }
  }

  &__total {
    display: flex;
    justify-content: space-between;
  }

  &__includes-heading {
    padding-bottom: 0.75rem;
  }

  :deep(.soona-payment__submit) {
    width: 100%;
  }

  &__tos {
    padding-top: 1rem;

    a {
      text-decoration: underline;
    }
  }

  &__testimonial {
    padding-bottom: 2rem;
  }

  &__citation {
    display: flex;
    gap: 0.75rem;

    img {
      border-radius: 50%;
      height: 2.5rem;
      object-fit: cover;
      width: 2.5rem;
    }
  }

  &__citation-right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}
</style>
