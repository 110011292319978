<script setup>
import { computed, ref } from 'vue';
import unescape from 'lodash/unescape';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ActionItem from './ActionItem.vue';
import ReturnAddressModal from '@/components/user/anytime/inventory/return-address/ReturnAddressModal.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  actionItems: {
    type: Object,
    required: true,
  },
});

const accountId = computed(() => props.accountId);
const actionItems = computed(() => props.actionItems);

const incrementDelta = 0.15;
const delayBasePackagesPendingReturn = computed(
  () => incrementDelta * actionItems.value.today.length
);
const delayBaseUnscheduled = computed(
  () => delayBasePackagesPendingReturn.value + incrementDelta
);
const delayBasePending = computed(
  () =>
    delayBaseUnscheduled.value +
    incrementDelta * actionItems.value.unscheduled_with_action_items.length
);
const delayBaseSuggested = computed(
  () =>
    delayBasePending.value +
    incrementDelta * actionItems.value.pending_selects.length
);
const delayBaseNext = computed(
  () =>
    delayBaseSuggested.value +
    incrementDelta * actionItems.value.suggested_display.length
);

// return address dialog
const showReturnAddressDialog = ref(false);

const displayReturnAddressActionItem = computed(
  () =>
    actionItems.value.pending_return.has_packages_missing_return_address ||
    actionItems.value.pending_return.needs_return_address
);

const returnAddressActionItemType = computed(() =>
  actionItems.value.pending_return.needs_return_address
    ? 'default-return-address'
    : 'package-return-address'
);
</script>

<template>
  <section class="action-items">
    <header class="action-items__header">
      <h3 id="dashboard-pro-services" class="u-title--heavy">action items</h3>
      <router-link
        class="u-label--regular action-items__bookings-link"
        :to="`/account/${accountId}/bookings`"
      >
        all bookings
        <SoonaIcon name="arrow-right" size="small" />
      </router-link>
    </header>
    <ul class="action-items__list">
      <ActionItem
        v-for="(item, index) in actionItems.pending_selects"
        :key="item.id"
        :item="item"
        type="pending-selects"
        :animation-delay="delayBasePending + incrementDelta * index"
      >
        {{ unescape(item.name) }}
        <template #pill>time to check out!</template>
      </ActionItem>
      <ActionItem
        v-for="(item, index) in actionItems.today"
        :key="item.id"
        :item="item"
        type="today"
        :animation-delay="incrementDelta * index"
      >
        {{ unescape(item.name) }}
        <template #pill>today</template>
      </ActionItem>
      <ActionItem
        v-if="displayReturnAddressActionItem"
        :account-id="accountId"
        :item="actionItems.pending_return"
        :type="returnAddressActionItemType"
        :animation-delay="delayBasePackagesPendingReturn + incrementDelta"
        @open-dialog="showReturnAddressDialog = true"
      >
        add a return address
        <template #pill>pending return</template>
      </ActionItem>
      <ActionItem
        v-for="(item, index) in actionItems.unscheduled_with_action_items"
        :key="item.id"
        :item="item"
        type="unscheduled"
        :animation-delay="delayBaseUnscheduled + incrementDelta * index"
      >
        {{ unescape(item.name) }}
        <template #pill>unscheduled</template>
      </ActionItem>
      <ActionItem
        v-for="(item, index) in actionItems.suggested_display"
        :key="item.id"
        :item="item"
        type="suggested"
        :animation-delay="delayBaseSuggested + incrementDelta * index"
      >
        {{ unescape(item.name) }}
      </ActionItem>
      <ActionItem
        v-for="(item, index) in actionItems.after_today_display"
        :key="item.id"
        :item="item"
        type="next"
        :animation-delay="delayBaseNext + incrementDelta * index"
      >
        {{ unescape(item.name) }}
        <template #pill>next up</template>
      </ActionItem>
    </ul>
    <ReturnAddressModal
      v-if="showReturnAddressDialog"
      :account-id="accountId"
      @cancel="showReturnAddressDialog = false"
      @success="showReturnAddressDialog = false"
    />
  </section>
</template>

<style scoped lang="scss">
@use 'src/variables';

.action-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  container: action-items / inline-size;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__bookings-link {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    gap: 0.25rem;

    &:hover {
      text-decoration: underline;
      color: variables.$black-default;
    }
  }
}
</style>
