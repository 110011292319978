<script setup>
import { computed, toRefs } from 'vue';

import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

import dayImage from '@images/booking/membership-day-pass-card.svg';
import basicImage from '@images/booking/membership-tier-one-card.svg';
import standardImage from '@images/booking/membership-tier-two-card.svg';

const props = defineProps({
  title: {
    default: null,
    type: String,
  },
  description: {
    default: null,
    type: String,
  },
  membership: {
    type: String,
    default: 'basic',
    validator: function (value) {
      return ['basic', 'standard', 'day'].includes(value);
    },
  },
});

const { membership } = toRefs(props);

const imageMap = {
  day: dayImage,
  basic: basicImage,
  standard: standardImage,
};

const membershipImage = computed(() => imageMap[membership.value]);

const tooltipCopy = computed(() => {
  switch (membership.value) {
    case 'basic':
      return 'The Basic membership is ideal for more than one shoot a year. It includes basic access to our AI tools and no studio fees.';
    case 'standard':
      return 'The Standard membership is ideal if you use models, styling, upgrades, premium editing or have 3+ shoots a year. It includes full access to our AI tools, 10% models + upgrades, free premium edits and no studio fees.';
    case 'day':
      return 'The Studio pass is ideal if you just have one shoot a year. Customers must pay a $149 studio fee on every booking unless they are a Basic or Standard subscriber.';
    default:
      return '';
  }
});
</script>

<template>
  <div class="membership-card" :class="{ 'white-text': membership !== 'day' }">
    <img alt="" :src="membershipImage" class="membership-card__image" />

    <div class="membership-card__content">
      <div class="membership-card__logo">
        <img src="@images/soona-logo-white.svg" />
      </div>

      <div>
        <h3 class="membership-card__title">
          {{ title }}
        </h3>

        <p class="membership-card__description">
          {{ description }}
        </p>
      </div>
    </div>

    <div class="membership-card__tooltip">
      <SoonaTooltip>
        <template #default="{ setRef, mouseenter, focus, mouseleave, blur }">
          <div
            :ref="el => setRef(el)"
            size="small"
            class="membership-card__tooltip__icon"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
          >
            <SoonaIcon
              size="small"
              class="tooltip-info-icon"
              name="circle-information"
            />
          </div>
        </template>
        <template #tooltip-content>{{ tooltipCopy }}</template>
      </SoonaTooltip>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.membership-card {
  display: flex;
  width: 10.5rem;
  user-select: none;
  position: relative;
  min-height: 5.65rem;

  &.white-text {
    color: variables.$white-default;
  }

  &__content {
    flex: 1;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5rem 0 0.625rem 0.875rem;
  }

  &__image {
    width: 10.5rem;
    inset: -0.225rem;
    object-fit: cover;
    position: absolute;
  }

  &__logo {
    width: 3rem;
    object-fit: contain;
  }

  &__title {
    font-weight: 800;
    font-size: 0.685rem;
  }

  &__description {
    opacity: 0.6;
    font-size: 0.625rem;
  }

  &__tooltip {
    top: 0.5rem;
    right: 1rem;
    position: absolute;

    &__icon {
      color: inherit;
    }
  }
}
</style>
