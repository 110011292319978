<script setup>
import { computed, ref } from 'vue';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import UploadRaw from './UploadRaw.vue';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';
import { useRecommendAnimatedCollection } from '@/queries/animated_collections/useRecommendAnimatedCollection';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { queryKeys } from '@/queries/query-keys';
import { useQueryClient } from '@tanstack/vue-query';
import HideAssetButton from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/HideAssetButton.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { storeToRefs } from 'pinia';

defineProps({
  selectedVariation: {
    type: String,
  },
  variations: {
    type: Array,
  },
});

const emits = defineEmits(['select-variation']);

const queryClient = useQueryClient();

const { addToast } = useSoonaToast();

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId, activeAction } = storeToRefs(mediaEditorStore);

const {
  asset,
  isOriginal,
  isGif,
  isBagged,
  animatedCollectionId,
  gifHasAlreadyBeenRecommended,
  rotation,
  notRotated,
  reservationId,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { mutate: updateDigitalAsset, isPending: isUpdatingDigitalAsset } =
  useUpdateDigitalAsset(assetAccountId);

const { mutate: recommendAnimatedCollection, isPending: isRecommendingGif } =
  useRecommendAnimatedCollection(assetAccountId, animatedCollectionId);

const rotationMessage = computed(() =>
  notRotated.value ? 'original rotation/0º' : `${rotation.value}º from original`
);

const rotateLeft = () => {
  const existingRotation = rotation.value ?? 0;
  const toRotate = existingRotation === 0 ? 270 : existingRotation - 90;
  updateDigitalAsset(
    {
      assetId: assetId,
      body: {
        rotation: toRotate,
      },
    },
    {
      onError: () => {
        addToast(`error rotating asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

const rotateRight = () => {
  const existingRotation = rotation.value ?? 0;
  const toRotate = existingRotation === 270 ? 0 : existingRotation + 90;
  updateDigitalAsset(
    {
      assetId: assetId,
      body: {
        rotation: toRotate,
      },
    },
    {
      onError: () => {
        addToast(`error rotating asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

const showRecommendGifModal = ref(false);

const recommendGif = () => {
  if (!isGif.value) return;

  recommendAnimatedCollection(
    {},
    {
      onError: () => {
        addToast(`error recommending gif`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
      onSuccess: () => {
        showRecommendGifModal.value = false;

        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssetByDigitalAssetId(
            reservationId,
            assetId
          ),
        });
      },
    }
  );
};

const isLoading = computed(
  () => !!isUpdatingDigitalAsset.value || !!isRecommendingGif.value
);

const animatedCollectionUrl = computed(() => {
  const queries = reservationId.value
    ? `?reservation_id=${reservationId.value}`
    : '';

  return `/account/${assetAccountId.value}/animation/${animatedCollectionId.value}${queries}`;
});
</script>

<template>
  <div class="staff-action-bar">
    <div class="staff-action-bar__left">
      <SoonaSegmentedRadios
        v-if="isOriginal && !isGif"
        class="variation-picker"
        label="visible variant"
        :label-visually-hidden="true"
        name="visible-variant"
        :options="variations"
        :model-value="selectedVariation"
        @update:model-value="emits('select-variation', $event)"
      />
    </div>
    <div class="staff-action-bar__right">
      <!-- rotation -->
      <SoonaButton
        v-if="isOriginal"
        class="rotate-left"
        variation="icon-gray-outline"
        :loading="isLoading"
        :disabled="isLoading || !!activeAction"
        :title="`rotate left 90º (currently ${rotationMessage})`"
        @click="rotateLeft"
      >
        <SoonaIcon name="rotate-left" />
      </SoonaButton>
      <SoonaButton
        v-if="isOriginal"
        class="rotate-right"
        variation="icon-gray-outline"
        :loading="isLoading"
        :disabled="isLoading || !!activeAction"
        :title="`rotate right 90º (currently ${rotationMessage})`"
        @click="rotateRight"
      >
        <SoonaIcon name="rotate-right" />
      </SoonaButton>
      <!-- show/hide -->
      <HideAssetButton v-if="!isBagged" />
      <!-- upload raw -->
      <UploadRaw
        v-if="asset"
        :digital-asset="asset"
        variation="secondary-gray"
      />
      <!-- recommend gif -->
      <SoonaButton
        v-if="isGif"
        class="recommend-animation"
        variation="secondary-gray"
        :loading="isLoading"
        :disabled="isLoading || gifHasAlreadyBeenRecommended"
        @click="() => (showRecommendGifModal = true)"
      >
        {{ gifHasAlreadyBeenRecommended ? 'recommended' : 'recommend gif' }}
      </SoonaButton>
      <!-- edit gif (temporary placement) -->
      <SoonaButton
        v-if="isGif"
        element="router-link"
        :to="animatedCollectionUrl"
        variation="secondary-gray"
        size="large"
      >
        edit gif
      </SoonaButton>
    </div>

    <SoonaDialog
      v-if="showRecommendGifModal"
      @close="() => (showRecommendGifModal = false)"
    >
      <template #heading>holler at the client</template>
      <div class="body">
        <p class="explanation">
          an email will be sent to the client to let them know about this
          exciting new creation! click 'email' if you'd like them to be notified
          or cancel if you are not quite ready yet.
        </p>
      </div>
      <template #footer="{ close }">
        <SoonaButton
          variation="tertiary"
          data-cypress="button-dialog-cancel"
          @on-click="close"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="recommendGif"
        >
          {{ isRecommendingGif ? 'recommending' : 'email' }}
        </SoonaButton>
      </template>
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.staff-action-bar {
  background-color: variables.$periwink-blue-10;
  border-bottom: 0.625rem solid variables.$gray-30;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  height: max-content;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  width: 100%;

  &__left {
    display: flex;
    gap: 0.25rem;

    .staff-action-bar__left-btn:first-child {
      border-top-left-radius: 0.3125rem;
      border-bottom-left-radius: 0.3125rem;
    }

    .staff-action-bar__left-btn:last-child {
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
  }

  &__left-btn {
    background-color: variables.$gray-20;
    border: 0.625rem solid variables.$gray-20;
    height: 2.5rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-30;
    }

    &:active {
      background-color: variables.$gray-20;
    }
  }

  &__right {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }
}

.rotate-left {
  align-self: center;
  top: 0.125rem;
  position: relative;
  transition: transform 0.25s ease-in-out;

  &:not(:disabled):hover {
    transform: rotate(-30deg);
  }
}

.rotate-right {
  align-self: center;
  top: 0.125rem;
  position: relative;
  transition: transform 0.25s ease-in-out;

  &:not(:disabled):hover {
    transform: rotate(30deg);
  }
}

.variation-picker {
  margin-bottom: 0;
}
</style>
