<script setup>
import { computed, ref, toRefs, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { onKeyStroke } from '@vueuse/core';
import { convertToHHMM12, convertToMMDDYY } from '@/lib/date-formatters';
import {
  AvoToast20,
  AvoToast60,
  BlackDefault,
  FriendlyRed30,
  FriendlyRed60,
  Gray20,
  Tangerine30,
} from '@/variables.module.scss';
import AssetDetails from '@/components/dam/asset_comparison/AssetDetails.vue';
import ComparisonItem from '@/components/dam/asset_comparison/ComparisonItem.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  backBtnCopy: {
    default: 'back',
    type: String,
  },
  comparisonAssets: {
    default: () => [],
    type: Array,
  },
  errors: {
    default: () => [],
    type: Array,
  },
  nextRoute: {
    default: null,
    type: Object,
  },
  originalAsset: {
    required: true,
    type: Object,
  },
  previousRoute: {
    default: null,
    type: Object,
  },
});

const emit = defineEmits(['back']);

const router = useRouter();

const { comparisonAssets, nextRoute, originalAsset, previousRoute } =
  toRefs(props);

onKeyStroke('ArrowLeft', () => {
  if (!previousRoute.value) return;

  router.replace(previousRoute.value);
});

onKeyStroke('ArrowRight', () => {
  if (!nextRoute.value) return;

  router.replace(nextRoute.value);
});

onKeyStroke('ArrowUp', e => {
  if (!e.altKey) return;
  emit('back');
});

const left = ref(null);
const right = ref(null);

const formatOption = da => {
  let editStatus = 'unknown';
  switch (da.edit_status) {
    case 'original':
      editStatus = 'studio original';
      break;
    case 'accepted':
      editStatus = 'pro edit';
      break;
    case 'pending':
      editStatus = 'pending';
      break;
    case 'rejected':
      editStatus = 'hidden';
      break;
  }
  return {
    label: `${editStatus} · ${convertToMMDDYY(da.created_at)} ${convertToHHMM12(da.created_at)}`,
    value: da,
  };
};

const flagData = asset => {
  if (!asset?.edit_status) return null;

  if (asset.edit_status === 'accepted') {
    return {
      backgroundColor: AvoToast60,
      textColor: AvoToast20,
      title: 'edit delivered',
    };
  }

  if (asset.edit_status === 'pending') {
    return {
      backgroundColor: Tangerine30,
      textColor: BlackDefault,
      title: 'edit pending',
    };
  }

  if (asset.visibility !== 'all') {
    return {
      backgroundColor: Gray20,
      textColor: BlackDefault,
      title: 'hidden',
    };
  }

  return {
    backgroundColor: FriendlyRed30,
    textColor: FriendlyRed60,
    title: 'needs edit',
  };
};

const speed = asset => {
  if (!asset?.options?.speed) return '';

  const speedOption = asset.options.speed;

  return `${speedOption} ms, ${(1000 / speedOption).toFixed(1)} frames/s`;
};

const options = computed(() => {
  if (!originalAsset.value) return [];

  const opts = [formatOption(originalAsset.value)];

  comparisonAssets.value.forEach(comparison =>
    opts.push(formatOption(comparison))
  );

  return opts;
});

const firstPending = options =>
  options.value.find(opt => opt.value?.edit_status === 'pending')?.value;

watchEffect(() => {
  const existingLeft = options.value.find(c => c.value.id === left.value?.id);
  const existingRight = options.value.find(c => c.value.id === right.value?.id);

  left.value = existingLeft?.value ?? options.value.at(0)?.value;

  if (options.value.length > 1) {
    right.value =
      existingRight?.value ??
      firstPending(options) ??
      options.value.at(-1)?.value;
  } else {
    // for edge cases where asset value lingers
    right.value = null;
  }
});
</script>

<template>
  <SoonaError v-if="errors" :priority-errors="errors" />
  <div class="asset-comparison">
    <div class="header">
      <div class="header-left">
        <div class="header-left-top">
          <SoonaButton
            size="medium"
            variation="secondary-gray"
            @click="emit('back')"
          >
            {{ backBtnCopy }}
          </SoonaButton>
          <div class="navigation">
            <div class="prev-btn">
              <SoonaButton
                v-if="previousRoute"
                element="router-link"
                :to="previousRoute"
                variation="icon-gray-outline"
                replace
              >
                <SoonaIcon name="arrow-left" />
                <span class="u-a11y-only">Previous (left arrow)</span>
              </SoonaButton>
            </div>
            <SoonaButton
              v-if="nextRoute"
              element="router-link"
              :to="nextRoute"
              variation="icon-gray-outline"
              replace
            >
              <SoonaIcon name="arrow-right" />
              <span class="u-a11y-only">Next (right arrow)</span>
            </SoonaButton>
          </div>
          <slot name="header-left-top" />
        </div>
        <slot name="header-left-bottom" />
      </div>
      <slot name="header-right" />
    </div>
    <div class="asset-comparison__inner">
      <div class="asset-comparison__assets">
        <ComparisonItem v-model:asset="left" :options="options">
          <template #footer>
            <AssetDetails
              v-if="left || options.length >= 2"
              v-model:asset="left"
              :flag-data="flagData(left)"
              :options="options"
              :speed="speed(left)"
            >
              <template #actions>
                <slot name="left-actions" :selected-asset="left" />
              </template>
              <template #details>
                <slot name="left-details" />
              </template>
            </AssetDetails>
          </template>
        </ComparisonItem>
        <ComparisonItem v-model:asset="right" :options="options">
          <template #footer>
            <AssetDetails
              v-if="right || options.length >= 2"
              v-model:asset="right"
              :flag-data="flagData(right)"
              :options="options"
              :speed="speed(right)"
            >
              <template #actions>
                <slot name="right-actions" :selected-asset="right" />
              </template>
              <template #details>
                <slot name="right-details" />
              </template>
            </AssetDetails>
          </template>
        </ComparisonItem>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.asset-comparison {
  display: flex;
  flex-direction: column;
  position: relative;

  .header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    padding: 0.5rem 1rem;
  }

  .header-left {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .header-left-top {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  .navigation {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  .prev-btn {
    min-width: 2.5rem;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: calc(100dvh - var(--app-header-height));
  }

  &__assets {
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow-x: auto;
  }
}
</style>
