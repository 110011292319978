<script setup>
import SoonaSearchFilter from '@/components/ui_library/SoonaSearchFilter.vue';

const searchText = defineModel('searchText', {
  type: String,
  required: true,
});
const selectedFilter = defineModel('selectedFilter', {
  type: String,
  required: true,
});
const sortBy = defineModel('sortBy', {
  type: Object,
  required: true,
});

const filterOptions = ['active', 'draft', 'archived', 'props'];

const sortOptions = [{ value: 'name', label: 'name' }];
</script>

<template>
  <SoonaSearchFilter
    v-model:search-text="searchText"
    v-model:selected-filter="selectedFilter"
    v-model:sort-by="sortBy"
    search-placeholder="search products"
    :filter-options="filterOptions"
    :sort-by-options="sortOptions"
    straight-top
  />
</template>
