<script setup>
import { computed, toRefs, onMounted, provide, ref, watch } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import AIEditorIframe from '@/components/user/anytime/gallery/media-editor/media-multiplier/ai/AIEditorIframe.vue';
import { useMokkerAITemplatesUrl } from '@/queries/mokker/useMokkerTemplateUrl';
import { useTitle } from '@vueuse/core';
import SubscriptionsDialog from '@/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { MEDIA_POPOVER_BACK_KEY } from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRouter } from 'vue-router';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const { accountId } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();

useTitle('AI templates | soona');

const mediaEditorLandingPageUrl = {
  name: 'media-editor',
  params: {
    accountId: accountId.value,
  },
};

provide(MEDIA_POPOVER_BACK_KEY, mediaEditorLandingPageUrl);

const iframeLoaded = ref(false);
const iframeUrl = ref('');

const { data: mokkerTemplatesUrl } = useMokkerAITemplatesUrl(
  accountId,
  'templates'
);

const mokkerTemplatesUrlValue = computed(
  () => mokkerTemplatesUrl.value?.result || ''
);

watch(mokkerTemplatesUrlValue, () => {
  iframeUrl.value = mokkerTemplatesUrlValue.value;
});

const showPaywallDialog = ref(false);

const router = useRouter();

const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});
</script>

<template>
  <div class="ai-studio-templates">
    <div class="ai-studio-templates-landing-page">
      <AIEditorIframe
        :is-contained="true"
        :iframe-url="iframeUrl"
        @iframe-loaded="iframeLoaded = true"
        @close-iframe="
          () => {
            mediaEditorStore.setIframeUrl(null);
            router.push(mediaEditorLandingPageUrl);
          }
        "
      />
    </div>
  </div>
  <SubscriptionsDialog
    v-if="showPaywallDialog"
    class="ai-studio-templates__paywall-dialog"
    selected-tier-slug="tier-one"
    @close="() => (showPaywallDialog = false)"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ai-studio-templates {
  inset: 0;
  position: absolute;

  :deep(.subscriptions-dialog) {
    z-index: 20;
  }
}
</style>
