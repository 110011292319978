import { computed } from 'vue';

export function useTargetSize(listingAction) {
  const targetSize = computed(() => {
    const originalSize = listingAction.value.original_asset.resolution;
    const [width, height] = originalSize.split('x').map(Number);

    let targetWidth, targetHeight;

    if (width > height) {
      targetWidth = 1500;
      targetHeight = Math.trunc((height / width) * 1500);
    } else {
      targetHeight = 1500;
      targetWidth = Math.trunc((width / height) * 1500);
    }

    return `${targetWidth}x${targetHeight}`;
  });
  return { targetSize };
}
