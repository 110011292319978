<script setup>
import { ref, computed } from 'vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';
const props = defineProps({
  proServiceTraitCategory: Object,
  modelValue: Array,
});
const emits = defineEmits(['update:modelValue']);
const selectedTags = computed(() => props.modelValue);
const open = ref(true);
const proServiceTraitCategory = computed(() => props.proServiceTraitCategory);
const id = computed(
  () =>
    'pro-service-filter-' +
    proServiceTraitCategory.value.title.replace(/\W/g, '-')
);
const filteredProServiceTags = computed(() => {
  return proServiceTraitCategory.value.tags.filter(
    t => t.title !== 'no preference'
  );
});
function isSelected(tag) {
  let isChecked = selectedTags.value.find(item => item === tag.id);
  return typeof isChecked != 'undefined';
}
function traitSelect(item) {
  let indexNumber = selectedTags.value.findIndex(tag => tag === item.id);
  let proxy = [...selectedTags.value];
  if (indexNumber > -1) {
    proxy.splice(indexNumber, 1);
  } else {
    proxy.push(item.id);
  }
  emits('update:modelValue', proxy);
}
function tagInCategory(tagIn) {
  return filteredProServiceTags.value.some(tag => tag.id === tagIn);
}
function handleResetFilterOptions() {
  if (selectedTags.value.length > 0) {
    const newSelectedTags = selectedTags.value.filter(
      tag => !tagInCategory(tag)
    );
    emits('update:modelValue', newSelectedTags);
  }
}
function toggleOpen() {
  open.value = !open.value;
}
</script>
<template>
  <div class="pro-service-filter">
    <div class="filter-header">
      <p>{{ proServiceTraitCategory.title }}</p>
      <span class="filter-header__buttons">
        <SoonaButton
          variation="tertiary"
          size="medium"
          @click="() => handleResetFilterOptions()"
        >
          reset
        </SoonaButton>
        <button
          :id="`${id}-trigger`"
          class="u-button-reset"
          :aria-expanded="open"
          :aria-controls="`${id}-region`"
          type="button"
          @click="toggleOpen"
        >
          <SoonaIcon
            :class="['header-chevron', { open }]"
            name="chevron-down"
            size="medium"
          />
          <span class="u-a11y-only"
            >toggle {{ proServiceTraitCategory.title }} filters visibility</span
          >
        </button>
      </span>
    </div>
    <div
      :id="`${id}-region`"
      role="region"
      class="filter-container"
      :aria-labelledby="`${id}-trigger`"
      :hidden="!open"
    >
      <SoonaToggle
        v-for="tag in filteredProServiceTags"
        :key="tag.id"
        type="checkbox"
        :label="tag.title"
        :model-value="isSelected(tag)"
        @update:model-value="traitSelect(tag)"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';
.pro-service-filter {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-top: 0.0625rem solid variables.$gray-60;
  padding: 1rem 0;
  .filter-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
    p {
      font-size: 0.875rem;
      font-weight: 800;
    }
    &__buttons {
      display: flex;
      gap: 1.5rem;
      :deep(.soona-button) {
        min-width: 0;
      }
      .header-chevron {
        color: variables.$gray-90;
        transition: transform 300ms ease-out;
        cursor: pointer;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
  }
  .filter-container {
    display: flex;
    flex-direction: column;
    max-height: 15.625rem;
    overflow: hidden;
    overflow-y: auto;
    &[hidden] {
      display: none;
    }
  }
}
.last {
  border-bottom: 0.0625rem solid variables.$gray-60;
}
</style>
