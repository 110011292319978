<template>
  <SoonaDialog class="reference-img-modal" @close="closeRefImgModal">
    <template #heading>add a reference image</template>
    <template #header>
      <p>select from your existing images or upload a new one below!</p>
    </template>
    <div class="reference-img-modal__img-container">
      <SoonaUpload
        class="reference-img-modal__uploader"
        class-overide="reference-img-modal__uploader"
        :upload-complete="blob => handleImageUploadComplete(blob, tagCategory)"
        :hide-icon="false"
        :is-multiple="false"
        label="add photo"
        accept=".jpeg, .jpg, .png, .gif"
      />
      <button
        v-for="tag in userTagsForReferenceImageCategory"
        :key="`img-${tag.id}`"
        class="reference-img-modal__img-btn"
        :aria-pressed="selectedRefImgsIds.includes(tag.id)"
        :title="tag.title"
        @click="selectedRefImgList(tagCategory.id, tag)"
      >
        <img :src="tag.image_url" alt="selectable uploaded image" />
      </button>
    </div>
    <SoonaButton
      v-if="showLoadMore"
      class="reference-img-modal__load-more-btn"
      variation="tertiary"
      @on-click="loadFiles"
    >
      load more images
    </SoonaButton>
    <template #footer>
      <div class="reference-img-modal__action-btns">
        <SoonaButton variation="secondary-gray" @on-click="closeRefImgModal">
          cancel
        </SoonaButton>
        <SoonaButton
          :disabled="selectedRefImgsIds.length === 0"
          @on-click="submitRefImgTag()"
        >
          {{ submitBtnCopy }}
        </SoonaButton>
      </div>
    </template>
  </SoonaDialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';

export default {
  name: 'ReferenceImgModal',
  components: { SoonaButton, SoonaDialog, SoonaUpload },
  props: {
    tagCategory: Object,
  },
  emits: ['close-dialog'],
  data() {
    return {
      errors: [],
      customTagReferenceImage: {},
      canCreateCustomTag: true,
      createTagTagCategoryId: undefined,
      selectedRefImgsIds: [],
      page: 1,
    };
  },
  computed: {
    ...mapGetters('shotList', [
      'savedSelectedReferenceImgIds',
      'referenceImageTCAccountTags',
      'isReferenceImageLastPage',
    ]),
    ...mapState({
      currentShot: state => state.shotList.currentShot,
    }),
    userTagsForReferenceImageCategory() {
      return this.referenceImageTCAccountTags
        ? this.referenceImageTCAccountTags.filter(
            tag =>
              tag.display_rule === 'true' &&
              !this.savedSelectedReferenceImgIds(this.currentShot).includes(
                tag.id
              )
          )
        : [];
    },
    showLoadMore() {
      return (
        this.userTagsForReferenceImageCategory.length !== 0 &&
        !this.isReferenceImageLastPage
      );
    },
    submitBtnCopy() {
      return `add ${this.selectedRefImgsIds.length} image${
        this.selectedRefImgsIds.length !== 1 ? 's' : ''
      }`;
    },
  },
  beforeMount() {
    this.loadReferenceImageTagCategory({ page: this.page });
  },
  methods: {
    ...mapActions('shotList', [
      'addReferenceImgTag',
      'createReferenceImageTag',
      'loadReferenceImageTagCategory',
    ]),
    async submitUploadableTag() {
      if (
        this.canCreateCustomTag &&
        'filename' in this.customTagReferenceImage
      ) {
        this.canCreateCustomTag = false;
        await this.createReferenceImageTag({
          tagCategoryId: this.createTagTagCategoryId,
          title: this.customTagReferenceImage.filename,
          image_blob: this.customTagReferenceImage,
        });
        this.page = 1;
        this.loadReferenceImageTagCategory({ page: this.page });
        this.canCreateCustomTag = true;
        this.customTagReferenceImage = {};
        this.createTagTagCategoryId = undefined;
      }
    },
    async submitRefImgTag() {
      //this.$track('Add Images Clicked', { current_shot: this.currentShot });
      let tagList = this.referenceImageTCAccountTags.filter(tag =>
        this.selectedRefImgsIds.includes(tag.id)
      );
      try {
        await this.addReferenceImgTag(tagList);
        this.$emit('close-dialog');
        this.selectedRefImgsIds = [];
      } catch (error) {
        this.customTagError =
          'oops! we had a problem adding your reference images.';
      }
    },
    selectedRefImgList(tagCategoryId, tag) {
      this.errors = this.errors.filter(e => e.id != tagCategoryId);

      if (this.selectedRefImgsIds.includes(tag.id)) {
        //this.$track(capitalizeWords(tag.title) + ' Deselected', { tag: tag });
        this.selectedRefImgsIds = this.selectedRefImgsIds.filter(
          selectedTag => selectedTag != tag.id
        );
      } else {
        //this.$track(capitalizeWords(tag.title) + ' Selected', { tag: tag });
        this.selectedRefImgsIds = [...this.selectedRefImgsIds, tag.id];
      }
    },
    handleImageUploadComplete(blob, tagCategory) {
      this.createTagTagCategoryId = tagCategory.id;
      this.customTagReferenceImage = blob;
      this.submitUploadableTag();
    },
    closeRefImgModal() {
      this.$emit('close-dialog');
      this.selectedRefImgsIds = [];
    },
    async loadFiles() {
      this.page += 1;
      await this.loadReferenceImageTagCategory({
        page: this.page,
        append: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.reference-img-modal {
  &__img-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(8rem, 100%), 1fr));
    gap: 0.25rem;
  }

  &__uploader {
    background-color: variables.$gray-10;
    border-radius: 0.5rem;
    border: solid 0.125rem variables.$gray-30;
    color: variables.$gray-60;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 800;
    height: 6.25rem;
    overflow: hidden;
    padding: 0;
  }

  &__img-btn {
    background-color: variables.$white-default;
    border-radius: 0.5rem;
    border: solid 0.125rem variables.$white-default;
    cursor: pointer;
    height: 6.25rem;
    overflow: hidden;
    padding: 0;

    &[aria-pressed='true'] {
      border-color: variables.$black-default;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &__load-more-btn {
    display: flex;
    margin: 0 auto;
  }

  &__action-btns {
    display: flex;
    gap: 0.75rem;
  }
}

:deep(.soona-uploader) {
  .reference-img-modal__uploader {
    height: max-content;
    margin: auto 0;
  }

  input {
    cursor: pointer;
  }
}
</style>
