<template>
  <div class="admin-trend-set-builder">
    <div class="admin-trend-set-builder__header">
      <div class="admin-trend-set-builder__header-inner">
        <SoonaBackButton
          element="router-link"
          to="/admin/trend-sets"
          button-text="all tend sets"
          data-cypress="button-back-to-admin-trend-set-list-view"
        />
        <h2 class="u-body--heavy">🔨 trend set builder 🔨</h2>
      </div>
    </div>
    <div class="admin-trend-set-builder__content">
      <div class="admin-trend-set-builder__content-left">
        <div class="admin-trend-set-builder__sidebar">
          <div class="admin-trend-set-builder__sidebar-category">
            <p data-cypress="text-trend-set-badge">{{ trendSetFilterNames }}</p>
          </div>
          <div class="admin-trend-set-builder__sidebar-title">
            <SoonaTextfield
              v-if="isEditingTitle"
              v-model="trendSetTitle"
              placeholder="name your trend set"
              type="text"
              data-cypress="input-trend-set-title"
            >
              <template #helper-bottom> 25 characters max, please </template>
            </SoonaTextfield>
            <h2
              v-else
              class="u-headline--heavy"
              data-cypress="heading-trend-set-title"
            >
              {{ trendSetTitle }}
            </h2>
            <SoonaError
              v-if="titleError"
              class="admin-trend-set-builder__sidebar-error"
              data-cypress="error-trend-set-title"
            >
              {{ titleError }}
            </SoonaError>
            <button
              v-if="trendSetStatus !== 'published'"
              class="btn btn-sm btn-text"
              data-cypress="button-edit-trend-set-title"
              @click.prevent="handleTitleChange"
            >
              <SoonaIcon name="pen-square" size="medium" />
            </button>
          </div>
        </div>
      </div>
      <div class="admin-trend-set-builder__content-right">
        <form
          id="trend-set-form"
          class="admin-trend-set-builder__form"
          @submit.prevent="handleTrendSetSave"
        >
          <section class="admin-trend-set-builder__section">
            <div class="admin-trend-set-builder__section-inner">
              <SoonaTextfield
                v-model="trendSetFlag"
                label="flag callout"
                placeholder="if you want a callout to appear in a white flag over this scene image, enter it here"
                type="text"
                :disabled="trendSetStatus === 'published'"
                data-cypress="input-trend-set-flag"
              >
                <template #helper> optional </template>
                <template #helper-bottom> 13 characters max, please </template>
              </SoonaTextfield>
              <SoonaTextfield
                v-model="trendSetToolTipText"
                element="textarea"
                label='"?" tool tip text'
                placeholder="enter the description information that will appear inside the information tool tip for this scene"
                rows="4"
                type="text"
                :disabled="trendSetStatus === 'published'"
                data-cypress="input-trend-set-tool-tip-text"
              />
              <SoonaError
                v-if="trendSetToolTipTextError"
                class="admin-trend-set-builder__soona-error"
                data-cypress="error-trend-set-tool-tip-text"
              >
                please fill out this field
              </SoonaError>
              <div class="admin-trend-set-builder__field">
                <label
                  class="admin-trend-set-builder__label"
                  for="trend-set-thumbnail"
                  >upload main thumbnail for this scene</label
                >
                <img
                  v-if="trendSetThumbnailUrl"
                  class="admin-trend-set-builder__preview-image"
                  :src="trendSetThumbnailUrl"
                  alt=""
                />
                <SoonaUpload
                  id="trend-set-thumbnail"
                  class="admin-trend-set-builder__upload"
                  :class="{
                    'admin-trend-set-builder__upload--complete':
                      trendSetThumbnail,
                    'admin-trend-set-builder__error admin-trend-set-builder__error--image':
                      trendSetThumbnailError,
                  }"
                  class-overide="admin-trend-set-builder__upload-btn"
                  :upload-complete="handleTrendSetThumbnailUploadComplete"
                  :is-multiple="false"
                  label="450px x 450px, max 100kb"
                  accept=".jpeg, .jpg, .png, .gif"
                  data-cypress="upload-trend-set-thumbnail"
                />
                <div
                  v-if="trendSetThumbnail"
                  class="admin-trend-set-builder__uploaded-file"
                >
                  <SoonaIcon name="image-square" />
                  <p class="u-body--heavy">{{ trendSetThumbnailName }}</p>
                  <p class="u-label--regular">{{ trendSetThumbnailSize }}kb</p>
                  <button
                    type="button"
                    data-cypress="button-delete-trend-set-thumbnail"
                    @click="removeTrendSetThumbnail"
                  >
                    <SoonaIcon name="xmark" />
                  </button>
                </div>
                <SoonaError
                  v-if="trendSetThumbnailError"
                  data-cypress="error-trend-set-thumbnail"
                >
                  please upload an image
                </SoonaError>
              </div>
              <div class="admin-trend-set-builder__field">
                <label
                  class="admin-trend-set-builder__label"
                  for="trend-set-shot-list-image"
                  >upload shot list image for this scene (if different than main
                  thumbnail image)</label
                >
                <img
                  v-if="trendSetShotListImageUrl"
                  class="admin-trend-set-builder__preview-image"
                  :src="trendSetShotListImageUrl"
                  alt=""
                />
                <SoonaUpload
                  id="trend-set-shot-list-image"
                  class="admin-trend-set-builder__upload"
                  :class="{
                    'admin-trend-set-builder__upload--complete':
                      trendSetShotListImage,
                  }"
                  class-overide="admin-trend-set-builder__upload-btn"
                  :upload-complete="
                    blob => handleTrendSetShotListImageUploadComplete(blob)
                  "
                  :is-multiple="false"
                  label="450px x 450px, max 100kb"
                  accept=".jpeg, .jpg, .png, .gif"
                  data-cypress="upload-trend-set-shot-list-image"
                />
                <div
                  v-if="trendSetShotListImage"
                  class="admin-trend-set-builder__uploaded-file"
                >
                  <SoonaIcon name="image-square" />
                  <p class="u-body--heavy">
                    {{ trendSetShotListImageName }}
                  </p>
                  <p class="u-label--regular">
                    {{ trendSetShotListImageSize }}kb
                  </p>
                  <button
                    type="button"
                    data-cypress="button-delete-trend-set-shot-list-image"
                    @click.prevent="handleDeleteTrendSetShotListImage"
                  >
                    <SoonaIcon name="xmark" />
                  </button>
                </div>
              </div>
              <SoonaTextfield
                v-model="trendSetShotListDescription"
                label="shot list description"
                placeholder="enter the title that will appear inside the information modal for this scene"
                type="text"
                :disabled="trendSetStatus === 'published'"
                data-cypress="input-trend-set-shot-list-description"
              />
              <SoonaError
                v-if="trendSetShotListDescriptionError"
                class="admin-trend-set-builder__soona-error"
                data-cypress="error-trend-set-shot-list-description"
              >
                please fill out this field
              </SoonaError>
            </div>
          </section>
          <div class="admin-trend-set-builder__next-btn">
            <SoonaButton
              type="submit"
              :disabled="trendSetStatus === 'published'"
              data-cypress="button-save-trend-set"
            >
              save trend set
            </SoonaButton>
          </div>
        </form>
      </div>
      <SoonaLoading
        loading-text="saving your trend set..."
        :is-loading="isSaving"
      />
      <div v-if="savingSuccessful" class="admin-trend-set-builder__success">
        <div class="admin-trend-set-builder__success-circle"></div>
        <p class="u-label--heavy">success!</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';
import SoonaUpload from 'src/components/uploader/SoonaUpload.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';

export default {
  name: 'AdminTrendSetBuilder',
  components: {
    SoonaBackButton,
    SoonaButton,
    SoonaError,
    SoonaIcon,
    SoonaLoading,
    SoonaTextfield,
    SoonaUpload,
  },
  data() {
    return {
      trendSetStatus: '',
      trendSetTagCategoryId: 0,
      trendSetTagId: 0,
      trendSetFilterNames: '',
      trendSetTitle: '',
      trendSetFlag: '',
      trendSetToolTipText: '',
      trendSetThumbnail: undefined,
      trendSetThumbnailUrl: null,
      trendSetThumbnailName: '',
      trendSetThumbnailSize: '',
      trendSetShotListId: '',
      trendSetShotListImage: undefined,
      trendSetShotListImageUrl: null,
      trendSetShotListImageName: '',
      trendSetShotListImageSize: '',
      trendSetShotListImageTags: undefined,
      trendSetShotListDescription: '',
      loaded: false,
      errorMessage: '',
      titleError: '',
      showFormErrors: false,
      isEditingTitle: false,
      isSavingTitle: false,
      isSaving: false,
      savingSuccessful: false,
    };
  },
  computed: {
    ...mapState({
      contentTagId: state =>
        state.shotList.allTagCategories
          .find(tc => tc.title === 'Content')
          ?.tags?.filter(t => t.title.toLowerCase() === 'photo')[0]?.id,
      contentTagCategoryId: state =>
        state.shotList.allTagCategories
          .find(tc => tc.title === 'Type')
          ?.tags?.filter(t => t.title.toLowerCase() === 'seasonal')[0]?.id,
      referenceImageTagCategoryId: state =>
        state.shotList.allTagCategories.find(
          tc => tc.title === 'Reference Image'
        )?.id,
    }),
    isReadyToSave() {
      return (
        this.trendSetStatus !== 'published' &&
        this.trendSetTitle.length > 0 &&
        this.trendSetTitle.length < 26 &&
        this.trendSetFlag.length < 14 &&
        this.trendSetToolTipText.length > 0 &&
        this.trendSetThumbnailUrl &&
        this.trendSetShotListDescription.length > 0
      );
    },
    trendSetTitleTooLong() {
      if (this.trendSetTitle?.length > 25) {
        return 'admin-trend-set-builder__character-limit--error';
      }
      return '';
    },
    trendSetFlagTooLong() {
      if (this.trendSetFlag?.length > 13) {
        return 'admin-trend-set-builder__character-limit--error';
      }
      return '';
    },
    trendSetTitleError() {
      if (this.showFormErrors && !this.trendSetTitle) return true;
      return false;
    },
    trendSetToolTipTitleError() {
      if (this.showFormErrors && !this.trendSetToolTipTitle) return true;
      return false;
    },
    trendSetToolTipTextError() {
      if (this.showFormErrors && !this.trendSetToolTipText) return true;
      return false;
    },
    trendSetThumbnailError() {
      if (this.showFormErrors && !this.trendSetThumbnailUrl) return true;
      return false;
    },
    trendSetShotListDescriptionError() {
      if (this.showFormErrors && !this.trendSetShotListDescription) return true;
      return false;
    },
  },
  beforeMount() {
    this.refreshTrendSetConfiguration();
    this.loadTagCategories();
  },
  methods: {
    updateErrorStatus(val) {
      this.showFormErrors = val;
    },
    ...mapActions('trendSetBuilder', [
      'createShotTemplate',
      'loadTrendSet',
      'updateTrendSet',
    ]),
    ...mapActions('shotList', ['loadTagCategories']),
    refreshTrendSetConfiguration() {
      const info = {
        tag_id: this.$route.params.trendSetId,
        tag_category_id: this.$route.params.trendSetCategoryId,
      };

      this.loadTrendSet(info).then(([trendSet, error]) => {
        if (error) {
          this.errorMessage = error;
        } else {
          this.loaded = true;
          this.prefillData(trendSet);
        }
      });
    },
    prefillData(trendSet) {
      this.trendSetStatus = trendSet?.status;
      this.trendSetTagCategoryId = trendSet?.tag_category_id;
      this.trendSetTagId = trendSet?.id;
      this.trendSetFilterNames = trendSet?.filter_names;
      this.trendSetFlag = trendSet?.price_description || '';
      this.trendSetThumbnailUrl = trendSet?.image_url;
      this.trendSetTitle = trendSet?.title || '';
      this.trendSetShotListId = trendSet?.shot_templates[0]?.id;
      this.trendSetShotListDescription =
        trendSet?.shot_templates[0]?.description || '';
      this.trendSetShotListImageTags = trendSet?.shot_list_image_tags;
      this.trendSetShotListImageUrl = trendSet?.shot_list_image_url;
      this.trendSetToolTipText = trendSet?.description || '';
    },
    handleTrendSetThumbnailUploadComplete(blob) {
      this.trendSetThumbnail = blob.signed_id;
      this.trendSetThumbnailName = blob.filename;
      this.trendSetThumbnailSize = Math.floor(+blob.byte_size / 1000);
      this.trendSetThumbnailUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;
    },
    removeTrendSetThumbnail() {
      this.trendSetThumbnail = undefined;
      this.trendSetThumbnailName = '';
      this.trendSetThumbnailSize = '';
      this.trendSetThumbnailUrl = '';
    },
    handleTrendSetShotListImageUploadComplete(blob) {
      this.trendSetShotListImage = blob.signed_id;
      this.trendSetShotListImageName = blob.filename;
      this.trendSetShotListImageSize = Math.floor(+blob.byte_size / 1000);
      this.trendSetShotListImageUrl = `/rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`;

      if (this.trendSetShotListImageTags) {
        for (const t of this.trendSetShotListImageTags) {
          t._destroy = 1;
        }
      }
    },
    handleDeleteTrendSetShotListImage() {
      this.trendSetShotListImage = undefined;
      this.trendSetShotListImageUrl = null;
      this.trendSetShotListImageName = '';
      this.trendSetShotListImageSize = '';

      if (this.trendSetShotListImageTags) {
        this.trendSetShotListImageTags[
          this.trendSetShotListImageTags.length - 1
        ]._destroy = 0;
      }
    },
    handleTitleChange() {
      if (
        !this.trendSetTitle ||
        this.trendSetTitle.trim().length < 1 ||
        this.trendSetTitle.length > 25
      ) {
        return;
      }

      this.isEditingTitle = !this.isEditingTitle;

      if (!this.isEditingTitle) {
        this.saveTitleChange();
      }
    },
    saveTitleChange() {
      this.isSavingTitle = true;

      const info = {
        title: this.trendSetTitle,
      };

      this.updateTrendSet({
        trendSetId: this.$route.params.trendSetId,
        trendSetCategoryId: this.$route.params.trendSetCategoryId,
        params: info,
      }).then(([trendSet, error]) => {
        if (error) {
          this.titleError = error.response?.data?.message;
          this.isSavingTitle = false;
        } else {
          this.trendSetTitle = trendSet?.title;
          this.isSavingTitle = false;
          this.titleError = '';
        }
      });
    },
    handleTrendSetSave() {
      if (this.isReadyToSave) {
        this.isSaving = true;

        if (!this.trendSetShotListId) {
          const shotTemplateInfo = {
            shot_template: {
              description: this.trendSetShotListDescription,
              tag_id: this.trendSetTagId,
              shot_template_tags_attributes: [
                {
                  tag_id: this.contentTagId,
                  tag_category_id: this.contentTagCategoryId,
                },
              ],
            },
          };

          this.createShotTemplate(shotTemplateInfo).then(
            ([shotTemplate, error]) => {
              if (error) {
                this.error = error.response?.data?.message;
                this.isSaving = false;
              } else {
                this.trendSetShotListId = shotTemplate.id;
                this.saveTrendSet();
              }
            }
          );
        } else {
          this.saveTrendSet();
        }
      } else {
        this.showFormErrors = true;
      }
    },
    saveTrendSet() {
      let info = {};

      if (this.trendSetShotListImage || this.trendSetThumbnail) {
        info = {
          status: this.trendSetStatus,
          description: this.trendSetToolTipText,
          image: this.trendSetThumbnail,
          order: 10,
          price_description: this.trendSetFlag,
          title: this.trendSetTitle,
          shot_templates_attributes: [
            {
              id: this.trendSetShotListId,
              description: this.trendSetShotListDescription,
              tags_attributes: [
                ...(this.trendSetShotListImageTags || []),
                {
                  image: this.trendSetShotListImage || this.trendSetThumbnail,
                  tag_category_id: this.referenceImageTagCategoryId,
                  title:
                    this.trendSetShotListImageName ||
                    this.trendSetThumbnailName,
                  image_url:
                    this.trendSetShotListImageUrl || this.trendSetThumbnailUrl,
                },
              ],
            },
          ],
        };
      } else {
        info = {
          status: this.trendSetStatus,
          description: this.trendSetToolTipText,
          image: this.trendSetThumbnail,
          order: 10,
          price_description: this.trendSetFlag,
          title: this.trendSetTitle,
          shot_templates_attributes: [
            {
              id: this.trendSetShotListId,
              description: this.trendSetShotListDescription,
              tags_attributes: [...(this.trendSetShotListImageTags || [])],
            },
          ],
        };
      }

      this.updateTrendSet({
        trendSetId: this.$route.params.trendSetId,
        trendSetCategoryId: this.$route.params.trendSetCategoryId,
        params: info,
      }).then(([trendSet, error]) => {
        if (error) {
          this.titleError = error.response?.data?.message;
          this.isSaving = false;
        } else {
          this.trendSetTitle = trendSet?.title;
          this.isSaving = false;
          this.titleError = '';
          this.savingSuccessful = true;
          setTimeout(() => this.$router.push('/admin/trend-sets/'), 3000);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
@import '@/buttons';

.admin-trend-set-builder {
  &__header {
    background-color: variables.$periwink-blue-10;
    margin-bottom: 50px;
    padding: 15px 50px;

    &-inner {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1086px;
    }
  }

  &__sidebar {
    margin-right: 36px;
    width: 265px;

    &-category {
      background: variables.$tangerine-20;
      border-radius: 50px;
      margin-bottom: 12px;
      padding: 5px 10px;
      padding-top: 7px;
      width: max-content;

      p {
        color: variables.$black-default;
        font-size: 11px;
        font-weight: 800;
        letter-spacing: 1.5px;
        text-transform: uppercase;
      }
    }

    &-title {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      padding-bottom: 44px;
      position: relative;

      .btn {
        background: transparent;

        &:hover {
          color: variables.$periwink-blue-60;
        }
      }
    }

    &-error {
      @include variables_fonts.u-label--regular;

      position: absolute;
      top: 3.75rem;
      left: 0;
      max-width: 13.1875rem;
    }
  }

  &__soona-error {
    margin-bottom: 3rem;
    margin-top: -1rem;
  }

  &__character-limit {
    @include variables_fonts.u-label--regular;

    color: variables.$gray-60;

    &--error {
      color: variables.$roses-60;
    }
  }

  &__content {
    display: flex;
    justify-content: center;

    &-right {
      align-items: center;
      display: flex;
      flex-direction: column;
      max-width: 900px;
      min-width: 700px;
    }
  }

  &__form {
    width: 100%;
  }

  &__success {
    align-items: center;
    background-color: variables.$white-translucent-80;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    user-select: none;
    width: 100vw;
    z-index: 300;

    &-circle {
      background-color: variables.$avo-toast-40;
      border-radius: 50%;
      height: 50px;
      position: relative;
      width: 50px;

      &:before {
        content: '';
        border-bottom: 3px solid variables.$white-default;
        border-right: 3px solid variables.$white-default;
        height: 25px;
        left: 19px;
        position: absolute;
        width: 12px;
        top: 10px;
        transform: rotate(45deg);
      }
    }

    p {
      padding-top: 24px;
    }
  }

  &__section-inner {
    border: 1px solid variables.$gray-30;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 55px 45px;
  }

  &__uploaded-file {
    align-items: center;
    border: 2px solid variables.$gray-30;
    border-radius: 4px;
    display: flex;
    margin-top: 10px;
    padding: 5px;
    position: relative;
    width: 426px;

    p {
      padding-right: 10px;
    }

    button {
      align-items: center;
      background: transparent;
      border: none;
      color: variables.$gray-60;
      display: flex;
      justify-content: center;
      padding: 0 10px;
      position: absolute;
      right: 0;
      transition: 0.3s;

      &:hover {
        color: variables.$periwink-blue-50;
      }
    }
  }

  &__preview-image {
    margin-bottom: 26px;
    height: 450px;
    width: 450px;
  }

  &__field {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    position: relative;

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__label {
    @include variables_fonts.u-body--heavy;

    padding-bottom: 4px;
  }

  &__next-btn {
    justify-content: flex-end;
    display: flex;
    padding-bottom: 20px;
    padding-top: 14px;
  }
}
</style>

<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.admin-trend-set-builder {
  &__input {
    input {
      border: 1px solid variables.$gray-30;
      border-radius: 5px;

      &::placeholder {
        color: variables.$gray-60;
      }
    }
  }

  &__upload {
    background-color: variables.$white-default;
    border: 1px dashed variables.$gray-40;
    border-radius: 5px;
    height: 172px;
    transition: 0.3s;
    width: 426px;

    &:hover {
      background-color: variables.$gray-10;
    }

    &--complete {
      background-color: variables.$avo-toast-20;
      border-color: variables.$avo-toast-40;

      &:hover {
        background-color: variables.$avo-toast-20;
      }

      .admin-trend-set-builder__upload-btn {
        &:before {
          bottom: 36px;
          color: variables.$black-default;
          content: 'file upload complete!';
        }
      }

      .icon-wrapper {
        &:before {
          background-color: variables.$avo-toast-40 !important;
          border-radius: 50% !important;
          height: 34px !important;
          left: 50% !important;
          top: 35px !important;
          transform: translate(-50%, -50%);
          width: 34px !important;
        }

        &:after {
          background: transparent !important;
          border-right: 4px solid variables.$white-default !important;
          border-bottom: 4px solid variables.$white-default !important;
          border-radius: 0 !important;
          left: -8px !important;
          height: 20px !important;
          top: 35px !important;
          transform: rotate(45deg) translate(-50%, -50%) !important;
          width: 10px !important;
        }
      }

      .file-icon {
        display: none;
      }

      .file-label {
        color: transparent;
      }
    }

    .file {
      display: block;
      height: 100%;
    }

    label {
      height: 100%;
    }

    &-btn {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 30px 0;
      width: 100%;

      &:before {
        @include variables_fonts.u-label--heavy;

        content: 'browse computer';
        position: absolute;
        text-align: center;
        width: 200px;
      }
    }

    .icon-wrapper {
      flex-grow: 1;
      position: relative;

      &:before,
      &:after {
        background-color: variables.$gray-40;
        border-radius: 100px;
        content: '';
        position: absolute;
      }

      &:before {
        height: 20px;
        left: -10px;
        width: 41px;
      }

      &:after {
        height: 20px;
        top: -8px;
        width: 20px;
      }
    }

    .file-icon {
      height: 21px;
      position: relative;
      width: auto;
      z-index: 1;

      path {
        fill: variables.$white-default;
      }
    }

    .file-label {
      @include variables_fonts.u-label--regular;
    }
  }
}
</style>
