<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { refDebounced, useTitle } from '@vueuse/core';
import { useAccount } from '@/queries/account/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useBookingsCategory } from './useBookingsCategory';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useGetRecentlyWrappedReservations } from '@/queries/useGetRecentlyWrappedReservations';
import AccountInfo from '@/components/user/anytime/dashboard/AccountInfo.vue';
import BookingsCategory from './BookingsCategory.vue';
import BookingsFilters from './BookingsFilters.vue';
import CreditPurchaseBanner from 'src/components/CreditPurchaseBanner.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';
import SpeedyCheckoutBanner from '@/components/ui_library/SpeedyCheckoutBanner.vue';
import faceImg from 'images/empty-states/face.jpg';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const speedyCheckoutFlag = useFlag('apollo_speedy_checkout');
useTitle('bookings | soona');

const accountId = computed(() => props.accountId);
const route = useRoute();
const router = useRouter();

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { data: account, isSuccess: accountIsSuccess } = useAccount(
  computed(() => props.accountId),
  { enabled: isFtSoonaStaff }
);
// common filters
const filterOptions = ['all', 'upcoming', 'suggested', 'drafts', 'past'];
const selectedFilter = ref(
  filterOptions.includes(route.query.filter) ? route.query.filter : 'all'
);
const searchText = ref(route.query.query ?? '');
const searchDebounced = refDebounced(searchText, 200);

function clearFilters() {
  selectedFilter.value = 'all';
  searchText.value = '';
}

watchEffect(() => {
  router.replace({
    query: {
      filter: selectedFilter.value,
      query: searchDebounced.value,
    },
  });
});

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

// upcoming
const {
  currentPage: upcomingCurrentPage,
  isLoading: upcomingIsLoading,
  isShown: upcomingShown,
  itemsPerPage: upcomingItemsPerPage,
  perPageOptions: upcomingPerPageOptions,
  priorityError: upcomingPriorityError,
  reservations: upcomingReservations,
  reservationsPagination: upcomingReservationsPagination,
} = useBookingsCategory({
  accountId,
  filter: 'upcoming',
  search: searchDebounced,
  enabled: computed(
    () => selectedFilter.value === 'all' || selectedFilter.value === 'upcoming'
  ),
});

// suggested
const {
  currentPage: suggestedCurrentPage,
  isLoading: suggestedIsLoading,
  isShown: suggestedShown,
  itemsPerPage: suggestedItemsPerPage,
  perPageOptions: suggestedPerPageOptions,
  priorityError: suggestedPriorityError,
  reservations: suggestedReservations,
  reservationsPagination: suggestedReservationsPagination,
} = useBookingsCategory({
  accountId,
  filter: 'suggested',
  search: searchDebounced,
  enabled: computed(
    () => selectedFilter.value === 'all' || selectedFilter.value === 'suggested'
  ),
});

// draft
const {
  currentPage: draftCurrentPage,
  isLoading: draftIsLoading,
  isShown: draftShown,
  itemsPerPage: draftItemsPerPage,
  perPageOptions: draftPerPageOptions,
  priorityError: draftPriorityError,
  reservations: draftReservations,
  reservationsPagination: draftReservationsPagination,
} = useBookingsCategory({
  accountId,
  filter: 'draft',
  search: searchDebounced,
  enabled: computed(
    () => selectedFilter.value === 'all' || selectedFilter.value === 'drafts'
  ),
});

// past
const {
  currentPage: pastCurrentPage,
  isLoading: pastIsLoading,
  isShown: pastShown,
  itemsPerPage: pastItemsPerPage,
  perPageOptions: pastPerPageOptions,
  priorityError: pastPriorityError,
  reservations: pastReservations,
  reservationsPagination: pastReservationsPagination,
} = useBookingsCategory({
  accountId,
  filter: 'past',
  search: searchDebounced,
  enabled: computed(
    () => selectedFilter.value === 'all' || selectedFilter.value === 'past'
  ),
});

const { data: recentlyWrappedReservations } = useGetRecentlyWrappedReservations(
  accountId,
  { enabled: speedyCheckoutFlag.value }
);

const speedyCheckoutReservation = computed(
  () => recentlyWrappedReservations.value?.[0]
);

// aggregated display logic
const isLoading = computed(
  () =>
    upcomingIsLoading.value ||
    suggestedIsLoading.value ||
    draftIsLoading.value ||
    pastIsLoading.value
);

// no filtered results
const showNoResults = computed(() => {
  return (
    !upcomingShown.value &&
    !suggestedShown.value &&
    !draftShown.value &&
    !pastShown.value
  );
});

// no bookings in the account at all
const showNoData = computed(() => {
  return (
    showNoResults.value &&
    selectedFilter.value === 'all' &&
    searchDebounced.value === ''
  );
});
</script>

<template>
  <div class="bookings-page">
    <CreditPurchaseBanner>
      <template #image>
        <img src="@images/bulk-credits/photo_collage@2x.png" alt="" />
      </template>
      <template #heading-title>did you know you can</template>
      <template #heading-subtitle>prepay to save on content</template>
      <template #content-title>get the content you need for</template>
      <template #content-body>
        <div class="bookings-page__banner-logos">
          <img
            src="@images/bulk-credits/shopify-logo@2x.png"
            alt="shopify logo"
          />
          <img
            class="bookings-page__banner-amazon-logo"
            src="@images/bulk-credits/amazon-logo@2x.png"
            alt="amazon logo"
          />
          <img
            src="@images/bulk-credits/instagram-logo@2x.png"
            alt="instagram logo"
          />
          <img src="@images/bulk-credits/etsy-logo@2x.png" alt="etsy logo" />
          <img
            src="@images/bulk-credits/tiktok-logo@2x.png"
            alt="tiktok logo"
          />
        </div>
      </template>
      <template #btn-label>average savings</template>
      <template #btn-bold>$425</template>
      <template #btn-sublabel>/content shoot</template>
    </CreditPurchaseBanner>
    <header class="bookings-page__header">
      <AccountInfo
        v-if="isFtSoonaStaff && accountIsSuccess"
        class="bookings-page__account-info"
        :account="account"
        :link-to-profile="true"
      />
      <h1 class="u-headline--heavy">bookings</h1>
      <SoonaButton element="a" href="/#/booking/new" size="medium">
        create
      </SoonaButton>
    </header>
    <BookingsFilters
      v-model:selected-filter="selectedFilter"
      v-model:search-text="searchText"
      :filter-options="filterOptions"
    />
    <SpeedyCheckoutBanner
      v-if="
        speedyCheckoutFlag && speedyCheckoutReservation?.fast_checkout_eligible
      "
      :checkout-deadline="speedyCheckoutReservation.fast_checkout_deadline"
      :reservation-id="speedyCheckoutReservation.id"
    />
    <div v-if="isLoading" class="bookings-page__loading-wrapper">
      <SoonaLoading is-loading loading-text="loading" is-contained />
    </div>
    <SoonaNoResults
      v-else-if="showNoData"
      mask="circle"
      :image-source="faceImg"
    >
      <template #header>let’s create something beautiful.</template>
      <template #button>
        <SoonaButton element="a" href="/#/booking/new" variation="primary">
          book now
        </SoonaButton>
      </template>
    </SoonaNoResults>
    <SoonaNoResults v-else-if="showNoResults">
      <template #header>oops, we couldn’t find a match!</template>
      <template #body> try a different search or filter combination </template>
      <template #button>
        <SoonaButton variation="secondary-black" :on-click="clearFilters">
          reset filters
        </SoonaButton>
      </template>
    </SoonaNoResults>
    <template v-else>
      <BookingsCategory
        v-if="upcomingShown"
        v-model:current-page="upcomingCurrentPage"
        v-model:items-per-page="upcomingItemsPerPage"
        :account-id="accountId"
        :per-page-options="upcomingPerPageOptions"
        :priority-error="upcomingPriorityError"
        :reservations="upcomingReservations"
        :total-count="upcomingReservationsPagination.totalCount"
        :total-pages="upcomingReservationsPagination.totalPages"
        heading="upcoming"
      />
      <BookingsCategory
        v-if="suggestedShown"
        v-model:current-page="suggestedCurrentPage"
        v-model:items-per-page="suggestedItemsPerPage"
        :account-id="accountId"
        :per-page-options="suggestedPerPageOptions"
        :priority-error="suggestedPriorityError"
        :reservations="suggestedReservations"
        :total-count="suggestedReservationsPagination.totalCount"
        :total-pages="suggestedReservationsPagination.totalPages"
        heading="suggested"
      />
      <BookingsCategory
        v-if="draftShown"
        v-model:current-page="draftCurrentPage"
        v-model:items-per-page="draftItemsPerPage"
        :account-id="accountId"
        :per-page-options="draftPerPageOptions"
        :priority-error="draftPriorityError"
        :reservations="draftReservations"
        :total-count="draftReservationsPagination.totalCount"
        :total-pages="draftReservationsPagination.totalPages"
        heading="drafts"
      />
      <BookingsCategory
        v-if="pastShown"
        v-model:current-page="pastCurrentPage"
        v-model:items-per-page="pastItemsPerPage"
        :account-id="accountId"
        :per-page-options="pastPerPageOptions"
        :priority-error="pastPriorityError"
        :reservations="pastReservations"
        :total-count="pastReservationsPagination.totalCount"
        :total-pages="pastReservationsPagination.totalPages"
        heading="past"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bookings-page {
  &__banner-logos {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    img {
      height: 1rem;
    }
  }

  &__banner-amazon-logo {
    margin-top: 0.25rem;
  }

  &__loading-wrapper {
    position: relative;
    min-height: 10rem;
    color: variables.$black-default;
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    color: variables.$black-default;
    margin-bottom: 1.5rem;
  }

  &__account-info {
    margin-bottom: 1.5rem;
  }
}
</style>
