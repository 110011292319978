<script setup>
import { computed, ref } from 'vue';
import { useCreatePackConfiguration } from '@/queries/pack-configurations/useCreatePackConfiguration';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useRouter } from 'vue-router';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

const showAddPackModal = ref(false);
const packName = ref('');
const packCategory = ref('');

const router = useRouter();

const packCategoryOptions = [
  { value: 'photo', label: 'photo' },
  { value: 'video', label: 'video' },
  { value: 'stop motion', label: 'stop motion' },
];

const helperText = computed(() => {
  return packCategory.value === 'stop motion' &&
    !packName.value.includes('stop motion')
    ? "include 'stop motion' in the pack title"
    : undefined;
});

const { mutate, isPending, error } = useCreatePackConfiguration();
const priorityError = usePriorityError(error);

const handleSubmit = () => {
  mutate(
    {
      name: packName.value,
      category:
        packCategory.value === 'stop motion' ? 'video' : packCategory.value,
    },
    {
      onSuccess: pack => {
        router.push(`/crew/packs/${pack.data.id}/edit`);
      },
    }
  );
};
const toggleModal = value => {
  showAddPackModal.value = value;
};
</script>
<template>
  <div class="admin-create-pack-button">
    <SoonaButton
      :variation="'primary'"
      data-cypress="button-create-pack"
      @click="toggleModal(true)"
    >
      + add a new pack
    </SoonaButton>
    <div class="admin-create-pack-modal">
      <SoonaDialog
        v-if="showAddPackModal"
        size="small"
        @close="() => toggleModal(false)"
      >
        <template #heading>add a new pack</template>
        <template #default>
          <SoonaTextfield
            v-model:model-value="packName"
            :disabled="isPending"
            label="title"
            name="note"
            type="text"
            placeholder="name this pack"
            data-cypress="name-this-pack"
          >
            <template #helper-bottom>
              <SoonaAlert v-if="helperText">{{ helperText }}</SoonaAlert>
            </template>
          </SoonaTextfield>
          <SoonaSelect
            id="pack-type"
            v-model:model-value="packCategory"
            :disabled="isPending"
            :options="packCategoryOptions"
            placeholder="select"
            :searchable="false"
            :multi="false"
            data-cypress="pack-type"
          >
            <template #label>pack type</template>
          </SoonaSelect>
          <SoonaError v-if="priorityError">
            {{ priorityError }}
          </SoonaError>
        </template>
        <template #footer="{ close }">
          <SoonaButton
            type="button"
            variation="tertiary"
            size="medium"
            @click="close"
          >
            cancel
          </SoonaButton>
          <SoonaButton
            type="button"
            :disabled="!(packName && packCategory) || isPending"
            :loading="isPending"
            @click="handleSubmit"
          >
            add
          </SoonaButton>
        </template>
      </SoonaDialog>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';
@import '@/buttons';

.admin-create-pack-modal {
  &__helper {
    @include variables_fonts.u-label--regular;

    align-items: baseline;
    display: flex;
    font-size: 0.875rem;
    gap: 0.5rem;
    justify-content: space-between;
    padding-top: 0.3125rem;
  }

  &__helper-icon {
    color: variables.$roses-60;
  }
}
</style>
