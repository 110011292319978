<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import { computed, inject, ref, toRefs } from 'vue';
import { MEDIA_POPOVER_PREV_KEY } from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import DownloadDigitalAssetMenuItem from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAssetMenuItem.vue';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';

const props = defineProps({
  assetId: {
    type: [String, Number],
    required: true,
  },
  assetAccountId: {
    type: [String, Number],
    required: true,
  },
});

const emits = defineEmits(['set-asset', 'previous-next-asset']);

const { assetAccountId, assetId } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const { hasSubscriptionDownloadAccess } = mediaEditorStore;

const { mediaUrl, webUrl, previewUrl, mediaWidth, mediaHeight } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

// buttons
const injectedPrevRoute = inject(MEDIA_POPOVER_PREV_KEY);
const buttonRoute = computed(() => {
  return {
    ...injectedPrevRoute.value,
    params: {
      digitalAssetId: assetId.value,
    },
    query: {
      ...injectedPrevRoute.value?.query,
      tool: 'none',
    },
  };
});

// image loading

const isPreviewImgLoaded = ref(false);
const isLargerImgLoaded = ref(false);

const handlePreviousNextClick = direction => {
  isPreviewImgLoaded.value = false;
  isLargerImgLoaded.value = false;
  emits('previous-next-asset', direction);
};

const handlePreviewImgLoaded = () => {
  isPreviewImgLoaded.value = true;
};

const handleLargerImgLoaded = () => {
  isLargerImgLoaded.value = true;
};

const subtitle = computed(() => {
  if (mediaWidth.value && mediaHeight.value) {
    return `${mediaWidth.value} x ${mediaHeight.value}`;
  }
  return '';
});

const downloadFile = url => {
  window.location.href = url;
};
</script>

<template>
  <SoonaDialog
    class="generations-dialog-preview"
    max-width="65rem"
    max-content-height="100%"
    @close="emits('set-asset', null)"
  >
    <div class="generations-dialog-preview__content">
      <SoonaButton
        variation="icon-gray-outline"
        @on-click="handlePreviousNextClick(-1)"
      >
        <SoonaIcon name="arrow-left" />
        <span class="u-a11y-only">previous image</span>
      </SoonaButton>
      <img
        v-if="isPreviewImgLoaded"
        v-show="isLargerImgLoaded"
        :src="mediaUrl"
        alt=""
        :width="mediaWidth"
        :height="mediaHeight"
        class="generations-dialog-preview__image"
        @load="handleLargerImgLoaded"
      />
      <img
        v-if="!isLargerImgLoaded"
        :src="previewUrl"
        alt=""
        :width="mediaWidth"
        :height="mediaHeight"
        class="generations-dialog-preview__image"
        @load="handlePreviewImgLoaded"
      />
      <SoonaButton
        variation="icon-gray-outline"
        @on-click="handlePreviousNextClick(1)"
      >
        <SoonaIcon name="arrow-right" />
        <span class="u-a11y-only">previous image</span>
      </SoonaButton>
    </div>
    <template #footer>
      <SoonaDropdownMenu copy="download" class="download-digital-asset-menu">
        <template #default="{ clickCapture, keydown, mouseover }">
          <template v-if="!hasSubscriptionDownloadAccess">
            <DownloadDigitalAssetMenuItem
              :mouseover="mouseover"
              :keydown="keydown"
              :click-capture="clickCapture"
              title="low res preview"
              subtitle="with soona watermark"
              @click="downloadFile(previewUrl)"
            >
              <template #right-col>
                <span class="u-label--regular free">free</span>
              </template>
            </DownloadDigitalAssetMenuItem>
            <DownloadDigitalAssetMenuItem
              :mouseover="mouseover"
              :keydown="keydown"
              :click-capture="clickCapture"
              title="full resolution"
              :subtitle="subtitle"
              pizzazz-border
              @click="() => mediaEditorStore.setShowPaywallDialog(true)"
            >
              <template #right-col>
                <SoonaFlag
                  background-color="transparent"
                  type="pill"
                  is-pizzazz
                >
                  <template #icon-left>
                    <SoonaIcon name="crown" size="small" class="crown" />
                  </template>
                </SoonaFlag>
              </template>
            </DownloadDigitalAssetMenuItem>
          </template>
          <template v-else>
            <DownloadDigitalAssetMenuItem
              :mouseover="mouseover"
              :keydown="keydown"
              :click-capture="clickCapture"
              title="download web resolution"
              @click="downloadFile(webUrl)"
            />
            <DownloadDigitalAssetMenuItem
              :mouseover="mouseover"
              :keydown="keydown"
              :click-capture="clickCapture"
              title="download full resolution"
              @click="downloadFile(mediaUrl)"
            />
          </template>
        </template>
      </SoonaDropdownMenu>
      <SoonaButton
        element="router-link"
        :to="buttonRoute"
        variation="solid-black"
        size="small"
      >
        edit photo
        <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style scoped lang="scss">
@use 'src/variables';

.generations-dialog-preview {
  &__content {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }

  &__image {
    max-width: 55rem;
    border-radius: 0.5rem;
  }
}
.crown {
  color: variables.$white-default;
}
</style>
