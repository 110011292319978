<script setup>
import { computed, onMounted, ref } from 'vue';
import BulkCreditsModal from '@/components/modal-payment-flows/preferred/BulkCreditsModal.vue';
import ImageInstructions from '../shared/ImageInstructions.vue';
import InstructionList from '../shared/InstructionList.vue';
import AITemplates from './AITemplates.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import faqData from './faq.json';
import SoonaButton from '../ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAccount } from '@/composables/useAccount';
import { BubbletapePink30 } from '@/variables.module.scss';
import howItWorksImage from '@images/media-editor/how-it-works.jpg';
import aiStudioImage from '@images/media-editor/ai-studio@2x.jpg';
import UploadDigitalAsset from '@/components/media-editor/UploadDigitalAsset.vue';
import RecentUploads from './RecentUploads.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useTitle } from '@vueuse/core';
import SubscriptionBannerMediaEditor from './SubscriptionBannerMediaEditor.vue';
import { useTiers } from '@/composables/subscription/useTiers';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const aiTemplatesFlag = useFlag('toaster_ai_templates');
const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');

const accountId = computed(() => props.accountId);

useTitle('AI studio | soona');

const showDialog = ref(false);

const {
  account: currentAccount,
  isDigitalSubscriber,
  eligibleForPlatformSubscriptionTrial,
} = useAccount(accountId);

const offerTrial = computed(
  () =>
    apolloTrialForEveryoneFlag.value &&
    eligibleForPlatformSubscriptionTrial.value
);

const { basicTier } = useTiers();

const { pageViewed } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

const handleCloseDialog = () => (showDialog.value = false);

const howItWorksList = computed(() => [
  'select any purchased or uploaded image',
  'choose a tool and modify your image',
  'save to gallery',
  'download or publish wherever you sell!',
]);

const aiStudioList = computed(() => [
  'create infinite images with AI generated backgrounds',
  'choose from our curated collection of intelligent templates',
  'add props from our extensive prop library',
  'capture your favorite style by uploading inspiration images',
]);

const toEditsPage = computed(() => {
  return {
    name: 'edits-assets',
    params: { accountId: accountId.value },
  };
});
</script>

<template>
  <div class="media-editor-landing-page">
    <div class="me-landing__tool">
      <SubscriptionBannerMediaEditor
        v-if="!!currentAccount && !isDigitalSubscriber"
        :tier="basicTier"
        :cta-copy="offerTrial ? 'try it free for 7 days' : 'upgrade'"
      />
      <header class="me-landing__header">
        <h1 class="u-headline--heavy">AI studio</h1>
        <p class="u-subheader--regular">upload or select a photo to start</p>
      </header>
      <AITemplates v-if="aiTemplatesFlag" :account-id="accountId" />
      <UploadDigitalAsset :account-id="accountId" />
      <aside class="me-landing__hint">
        <div class="me-landing__hint--text">
          <SoonaIcon name="lightbulb-alt" />
          <p>
            <strong>hint:</strong> if you’ve had a soona photo shoot - click on
            any of your completed edits to use AI studio
          </p>
        </div>
        <SoonaButton
          v-if="!!currentAccount"
          variation="secondary-black"
          element="router-link"
          :to="toEditsPage"
        >
          see all completed edits
        </SoonaButton>
      </aside>
      <RecentUploads :account-id="accountId" />
    </div>
    <ImageInstructions
      class="image-instructions"
      :background-color-1="BubbletapePink30"
      :background-color-2="BubbletapePink30"
      :image-path="howItWorksImage"
      has-image-shadow
    >
      <InstructionList :instruction-list="howItWorksList">
        <h2 class="image-instructions__title">how it works</h2>
      </InstructionList>
    </ImageInstructions>
    <ImageInstructions
      class="image-instructions"
      :background-color-1="BubbletapePink30"
      :background-color-2="BubbletapePink30"
      image-side="right"
      :image-path="aiStudioImage"
      :has-image-shadow="false"
    >
      <InstructionList :instruction-list="aiStudioList">
        <h2 class="image-instructions__title">welcome to AI studio!</h2>
      </InstructionList>
    </ImageInstructions>
    <SoonaFaq class="faq" :faq-tab-list="faqData" />
  </div>
  <BulkCreditsModal
    v-if="showDialog"
    flow="waitlist-landing"
    @close="handleCloseDialog"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.me-landing {
  &__tool {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
  }

  &__header {
    margin-bottom: -1rem;
  }

  &__hint {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.25rem;
    padding: 0.75rem;
    border-radius: 0.3125rem;
    border: 0.125rem solid variables.$bubbletape-pink-30;

    > svg {
      flex: 0 0 1.25rem;
      margin-right: -1rem;
    }

    &--text {
      display: flex;
      flex-flow: row wrap;
      gap: 0.25rem;
      align-items: center;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &__hint {
      gap: 1.25rem;
    }
  }
}

.media-editor-landing-page {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .faq {
    margin-top: 0rem;
    min-width: 100%;
  }

  .image-instructions__title {
    @include variables_fonts.u-title--heavy;
  }

  @media (min-width: variables.$screen-sm-min) {
    gap: 4.5rem;
  }
}
</style>
