<template>
  <div
    v-if="capabilities.soona_staff.hasCapability || !isHidden"
    class="ProServiceSelectionCard"
  >
    <div class="non-select-view">
      <div class="requirement-header" :class="selectionOpen ? 'expanded' : ''">
        <img
          v-if="proServiceProfiles.length === 0 && !providerSelected"
          :src="mainTag.image_url"
          class="service-image"
          :class="isHidden ? 'hidden' : ''"
        />
        <div class="service-description">
          <SoonaError v-for="(error, key) in selectionErrors" :key="key">
            {{ error.message }}
          </SoonaError>
          <div class="title-and-button">
            <h2 class="psp-service-name" :class="isHidden ? 'hidden' : ''">
              <span class="heading-text">
                {{ proServiceRequirement.numbered_title }}
                <span v-if="capabilities.soona_staff.hasCapability">
                  ({{ proServiceRequirement.level }})
                </span>
              </span>
              <span
                v-if="
                  ((modelsSelected || serviceCategory === 'grocery') &&
                    capabilities.soona_staff.hasCapability &&
                    selectionStatus) ||
                  selectionStatus === 'accepted'
                "
                :class="'banner ' + selectionStatus"
              >
                {{ selectionStatus.split('_').join(' ') }}
              </span>
            </h2>
            <SoonaIcon v-if="isHidden" name="eye-slash" />
            <SoonaDropdownMenu
              v-else-if="
                (['model', 'creator'].includes(serviceCategory) &&
                  modelsSelected) ||
                (['model', 'creator'].includes(serviceCategory) &&
                  capabilities.ft_soona_staff.hasCapability) ||
                capabilities.hide_res_pro_services.hasCapability
              "
              variation="secondary-black"
              :copy="
                capabilities.ft_soona_staff.hasCapability
                  ? 'crew actions'
                  : 'edit'
              "
              class="select-choice-dropdown"
              :disabled="actionDropdownDisabled"
            >
              <template #default="{ keydown, mouseover, clickCapture }">
                <SoonaDropdownMenuItem
                  v-for="item in currentDropDownOptions"
                  :key="item.key"
                >
                  <button
                    role="menuitem"
                    class="menu-button"
                    :disabled="item.disabled"
                    @click="item.click"
                    @click.capture="clickCapture"
                    @keydown="keydown"
                    @mouseover="mouseover"
                  >
                    {{ item.text }}
                  </button>
                </SoonaDropdownMenuItem>
              </template>
            </SoonaDropdownMenu>
          </div>
          <p
            v-if="pspText && (selectionOpen || !providerSelected)"
            class="selection-description"
            :class="isHidden ? 'hidden' : ''"
          >
            {{ pspText }}
            <!-- TODO remove ugc content -->
            <a
              v-if="['creator'].includes(serviceCategory)"
              href="http://ugc.soona.co/"
              target="_blank"
              class="content-creator-gallery-link"
            >
              view all of our creators here
              <SoonaIcon name="arrow-up-right-from-square" size="small" />
            </a>
          </p>
          <div
            v-if="!isHidden && (serviceCategory === 'model' || isCreator)"
            class="provider-selection-display"
            :class="{ 'crew-view': capabilities.soona_staff.hasCapability }"
          >
            <div
              v-for="choice in computedPSPchoicesToDisplay"
              :key="choice.pro_service_profile_id"
              class="provider-selection"
              :class="{
                'crew-view':
                  capabilities.soona_staff.hasCapability &&
                  choice.pro_service_profile_id,
              }"
            >
              <div
                class="provider-profile-image"
                :class="{
                  'crew-view': capabilities.soona_staff.hasCapability,
                  unfocused:
                    selectionStatus === 'accepted' &&
                    providerStatus(choice) !== 'accepted',
                }"
              >
                <div
                  v-if="choice.pro_service_profile_id"
                  class="profile-image-wrapper"
                >
                  <img
                    :key="choice.id"
                    :src="choice.image_url || accountImage"
                    class="image-display"
                    :class="{
                      'crew-view': capabilities.soona_staff.hasCapability,
                    }"
                  />
                  <button
                    v-if="selectionOpen"
                    class="delete-btn"
                    @click="removeProvider(choice)"
                  >
                    <SoonaIcon name="xmark" />
                  </button>
                  <AcceptedIcon
                    v-else-if="providerStatus(choice) === 'accepted'"
                    class="status-icon"
                    aria-hidden="true"
                  />
                  <PendingIcon
                    v-else-if="
                      capabilities.soona_staff.hasCapability &&
                      (providerStatus(choice) === 'sent' ||
                        providerStatus(choice) === 'reminder sent')
                    "
                    class="status-icon"
                    aria-hidden="true"
                  />
                  <SoonaIcon
                    v-else-if="
                      capabilities.soona_staff.hasCapability &&
                      ['declined', 'canceled', 'removed'].indexOf(
                        providerStatus(choice)
                      ) > -1
                    "
                    name="circle-xmark-solid"
                    class="status-icon status-icon--declined"
                  />
                  <NoResponseIcon
                    v-else-if="
                      capabilities.soona_staff.hasCapability &&
                      providerStatus(choice) === 'no response'
                    "
                    class="status-icon"
                    aria-hidden="true"
                  />
                </div>
                <div
                  v-else
                  class="emoji-display"
                  :class="{
                    'crew-view':
                      capabilities.soona_staff.hasCapability && selectionOpen,
                  }"
                >
                  <div class="heart-container">
                    <SoonaIcon name="heart-solid" />
                  </div>
                </div>
                <span
                  class="image-banner"
                  :class="{
                    'crew-view':
                      capabilities.soona_staff.hasCapability &&
                      (selectionOpen || choice.pro_service_profile_id),
                  }"
                  >{{ bannerText(choice) }}</span
                >
                <SoonaFlag
                  v-if="
                    choice.source &&
                    choice.source !== 'post_booking_pro_service_selection' &&
                    capabilities.soona_staff.hasCapability
                  "
                  class="choice-badge"
                  :background-color="Tangerine40"
                  font-weight="bolder"
                  :title="choice.source + ' choice'"
                  aria-hidden="true"
                />
                <span
                  v-if="choice.pro_service_profile_id"
                  class="view-details-section"
                >
                  <SoonaButton
                    class="view-details"
                    variation="filter"
                    size="small"
                    @click="openViewDetailsModal(choice.pro_service_profile_id)"
                  >
                    view details
                  </SoonaButton>
                </span>
              </div>
              <div
                v-if="
                  capabilities.ft_soona_staff.hasCapability &&
                  choice.pro_service_profile_id
                "
                class="provider-name"
              >
                {{ formatName(choice.provider_name) }}
              </div>
              <div
                v-if="
                  capabilities.ft_soona_staff.hasCapability &&
                  choice.pro_service_profile_id
                "
                class="profile-name"
              >
                <span v-if="choice.provider_name != choice.user_name">
                  {{ formatName(choice.user_name) }}
                </span>
              </div>
              <ProServiceSelectionProviderDropdown
                v-if="
                  capabilities.ft_soona_staff.hasCapability &&
                  choice.pro_service_profile_id
                "
                class="provider-actions-dropdown"
                :reservation="reservation"
                :selection="choice"
                :pro-service-requirement="proServiceRequirement"
              />
              <div
                v-if="capabilities.soona_staff.hasCapability"
                class="provider-status-date"
              >
                {{ providerStatusDate(choice) }}
              </div>
            </div>
          </div>
          <div
            v-if="capabilities.hide_res_pro_services.hasCapability && isHidden"
            class="reason-wrapper"
          >
            <p class="reason">
              hidden on
              {{ convertToMMDDYYYY(proServiceRequirement.hidden_at) }}
            </p>
            <p class="reason">
              reason:
              {{ proServiceRequirement.hide_reason.replace(/_/g, ' ') }}
            </p>
            <p class="reason">
              hidden by: {{ proServiceRequirement.hidden_by.name }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="
          currentServiceSelected !== proServiceRequirementId && serviceCategory
        "
        class="requirement-footer"
      >
        <p
          v-if="
            isCreator &&
            proService &&
            proService.typeform &&
            proService.typeform.submittedAt
          "
        >
          form completed on
          {{ convertToMMDDYYYY(proService.typeform.submittedAt) }}
        </p>
        <span v-if="isCreator" class="cta-button">
          <SoonaButton
            :disabled="
              proService.typeform.submittedAt !== null || isHidden.length > 0
            "
            size="large"
            @on-click="() => (formIsOpen = true)"
          >
            create brief
          </SoonaButton>
        </span>
        <SoonaButton
          v-if="
            ['model', 'creator'].includes(serviceCategory) && !modelsSelected
          "
          :disabled="
            isHidden || disableSelect || (isCreator && !typeformSubmitted)
          "
          :on-click="selectPSP"
          :variation="isCreator ? 'secondary-black' : 'primary'"
          data-cypress="button-select-psp-model"
          :title="
            !typeformSubmitted
              ? 'complete your brief first and then you can select a content creator.'
              : null
          "
        >
          {{ isCreator ? 'select creator' : 'select models' }}
        </SoonaButton>
        <SoonaButton
          v-if="
            serviceCategory === 'grocery' &&
            capabilities.ft_soona_staff.hasCapability
          "
          :disabled="
            reservation.scheduled_reservation_date && daysBeforeStart <= 1
          "
          variation="tertiary"
          :on-click="openGroceryModal"
        >
          {{
            proServiceRequirement.pro_service_extras &&
            proServiceRequirement.pro_service_extras.grocery_list !== ''
              ? 'edit details'
              : 'add details'
          }}
        </SoonaButton>
        <SoonaButton
          v-if="serviceCategory === 'grocery'"
          :disabled="
            isHidden ||
            (proServiceRequirement.pro_service_extras &&
              proServiceRequirement.pro_service_extras.grocery_list !== '')
          "
          element="a"
          href="https://airtable.com/appQj1knbGNiSFbRK/pagEAuuBzFpzi1DlN/form"
          variation="secondary-black"
          target="_blank"
        >
          make grocery list
          <SoonaIcon name="arrow-up-right-from-square" size="small" />
        </SoonaButton>
      </div>
      <div v-else-if="selectionOpen && pspText" class="requirement-footer">
        <SoonaButton
          :disabled="isHidden"
          variation="tertiary"
          :on-click="cancelSelection"
        >
          cancel
        </SoonaButton>
        <SoonaTooltip placement="top">
          <template
            #default="{
              setRef,
              mouseenter,
              focus,
              mouseleave,
              blur,
              ariaDescribedby,
            }"
          >
            <SoonaButton
              :ref="el => setRef(el)"
              :disabled="isHidden || disableSubmit"
              :on-click="openAddOnModal"
              data-cypress="button-submit-psp-models"
              :aria-describedby="ariaDescribedby"
              @mouseenter="mouseenter"
              @focus="focus"
              @mouseleave="mouseleave"
              @blur="blur"
            >
              submit
            </SoonaButton>
          </template>
          <template v-if="disableSubmit" #tooltip-content
            >minimum two model choices</template
          >
        </SoonaTooltip>
      </div>
      <SoonaDialog v-if="showHideServiceModal" @close="closeHideServiceModal">
        <template #heading>
          are you sure you want to hide this pro service?
        </template>
        <p class="dialog-text">
          hiding this pro service will remove visibility for customer only. this
          does not refund or cancel the pro service.
        </p>
        <SoonaSelect
          v-model:model-value="hidePSreason"
          :options="hideReasons"
          placeholder="select reason"
          data-cypress="select-product-size"
        >
          <template #label>reason for hiding pro service</template>
        </SoonaSelect>
        <SoonaError v-if="reasonError">{{ reasonError }}</SoonaError>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="hideServiceConfirm"
          >
            hide pro service
          </SoonaButton>
        </template>
      </SoonaDialog>
      <SoonaDialog
        v-if="showResetSelectionModal"
        @close="closeResetSelectionModal"
      >
        <template #heading>reset selection</template>
        <p>are you sure you want to reset the submitted model selection?</p>
        <p>doing this will send client an email to make a selection.</p>
        <template #footer="{ close }">
          <SoonaButton variation="tertiary" @on-click="close">
            cancel
          </SoonaButton>
          <SoonaButton
            data-cypress="button-dialog-confirm"
            @on-click="resetSelections"
          >
            reset selection
          </SoonaButton>
        </template>
      </SoonaDialog>
      <ProServiceProviderSelectionViewDetails
        v-if="selectedProviderId"
        :pro-service-provider-id="selectedProviderId"
        :show-modal-actions="false"
        :close-modal="closeViewDetailsModal"
        :pro-service-requirement-id="proServiceRequirementId"
      />
    </div>
    <div v-if="selectionOpen" class="select-view">
      <hr />
      <div class="gallery-filters">
        <div class="quick-filters">
          <div class="dropdown-filters">
            <ProServiceDropdownFilter
              v-for="quickFilter in quickFilters"
              :key="quickFilter.id"
              :model-value="filters"
              class="filter"
              :tag-category="quickFilter"
              :pro-service-provider-type="mainTag.title"
              @update:model-value="traitSelect"
            />
          </div>
          <SoonaButton
            variation="tertiary"
            :on-click="clearFilters"
            size="medium"
          >
            clear filters
          </SoonaButton>
        </div>
        <SoonaButton
          variation="secondary-black"
          class="filters-modal-button"
          :class="{
            ['filters-modal-button-active']: filters.length > 0,
          }"
          :on-click="openFilterModal"
          size="medium"
        >
          filters <FilterIcon aria-label="Filter" />
        </SoonaButton>
      </div>
      <div class="psp-gallery">
        <ProServiceProviderSelectionCard
          v-for="profile in proServiceProfiles"
          :key="profile.id"
          :pro-service-provider="profile"
          :pro-service-requirement-id="proServiceRequirementId"
          :reservation-id="reservation.id"
        />
        <PaginatorFull
          :page="proServiceProfilesPagination.currentPage"
          :records-per-page="proServiceProfilesPagination.itemsPerPage"
          :total-pages="proServiceProfilesPagination.totalPages"
          :total-count="proServiceProfilesPagination.totalCount"
          :page-count="proServiceProfiles.length"
          :hide-change-records-per-page="true"
          class="gallery-paginator"
          @update:page="currentPage = $event"
        />
      </div>
      <div
        v-if="proServiceProfiles.length === 0 && filters.length > 0"
        class="select-view-no-providers"
      >
        <div class="no-providers-message">
          oh no!...no results found
          <p>please try another search</p>
        </div>
        <SoonaButton
          variation="secondary-black"
          :on-click="clearFilters"
          size="medium"
        >
          clear all filters
        </SoonaButton>
      </div>
    </div>
    <ProServiceSelectionSummaryCard
      v-if="serviceCategory && (modelsSelected || groceryListAdded)"
      :pro-service-requirement="proServiceRequirement"
      :reservation="reservation"
    />
    <div
      v-if="
        serviceCategory === 'grocery' &&
        proServiceRequirement.pro_service_extras &&
        proServiceRequirement.pro_service_extras.grocery_list !== ''
      "
    >
      contact our crew to adjust your grocery list
    </div>
    <ProServiceSelectionFilterModal
      v-if="showFilterModal"
      :model-value="filters"
      :main-tag="mainTag"
      :confirm-button-copy="filterModalConfirmText"
      @confirm-clicked="confirmFilterModal"
      @cancel-clicked="cancelFilterModal"
      @update:model-value="traitSelect"
      @clear-filters="clearFilters"
    />
    <ProServiceModelExtras
      v-if="showAddOnDialog"
      :service-type="mainTag.title"
      :pro-service-requirement="proServiceRequirement"
      :account-id="accountId"
      :reservation-id="reservation.id"
      :shoot-type="shootType"
      :pro-service-extras-options="products"
      @submit="submitPsp"
      @close="closeAddOnDialog"
    />
    <ProServiceGroceryListModal
      v-if="showGroceryModal"
      :pro-service-requirement="proServiceRequirement"
      :reservation-id="reservation.id"
      @cancel="showGroceryModal = false"
      @submit-grocery-list="submitGroceryModal"
    />
    <CrewAssignmentModal
      v-if="crewAssignmentModalVisible"
      :modal-visible="crewAssignmentModalVisible"
      :close-modal="closeCrewAssignmentModal"
      :assign-crew-directly="true"
      :reservation-start="reservation.start"
      :reservation-end="reservation.end"
      :reservation-id="reservation.id"
      :prefilled-reservation-service="{
        title: proServiceRequirement.numbered_title,
        role: proServiceRequirement.selected_tags[0].title,
        id: proServiceRequirement.id,
      }"
    />
    <SoonaDialog
      v-if="showResetGrocerySelectionModal"
      @close="closeResetGrocerySelectionModal"
    >
      <template #heading>reset service</template>
      <p>are you sure you want to reset the grocery list and image?</p>
      <template #footer="{ close }">
        <SoonaButton variation="tertiary" @on-click="close">
          cancel
        </SoonaButton>
        <SoonaButton
          data-cypress="button-dialog-confirm"
          @on-click="resetGrocerySelections"
        >
          reset service
        </SoonaButton>
      </template>
    </SoonaDialog>
    <TypeformModal
      v-if="formIsOpen"
      :form-id="proService.typeform.typeformId"
      :email="pointOfContactEmail"
      :name="pointOfContactName"
      :reservation-id="String(reservation.id)"
      :open="formIsOpen"
      :on-close="handleTypeformClosed"
      :on-submit="handleTypeformSubmitted"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import * as types from 'src/store/mutation-types';
import dateTimeMixin from '../../../../mixins/dateTimeMixin';
import ProServiceProviderSelectionCard from './ProServiceProviderSelectionCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSelect from 'src/components/ui_library/SoonaSelect.vue';
import PaginatorFull from 'src/components/directory/PaginatorFull.vue';
import ProServiceDropdownFilter from './ProServiceDropdownFilter.vue';
import ProServiceSelectionFilterModal from './ProServiceSelectionFilterModal.vue';
import FilterIcon from 'src/components/svgs/FilterIcon.vue';
import ProServiceModelExtras from './ProServiceModelExtras.vue';
import ProServiceSelectionSummaryCard from './ProServiceSelectionSummaryCard.vue';
import ProServiceSelectionProviderDropdown from './ProServiceSelectionProviderDropdown.vue';
import ProServiceGroceryListModal from './ProServiceGroceryListModal.vue';
import ProServiceProviderSelectionViewDetails from './ProServiceProviderSelectionViewDetails.vue';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import CrewAssignmentModal from 'src/components/user/anytime/crew/CrewAssignmentModal.vue';
import { useProServiceEvents } from '@/composables/segment/useProServiceEvents';
import accountImage from 'images/account-placeholder.svg';

import * as Sentry from '@sentry/browser';
import { useGetCrewAssignments } from '@/queries/useGetCrewAssignments';
import PendingIcon from 'src/components/svgs/PendingIcon.vue';
import AcceptedIcon from 'src/components/svgs/AcceptedIcon.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import NoResponseIcon from 'src/components/svgs/NoResponseIcon.vue';
import { convertToMMDDYY } from 'src/lib/date-formatters';
import TypeformModal from 'src/components/TypeformModal.vue';
import { useGetTagFollowUpCategories } from '@/queries/useGetTagFollowUpCategories';
import { useCapabilities } from '@/composables/useCapabilities';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { Tangerine40 } from 'src/variables.module.scss';
import { useGetProServiceProfiles } from 'src/queries/useGetProServiceProfiles';
import { keepPreviousData } from '@tanstack/vue-query';
import { useTagCategoryProducts } from '@/queries/useTagCategoryProducts';

export default {
  name: 'ProServiceSelectionCard',
  components: {
    ProServiceProviderSelectionCard,
    ProServiceProviderSelectionViewDetails,
    SoonaButton,
    SoonaDialog,
    SoonaError,
    SoonaIcon,
    SoonaSelect,
    SoonaTooltip,
    PaginatorFull,
    ProServiceModelExtras,
    ProServiceSelectionFilterModal,
    FilterIcon,
    ProServiceDropdownFilter,
    PendingIcon,
    AcceptedIcon,
    NoResponseIcon,
    ProServiceSelectionSummaryCard,
    SoonaDropdownMenu,
    SoonaDropdownMenuItem,
    ProServiceSelectionProviderDropdown,
    ProServiceGroceryListModal,
    CrewAssignmentModal,
    TypeformModal,
    SoonaFlag,
  },
  mixins: [dateTimeMixin],
  props: {
    proServiceRequirement: Object,
    reservation: Object,
    purchasedProServices: Array,
  },
  setup(props) {
    const { buttonClicked } = useBaseEvents();
    const route = useRoute();
    const { proServiceSelectionStarted } = useProServiceEvents();

    const shootType = computed(() => {
      return props.reservation.shoot_type;
    });

    const { data: products } = useTagCategoryProducts({
      locationId: 1,
      shootType,
    });
    const reservationId = computed(() => props.reservation.id);

    const capabilitiesRef = ref([
      { capability: 'soona_staff' },
      { capability: 'ft_soona_staff' },
      { capability: 'hide_res_pro_services' },
    ]);
    const capabilities = useCapabilities(capabilitiesRef);

    const { data: crewAssignments } = useGetCrewAssignments(reservationId, {
      without_declined: 'false',
    });

    const mainTag = computed(() => {
      return props.proServiceRequirement?.service_tag;
    });

    const serviceCategory = computed(() => {
      return props.proServiceRequirement?.service_category;
    });

    const { data: tagFollowUpCategories } = useGetTagFollowUpCategories(
      computed(() => mainTag.value.id),
      {
        enabled: computed(() => !!mainTag.value),
      }
    );

    const providerStatusInfo = provider => {
      let id = 0;
      let status = '';
      let label = '';
      let date = null;
      if (crewAssignments.value) {
        for (const assignment of crewAssignments.value) {
          if (
            assignment.pro_service_profile?.id ===
              provider.pro_service_profile_id &&
            assignment.pro_service_requirement_id === provider.requirement_id &&
            id < assignment.id
          ) {
            status = assignment.status_detail;
            status = status?.split('_').join(' ');
            date = assignment.status_updated_at;
            label = status == 'no response' ? 'unassigned' : status;
            label =
              assignment.reminder_sent && label == 'sent'
                ? 'reminder sent'
                : label;
            id = assignment.id;
          }
        }
      }
      return { status, date, label };
    };

    const providerStatusDate = provider => {
      let providerInfo = providerStatusInfo(provider);
      const date_str = convertToMMDDYY(providerInfo.date);
      return `${providerInfo.label} ${date_str}`;
    };
    const providerStatus = provider => {
      let providerInfo = providerStatusInfo(provider);
      return providerInfo.status;
    };

    // provider details
    const selectedProviderId = ref(null);

    const openViewDetailsModal = choice => {
      selectedProviderId.value = choice;
    };
    const closeViewDetailsModal = () => {
      selectedProviderId.value = null;
    };

    const filters = ref([]);

    const clearFilters = event => {
      filters.value = [];
      if (event.target.innerText) {
        let subContext = `${mainTag.value.title}`;
        if (event.subContext) {
          subContext = event.subContext;
        }
        buttonClicked({
          context: route.meta.context,
          subContext: subContext,
          buttonLabel: event.target.innerText.toLowerCase(),
          buttonAction: 'clears all pro selection filters',
        });
      }
    };

    const currentPage = ref(1);

    const proServiceRequirement = computed(() => {
      return props.proServiceRequirement;
    });

    const reservationLocation = computed(() => {
      return props.reservation.location;
    });

    const { data: proServiceProfilesData } = useGetProServiceProfiles(
      {
        proServiceCategoryId:
          proServiceRequirement.value?.selected_tags[0].tag_category_id,
        proServiceTypeId: proServiceRequirement.value?.selected_tags[0].id,
        filters: filters,
        locationIds: [reservationLocation.value.id],
        currentPage: currentPage,
        itemsPerPage: 10,
        sort: 'avl',
        isActive: true,
      },
      { placeholderData: keepPreviousData }
    );

    const proServiceProfiles = computed(
      () => proServiceProfilesData.value?.proServiceProfiles ?? []
    );

    const proServiceProfilesPagination = computed(() => {
      if (proServiceProfilesData.value) {
        return proServiceProfilesData.value.pagination;
      } else {
        return {
          totalCount: 0,
          currentPage: 0,
          totalPages: 0,
          itemsPerPage: 0,
        };
      }
    });

    watch(filters.value, () => {
      currentPage.value = 1;
    });

    return {
      accountImage,
      capabilities,
      reservationId,
      crewAssignments,
      mainTag,
      tagFollowUpCategories,
      buttonClicked,
      providerStatus,
      providerStatusDate,
      proServiceSelectionStarted,
      serviceCategory,
      selectedProviderId,
      closeViewDetailsModal,
      openViewDetailsModal,
      Tangerine40,
      proServiceProfilesPagination,
      filters,
      proServiceProfiles,
      clearFilters,
      currentPage,
      shootType,
      products,
    };
  },
  data() {
    return {
      crewAssignmentModalVisible: false,
      showResetSelectionModal: false,
      showResetGrocerySelectionModal: false,
      selectionErrors: {},
      showFilterModal: false,
      traitsLength: 0,
      showHideServiceModal: false,
      showGroceryModal: false,
      reasonError: '',
      hidePSreason: '',
      showAddOnDialog: false,
      formIsOpen: false,
      initialPSPchoices: [{}, {}, {}],
      hideReasons: [
        {
          value: 'upgraded_service_type',
          label: 'upgraded service type',
        },
        {
          value: 'upgraded_service_tier',
          label: 'upgraded service tier',
        },
        {
          value: 'accidentally_added',
          label: 'accidentally added',
        },
        {
          value: 'no_longer_necessary',
          label: 'no longer necessary',
        },
        {
          value: 'booked_overlapping_services',
          label: 'booked overlapping services',
        },
        {
          value: 'pro_service_not_in_scope',
          label: 'pro service not in scope',
        },
        {
          value: 'other',
          label: 'other',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
      currentPSPchoices: state => state.proService.currentPSPchoices,
      currentServiceSelected: state => state.proService.currentServiceSelected,
      tagCategories: state => state.proService.currentTagCategories,
      currentUserId: state => state.currentUser.userID,
      currentUser: state => state.currentUser,
    }),
    proService() {
      // TODO remove this once there are no more typeforms submitted
      let proService = this.purchasedProServices.filter(item => {
        if (
          item?.typeform?.reservation_line_item_id ==
          this.proServiceRequirement.reservation_line_item_id
        ) {
          return item;
        }
      });
      return proService[0];
    },
    typeformSubmitted() {
      return this.proService?.typeform?.submittedAt ? true : false;
    },
    pointOfContactEmail() {
      return this.reservation.account.users.find(
        u => u.id === this.reservation.point_of_contact_user_id
      )?.email;
    },
    accountId() {
      return this.reservation.account.id;
    },
    pointOfContactName() {
      return this.reservation.account.users.find(
        u => u.id === this.reservation.point_of_contact_user_id
      )?.name;
    },
    pspText() {
      if (this.mainTag.title === 'hand model') {
        return 'select your top hand models + add manicure preferences';
      } else if (this.mainTag.title === 'full body model') {
        return 'select your top full body models, manicure, pedicure, hair + makeup, and wardrobe preferences.';
      } else if (this.mainTag.title === 'pet model') {
        return 'select your pet model preferences';
      } else if (this.mainTag.title === 'foot model') {
        return 'select your foot model, pedicure, and wardrobe preferences';
      } else if (this.mainTag.title === 'content creator') {
        return 'use our form to complete your ugc brief and select your content creator. submit your form to move on to the next step of your shoot.';
      } else if (this.mainTag.title === 'grocery shopping') {
        return this.proServiceRequirement.pro_service_extras?.grocery_list
          ? 'we have received your grocery list. if you need to make changes please contact our crew.'
          : 'use our grocery shopping form to add some fresh ingredients to your shoot! submit your grocery list to move on to the next step of your shoot.';
      } else {
        return this.mainTag.description;
      }
    },
    isHidden() {
      return this.proServiceRequirement?.display == false ? true : false;
    },
    disableSelect() {
      if (
        this.currentServiceSelected === this.proServiceRequirement?.id ||
        !this.currentServiceSelected
      ) {
        return false;
      } else {
        return true;
      }
    },
    disableSubmit() {
      if (
        this.currentPSPchoices[0].pro_service_profile_id &&
        (this.currentPSPchoices[1].pro_service_profile_id ||
          this.currentPSPchoices[2].pro_service_profile_id)
      ) {
        return false;
      } else {
        return true;
      }
    },
    proServiceRequirementId() {
      return this.proServiceRequirement?.id;
    },
    computedPSPchoices() {
      // show the local array for non-selected service, show currentPSPchoices from vuex for selected service

      if (!this.selectionOpen) {
        return this.initialPSPchoices;
      } else {
        return this.currentPSPchoices.filter(choice => !choice._destroy);
      }
    },
    filterModalConfirmText() {
      return this.proServiceProfilesPagination.totalCount > 0
        ? 'view ' + this.proServiceProfilesPagination.totalCount + ' models'
        : 'no matching models';
    },
    filteredSortedTagCategories() {
      if (!this.tagFollowUpCategories) return [];

      return this.tagFollowUpCategories.filter(
        tc =>
          tc.title !== 'manicure' &&
          tc.title !== 'manicure color' &&
          tc.title !== 'pronoun'
      );
    },
    quickFilters() {
      if (this.mainTag?.filter_names) {
        const filterNamesArray = this.mainTag.filter_names.split(',');
        return this.tagFollowUpCategories.filter(tc =>
          filterNamesArray.includes(tc.title)
        );
      } else {
        return this.basicInfoTagCategories;
      }
    },
    basicInfoTagCategories() {
      return this.filteredSortedTagCategories.filter(
        tc => tc.special_category === 'psp_trait_basic_info'
      );
    },
    modelsSelected() {
      return (
        !this.selectionOpen &&
        this.computedPSPchoices &&
        this.computedPSPchoices?.filter(c => c.id).length > 1
      );
    },
    groceryListAdded() {
      return (
        !this.selectionOpen &&
        this.proServiceRequirement.pro_service_extras?.grocery_list
      );
    },
    selectedModels() {
      return this.initialPSPchoices.filter(
        choice => choice.pro_service_profile_id
      );
    },
    currentDropDownOptions() {
      let actionList =
        this.serviceCategory === 'model' || this.isCreator
          ? [
              {
                click: this.selectPSP,
                text: 'edit selection',
                key: 'edit_selection',
              },
              {
                click: this.openAddOnModal,
                text: 'edit details',
                key: 'edit_details',
              },
            ]
          : [];
      if (this.capabilities.ft_soona_staff.hasCapability) {
        if (['model', 'creator'].includes(this.serviceCategory)) {
          actionList.push({
            click: this.openResetSelectionModal,
            text: 'reset selection',
            key: 'reset_selection',
            disabled:
              this.serviceHasPendingOrAcceptedProvider ||
              this.selectedModels.length === 0,
          });
        } else if (this.serviceCategory === 'grocery') {
          actionList.push({
            click: this.openCrewAssignmentModal,
            text: 'assign crew',
            key: 'assign_crew',
          });
          actionList.push({
            click: this.openResetGrocerySelectionModal,
            text: 'reset service',
            key: 'reset service',
          });
        }
      }
      if (this.capabilities.hide_res_pro_services.hasCapability) {
        if (
          (this.reservation.scheduled_reservation_date &&
            this.daysBeforeStart <= 1) ||
          (this.serviceCategory !== 'model' &&
            this.serviceCategory !== 'grocery' &&
            this.serviceCategory !== 'creator')
        ) {
          actionList = [
            {
              click: this.openHideServiceModal,
              text: 'hide pro service',
              key: 'hide_pro_service',
            },
          ];
        } else {
          actionList.push({
            click: this.openHideServiceModal,
            text: 'hide pro service',
            key: 'hide_pro_service',
          });
        }
      }
      return actionList;
    },
    daysBeforeStart() {
      return this.workingDaysBetweenDates(
        new Date(),
        new Date(this.reservation.scheduled_reservation_date),
        []
      );
    },
    actionDropdownDisabled() {
      let isDisabled = false;
      if (this.capabilities.ft_soona_staff.hasCapability) {
        if (!this.capabilities.hide_res_pro_services.hasCapability) {
          isDisabled = this.reservation.scheduled_reservation_date
            ? this.daysBeforeStart <= 1
            : false;
        }
      } else {
        if (this.reservation.reservation_type === 'in_studio') {
          isDisabled = this.serviceHasAssignedProvider;
        } else {
          isDisabled = this.reservation.scheduled_reservation_date;
        }
      }
      return isDisabled;
    },
    serviceHasPendingOrAcceptedProvider() {
      return this.crewAssignments?.some(
        aIndex =>
          this.crewAssignments[aIndex]?.pro_service_requirement_id ===
            this.proServiceRequirementId &&
          ['sent', 'accepted'].includes(this.crewAssignments[aIndex].status)
      );
    },
    serviceHasAssignedProvider() {
      return this.crewAssignments?.some(
        aIndex =>
          this.crewAssignments[aIndex]?.pro_service_requirement_id ===
          this.proServiceRequirementId
      );
    },
    selectionStatus() {
      let declined = 0;
      let sent = 0;
      let mostRecentDeclined = null;
      if (this.serviceCategory === 'model' || this.isCreator) {
        for (let selection of this.initialPSPchoices) {
          for (let i in this.crewAssignments) {
            let assignment = this.crewAssignments[i];
            if (
              selection.pro_service_profile_id &&
              selection.pro_service_profile_id ===
                assignment.pro_service_profile?.id &&
              selection.requirement_id === assignment.pro_service_requirement_id
            ) {
              if (assignment.status === 'accepted') {
                return 'accepted';
              } else if (
                ['declined', 'canceled', 'no_response', 'removed'].indexOf(
                  assignment.status
                ) > -1
              ) {
                declined++;
                if (
                  mostRecentDeclined == null ||
                  new Date(mostRecentDeclined.status_updated_at) <
                    new Date(assignment.status_updated_at)
                ) {
                  mostRecentDeclined = assignment;
                }
              } else if (
                assignment.status === 'sent' ||
                assignment.status === 'reminder_sent'
              ) {
                sent++;
              }
            }
          }
        }
      } else if (this.serviceCategory === 'grocery') {
        for (let i in this.crewAssignments) {
          let assignment = this.crewAssignments[i];
          if (
            this.proServiceRequirement.id ===
            assignment.pro_service_requirement_id
          ) {
            if (assignment.status === 'accepted') {
              return 'accepted';
            } else if (
              ['declined', 'canceled', 'no_response', 'removed'].indexOf(
                assignment.status
              ) > -1
            ) {
              declined++;
              if (
                mostRecentDeclined == null ||
                new Date(mostRecentDeclined.status_updated_at) <
                  new Date(assignment.status_updated_at)
              ) {
                mostRecentDeclined = assignment;
              }
            } else if (
              assignment.status === 'sent' ||
              assignment.status === 'reminder_sent'
            ) {
              sent++;
            }
          }
        }
      }
      if (sent) {
        return 'pending';
      } else if (declined > 0 && declined >= sent) {
        return this.statusBannerText(mostRecentDeclined);
      } else {
        return '';
      }
    },
    isCreator() {
      return this.serviceCategory === 'creator';
    },
    selectionOpen() {
      return this.currentServiceSelected === this.proServiceRequirementId;
    },
    providerSelected() {
      return this.initialPSPchoices[0]?.pro_service_profile_id ? true : false;
    },
    hidePlaceholders() {
      return !this.selectionOpen && this.providerSelected;
    },
    computedPSPchoicesToDisplay() {
      if (this.hidePlaceholders) {
        return this.computedPSPchoices.filter(
          choice => choice.pro_service_profile_id
        );
      }
      return this.computedPSPchoices.filter((_, index) => index <= 2);
    },
  },
  watch: {
    proServiceRequirement: {
      handler() {
        this.createInitialPSPchoices();
      },
      deep: true,
    },
  },
  mounted() {
    this.createInitialPSPchoices();
  },
  methods: {
    ...mapMutations('proService', [
      types.CLEAR_CURRENT_PSP_CHOICES,
      types.RESET_CURRENT_PSP_CHOICES,
      types.SELECT_CURRENT_SERVICE,
      types.REMOVE_SELECTED_PROVIDER,
      types.SET_CURRENT_PSP_CHOICE,
    ]),
    ...mapActions('proService', [
      'submitChosenTraits',
      'hideProService',
      'postProServiceRequirementChoices',
    ]),
    ...mapActions('reservation', ['submitTypeform']),
    bannerText(choice) {
      switch (this.computedPSPchoices.indexOf(choice)) {
        case 0:
          return 'TOP CHOICE';
        case 1:
          return 'ALTERNATE';
        case 2:
          return 'ALTERNATE';
      }
    },
    statusBannerText(assignment) {
      let status = assignment.status_detail;
      if (status == 'reminder sent' || status == 'sent') {
        return 'pending';
      } else if (status == 'removed') {
        return 'canceled';
      } else {
        return status;
      }
    },
    createInitialPSPchoices() {
      this.initialPSPchoices = [{}, {}, {}];
      // if there are choices saved in the DB - add to local array to be displayed
      if (
        this.proServiceRequirement.pro_service_requirement_choices.length > 0
      ) {
        this.proServiceRequirement.pro_service_requirement_choices.forEach(
          choice => {
            this.initialPSPchoices[Number(choice.order)] = {
              id: choice.id,
              pro_service_profile_id: choice.pro_service_profile?.id,
              image_url: choice.pro_service_profile?.image_urls
                ? choice.pro_service_profile?.image_urls[0]
                : accountImage,
              order: choice.order,
              initial_order: choice.order,
              user_id: choice.pro_service_profile.user_id,
              account_id: choice.pro_service_profile.account_id,
              provider_name: choice.pro_service_profile.provider_name,
              user_name: choice.pro_service_profile.user_name,
              requirement_id: this.proServiceRequirement?.id,
              source: choice.source,
            };
          }
        );
      }
    },
    async selectPSP() {
      await this.CLEAR_CURRENT_PSP_CHOICES();
      // set currentPSPchoices array in state for the current expanded service
      this.initialPSPchoices.map(async choice => {
        choice.pro_service_requirement_id = this.proServiceRequirement.id;
        await this.SET_CURRENT_PSP_CHOICE(choice);
      });
      await this.SELECT_CURRENT_SERVICE(this.proServiceRequirement?.id);
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: this.mainTag.title,
        buttonLabel: 'select model',
        buttonAction: 'starts model selection process',
      });
      this.proServiceSelectionStarted({
        context: this.$route.meta.context,
        subContext: this.mainTag.title,
        reservationId: this.reservationId,
        proServiceRequirementId: this.proServiceRequirementId,
      });
    },
    cancelSelection() {
      this.SELECT_CURRENT_SERVICE(null);
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.mainTag.title}`,
        buttonLabel: 'cancel',
        buttonAction: 'cancels model selection process',
      });
    },
    async openAddOnModal() {
      if (
        this.currentPSPchoices[0] &&
        this.currentPSPchoices[0].pro_service_requirement_id !==
          this.proServiceRequirement?.id
      ) {
        this.CLEAR_CURRENT_PSP_CHOICES();
        this.initialPSPchoices.map(async choice => {
          choice.pro_service_requirement_id = this.proServiceRequirement.id;
          await this.SET_CURRENT_PSP_CHOICE(choice);
        });
      }
      this.showAddOnDialog = true;
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.mainTag.title}`,
        buttonLabel: 'submit',
        buttonAction: 'opens model details & extras modal',
      });
    },
    submitPsp(payload) {
      this.SELECT_CURRENT_SERVICE(null);
      this.postProServiceRequirementChoices({
        reservationId: this.reservationId,
        pspRequirementId: this.proServiceRequirement?.id,
        extrasPayload: payload.extrasPayload,
        imagesPayload: payload.imagesPayload,
        proServiceSelectionReset: payload.proServiceSelectionReset,
        status: 'submitted',
      })
        .then(() => {
          if (this.currentPSPchoices.length > 0) {
            this.initialPSPchoices = this.currentPSPchoices.filter(
              choice => !choice._destroy
            );
          }
          delete this.selectionErrors['submissionError'];
        })
        .catch(error => {
          this.selectionErrors['submissionError'] = {
            message:
              'something went wrong and your selections were not submitted. please re-submit your model selections and details.',
            error: error,
          };
          Sentry.captureException(
            new Error('failed to submit pro service selections'),
            {
              extra: {
                reservation: this.reservation,
                error: error,
              },
            }
          );
        });
      this.closeAddOnDialog(false);
    },
    hideServiceConfirm() {
      if (!this.hidePSreason) {
        this.reasonError = `please enter a reason`;
        return;
      }
      let payload = {
        reservationId: this.reservationId,
        pspRequirementId: this.proServiceRequirement?.id,
        reason: this.hidePSreason,
      };
      this.hideProService(payload).then(() => {
        this.resetSelections();
        this.reasonError = '';
        this.closeHideServiceModal();
      });
    },
    openHideServiceModal() {
      this.showHideServiceModal = true;
    },
    closeHideServiceModal() {
      this.showHideServiceModal = false;
    },
    openGroceryModal() {
      this.showGroceryModal = true;
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `grocery shopping`,
        buttonLabel: 'add details',
        buttonAction: 'opens grocery shopping details and extras modal',
      });
    },
    submitGroceryModal(payload) {
      this.submitPsp(payload);
      this.showGroceryModal = false;
    },
    removeProvider(choice) {
      this.REMOVE_SELECTED_PROVIDER(choice);
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.mainTag.title} selected providers`,
        buttonLabel: 'x',
        buttonAction: 'removes selected pro service provider',
      });
    },
    closeAddOnDialog(fireButtonClickedEvent = true) {
      this.showAddOnDialog = false;
      if (fireButtonClickedEvent) {
        this.buttonClicked({
          context: this.$route.meta.context,
          subContext: `${this.mainTag.title} details & extras modal`,
          buttonLabel: 'cancel',
          buttonAction: 'closes model details & extras modal',
        });
      }
    },
    openFilterModal() {
      this.showFilterModal = true;
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.mainTag.title}`,
        buttonLabel: 'filters',
        buttonAction: 'opens model filters modal',
      });
    },
    confirmFilterModal(event) {
      this.showFilterModal = false;
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.mainTag.title} filters modal`,
        buttonLabel: event.target.innerText.toLowerCase(),
        buttonAction: `updates pro service selection options`,
      });
    },
    cancelFilterModal() {
      this.showFilterModal = false;
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: `${this.mainTag.title} filters modal`,
        buttonLabel: 'x',
        buttonAction: 'closes model filters modal',
      });
    },
    openResetGrocerySelectionModal() {
      this.showResetGrocerySelectionModal = true;
    },
    closeResetGrocerySelectionModal() {
      this.showResetGrocerySelectionModal = false;
    },
    openResetSelectionModal() {
      this.showResetSelectionModal = true;
    },
    closeResetSelectionModal() {
      this.showResetSelectionModal = false;
    },
    traitSelect(item) {
      let indexNumber = this.filters.findIndex(tag => tag === item);

      if (indexNumber > -1) {
        this.filters.splice(indexNumber, 1);
      } else {
        this.filters.push(item);
      }
    },
    async resetGrocerySelections() {
      this.submitPsp({
        extrasPayload: { grocery_list: '' },
        imagesPayload: {
          grocery_shopping_reference_image: null,
        },
        proServiceSelectionReset: true,
      });
      this.closeResetGrocerySelectionModal();
    },
    async resetSelections() {
      this.CLEAR_CURRENT_PSP_CHOICES();
      this.initialPSPchoices.map(async choice => {
        choice.pro_service_requirement_id = this.proServiceRequirement.id;
        this.SET_CURRENT_PSP_CHOICE(choice);
      });
      this.RESET_CURRENT_PSP_CHOICES();
      await this.postProServiceRequirementChoices({
        reservationId: this.reservationId,
        pspRequirementId: this.proServiceRequirement?.id,
        status: 'selection_reset',
      });
      this.SELECT_CURRENT_SERVICE(null);
      this.CLEAR_CURRENT_PSP_CHOICES();
      this.initialPSPchoices = this.currentPSPchoices;
      this.closeResetSelectionModal();
    },
    formatName(name) {
      let nameArray = name.split(' ');
      return nameArray.length > 1
        ? nameArray[0] + ' ' + nameArray[1].slice(0, 1) + '.'
        : nameArray[0];
    },
    openCrewAssignmentModal() {
      this.crewAssignmentModalVisible = true;
    },
    closeCrewAssignmentModal() {
      this.crewAssignmentModalVisible = false;
    },
    handleTypeformSubmitted(submissionId) {
      this.submitTypeform({
        embeddedTypeformId: this.proService.typeform.id,
        reservationId: this.reservationId,
        productId: this.proService.id,
        submissionId,
        submittedBy: this.currentUserId,
      });
      this.formIsSubmitted = true;
    },
    handleTypeformClosed() {
      this.formIsOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.ProServiceSelectionCard {
  color: variables.$black-default;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.1875rem;
  box-shadow: 0 0.25rem 0.375rem rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 0.9375rem;
  .non-select-view {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 10rem;

    .service-image {
      display: block;
      height: 9.375rem;
      width: 9.375rem;
      object-fit: cover;
      padding: 0.625rem;
      box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
    }

    .requirement-header {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;

      .service-description {
        display: flex;
        flex-direction: column;
        flex: 1 1 28rem;

        .title-and-button {
          display: flex;
          flex-wrap: wrap;
          gap: 1rem;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          .select-choice-dropdown {
            .menu-button:disabled {
              color: variables.$gray-40;
              cursor: not-allowed;
            }
          }
        }
        .provider-selection-display {
          display: flex;
          flex-wrap: wrap;
          gap: 0.75rem;
          margin-bottom: 0.5rem;
          .provider-selection {
            display: flex;
            flex-direction: column;
            max-width: fit-content;
            justify-content: flex-start;
            position: relative;

            .provider-status-date {
              font-size: 0.625rem;
              margin: -0.5rem 1rem 0.5rem 1rem;
            }
            .provider-name {
              font-weight: 800;
              font-size: 1.25rem;
              letter-spacing: 0.03125rem;
              margin: 0rem 1rem 0rem 1rem;
            }

            .profile-name {
              font-weight: 300;
              font-size: 0.875rem;
              letter-spacing: 0.03125rem;
              margin: 0rem 1rem 0.5rem 1rem;
              min-height: 25px;
            }

            .provider-actions-dropdown {
              margin-bottom: 1.5rem;
            }
            .provider-profile-image {
              display: flex;
              position: relative;
              margin-bottom: 1rem;

              &:hover {
                .view-details-section {
                  .view-details {
                    opacity: 1 !important;
                    transition: fill 0.1s ease-out !important;
                  }
                }
              }

              .view-details-section {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;

                .view-details {
                  background: variables.$tangerine-20;
                  border: 1px solid variables.$tangerine-20;
                  font-size: 7px;
                  font-weight: 400;
                  letter-spacing: 1.5px;
                  text-transform: uppercase;
                  opacity: 0;
                }
              }

              .image-banner {
                color: variables.$white-default;
                background-color: variables.$black-default;
                padding: 0.125rem 0.3125rem;
                font-size: 10px;
                font-weight: 800;
                position: absolute;
                bottom: 0.75rem;
                &.crew-view {
                  font-size: 0.875rem;
                  letter-spacing: 0.125rem;
                }
              }

              .choice-badge {
                position: absolute;
                bottom: 2.313rem;
              }

              .profile-image-wrapper {
                display: flex;
                position: relative;
                width: max-content;
                img {
                  box-shadow: none;
                }
                .delete-btn {
                  align-items: center;
                  color: variables.$white-default;
                  display: flex;
                  justify-content: center;
                  padding: 0;
                  position: absolute;
                  background-color: variables.$gray-80;
                  height: 1.25rem;
                  width: 1.25rem;
                  border-radius: 50%;
                  right: -0.5rem;
                  top: -0.5rem;
                }
                .status-icon {
                  position: absolute;
                  right: -0.5rem;
                  top: -0.5rem;
                  width: 1.5rem;
                  height: 1.5rem;

                  &--declined {
                    color: variables.$roses-70;
                  }
                }
              }
              .image-display,
              .emoji-display {
                height: 5.875rem;
                width: 5.875rem;
                object-fit: cover;
                border: 0.0625rem solid variables.$gray-30;
                border-radius: 5% 5% 5% 5%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                &.crew-view {
                  border: none;
                  height: 12.5rem;
                  width: 12.5rem;
                  border-radius: 2% 2% 0% 0%;
                }
              }
              .emoji-display {
                box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
              }

              .heart-container {
                align-items: center;
                border: 0.0625rem solid variables.$friendly-red-30;
                border-radius: 50%;
                color: variables.$friendly-red-50;
                display: flex;
                height: 2.875rem;
                justify-content: center;
                width: 2.875rem;
              }

              &.unfocused {
                opacity: 0.5;
              }
            }
            &.crew-view {
              border: 0.0625rem solid variables.$gray-30;
              border-radius: 0.3125rem;
            }
          }
        }
      }
      &.expanded {
        margin-left: 0.625rem;
      }
      .selection-description {
        margin-bottom: 1rem;
        .content-creator-gallery-link {
          text-decoration: underline;
          display: inline-flex;
          flex-flow: row wrap;
          gap: 0.25rem;
          align-items: center;
        }
      }
      .reason-wrapper {
        margin-top: 0.625rem;
        .reason {
          @include variables_fonts.u-label--regular;
        }
      }
      .psp-service-name {
        @include variables_fonts.u-subheader--all-caps-black;

        .heading-text {
          padding-right: 0.625rem;
        }

        .banner {
          padding: 0.1875rem 0.3125rem;
          font-size: 0.8125rem;
          color: variables.$white-default;
          &.accepted {
            background-color: variables.$avo-toast-60;
          }
          &.pending {
            background-color: variables.$tangerine-70;
          }
          &.declined,
          &.no_response,
          &.canceled {
            background-color: variables.$roses-70;
          }
        }
      }
    }
    .requirement-footer {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      gap: 1rem;
      margin-bottom: 1.125rem;
    }
  }
  .select-view {
    .gallery-filters {
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.5rem;

      .quick-filters {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        .dropdown-filters {
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem;
        }
      }
      .filters-modal-button {
        min-width: fit-content;
      }
      .filters-modal-button-active {
        background-color: variables.$friendly-red-10;
      }
    }
    .psp-gallery {
      display: flex;
      flex-wrap: wrap;
      gap: 0.75rem 0.4rem;
      padding: 0.625rem;
      .gallery-paginator {
        border: none !important;
        justify-content: flex-end !important;
      }
    }
  }
  .select-view-no-providers {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0.75rem;
    .no-providers-message {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
      p {
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: variables.$screen-xs-max) {
  .ProServiceSelectionCard {
    padding: 2.1875rem 0 !important;
    box-shadow: 0 0.0625rem rgba(0, 0, 0, 0.1);
    justify-content: center !important;
    .non-select-view {
      flex-direction: column;
      align-items: center;
      padding-left: 0;
      .requirement-header {
        margin-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        margin-bottom: 0.5rem;
        .service-description {
          .title-and-button {
            flex-direction: column;
          }
        }
        .psp-service-name {
          text-align: center;
          margin-bottom: 0.3125rem;
          margin-top: 0.625rem;
        }
      }
      .provider-selection-display {
        justify-content: center;
      }
    }
    .select-view {
      .gallery-filters {
        justify-content: center;
        .quick-filters {
          display: none;
        }
      }
      .psp-gallery {
        justify-content: center;
      }
    }
  }
  .requirement-footer {
    width: 100%;
    justify-content: center;
    margin-top: 0.9375rem;
  }
}
.hidden {
  opacity: 0.5;
}

.dialog-text {
  padding-bottom: 1rem;
}
</style>
