<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useRemoveDraft } from '@/queries/reservations/useRemoveDraft';
import { convertToMMDDYYYYlong } from '@/lib/date-formatters';
import truncate from 'lodash/truncate';
import unescape from 'lodash/unescape';
import GalleryCardBadge from '@/components/user/anytime/dashboard/GalleryCardBadge.vue';
import ReservationTime from '@/components/bookings/ReservationTime.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SpeedyCheckoutBadge from '@/components/bookings/SpeedyCheckoutBadge.vue';

const props = defineProps({
  reservation: {
    type: Object,
    required: true,
  },
});

const speedyCheckoutFlag = useFlag('apollo_speedy_checkout');

const id = computed(() => `res-card-${props.reservation.id}`);
const { hasCapability: remove_reservation_draft } = useCapability({
  capability: 'remove_reservation_draft',
});
const {
  mutate: removeDraft,
  isPending: isRemovingDraft,
  error: removeDraftError,
} = useRemoveDraft(computed(() => props.reservation.id));
const store = useStore();
const isOwnAccount = computed(
  () => store.state['currentUser/accountId'] === props.reservation.account_id
);

const imageUrl = computed(
  () =>
    props.reservation.preview_url ??
    props.reservation.location?.booking_gallery_image_url
);

const truncatedReservationName = computed(() =>
  truncate(unescape(props.reservation.name), {
    length: 60,
    omission: '…',
  })
);

const isWrapped = computed(() => {
  return ['pending_selects', 'edits_required', 'completed'].includes(
    props.reservation.status
  );
});

const expirationDisplay = computed(() =>
  props.reservation.expiration
    ? convertToMMDDYYYYlong(props.reservation.expiration)
    : null
);

const isDraft = computed(() => props.reservation.status === 'draft');

const isSuggestedDraft = computed(
  () => props.reservation.draft_type === 'suggested_draft'
);

const isClientDraft = computed(
  () => props.reservation.draft_type === 'client_draft'
);

const isClientOrSuggestedDraft = computed(
  () => isClientDraft.value || isSuggestedDraft.value
);
const showRemoveDraft = computed(
  () =>
    isClientOrSuggestedDraft.value &&
    (remove_reservation_draft || isOwnAccount.value)
);
const showLive = computed(
  () =>
    props.reservation.status === 'in_progress' &&
    props.reservation.reservation_type !== 'surprise'
);
const shootType = computed(() => props.reservation.shoot_type);
const productCount = computed(() => {
  return props.reservation.product_count === 1
    ? '1 product'
    : `${props.reservation.product_count.toLocaleString()} products`;
});

const resCardRoute = computed(() => {
  if (isDraft.value && isClientDraft.value) {
    return `/booking/${props.reservation.id}`;
  }
  return `/reservation/${props.reservation.id}/info`;
});
</script>

<template>
  <article :aria-labelledby="`${id}-heading`" class="res-card">
    <div
      class="res-card__image"
      :style="imageUrl ? `background-image: url(${imageUrl});` : ''"
    >
      <SpeedyCheckoutBadge
        v-if="speedyCheckoutFlag && reservation?.fast_checkout_eligible"
        :checkout-deadline="reservation.fast_checkout_deadline"
        :reservation-id="reservation.id"
      />
    </div>

    <div class="res-card__content">
      <div v-if="!isWrapped" class="res-card__content__badges">
        <GalleryCardBadge :reservation="reservation" type="bookingType" />
        <GalleryCardBadge :reservation="reservation" type="scheduleStatus" />
      </div>
      <div class="res-card__content__upper">
        <template v-if="isWrapped">
          <h2 :id="`${id}-heading`" class="res-card__heading">
            <router-link
              :to="`/reservation/${reservation.id}`"
              data-cypress="reservation-card-name"
            >
              {{ truncatedReservationName }}
            </router-link>
          </h2>
          <ReservationTime :date="reservation.start" :is-upcoming="false" />
        </template>
        <template v-else>
          <ReservationTime
            v-if="reservation.start"
            :date="reservation.start"
            :is-upcoming="true"
          />
          <p v-else-if="!isDraft" class="res-card__unscheduled">@tbd</p>
          <h2 :id="`${id}-heading`" class="res-card__heading">
            <router-link
              :to="resCardRoute"
              data-cypress="reservation-card-name"
            >
              {{ truncatedReservationName }}
            </router-link>
          </h2>
        </template>
        <template v-if="isDraft && isClientOrSuggestedDraft">
          <p
            v-if="
              isSuggestedDraft &&
              reservation.creator &&
              reservation.creator.name
            "
            class="u-label--regular"
          >
            created by:
            <span class="u-label--heavy">{{ reservation.creator.name }}</span>
          </p>
          <p v-if="expirationDisplay" class="u-label--regular">
            expires on:
            <span class="u-label--heavy">{{ expirationDisplay }}</span>
          </p>
          <router-link
            v-if="reservation.previous_id"
            :to="`/reservation/${reservation.previous_id}/info`"
            class="res-card__continuation-link"
          >
            view related shoot
          </router-link>
        </template>
        <div v-if="!isWrapped" class="res-card__summary__shoot-type">
          <SoonaIcon
            v-if="shootType === 'photo'"
            name="camera"
            size="x-small"
          />
          <SoonaIcon v-if="shootType === 'video'" name="video" size="x-small" />
          {{ shootType }}
        </div>
      </div>

      <div class="res-card__content__lower">
        <SoonaButton
          v-if="showLive"
          element="router-link"
          :to="`/reservation/${reservation.id}/`"
          variation="primary"
          size="large"
          class="pulse-live"
        >
          join live
        </SoonaButton>

        <div
          v-if="isDraft && isClientOrSuggestedDraft"
          class="res-card__draft-options"
        >
          <SoonaButton
            element="router-link"
            :to="`/booking/${reservation.id}`"
            variation="secondary-black"
            size="medium"
          >
            complete booking
          </SoonaButton>
          <SoonaButton
            v-if="showRemoveDraft"
            :disabled="isRemovingDraft"
            title="remove booking"
            variation="icon-plain-gray"
            size="medium"
            @on-click="removeDraft"
          >
            <SoonaIcon name="trash" />
            <span class="u-a11y-only">remove draft</span>
          </SoonaButton>
          <SoonaError v-if="removeDraftError">{{
            removeDraftError.message
          }}</SoonaError>
        </div>

        <div v-if="isWrapped" class="res-card__summary">
          <div class="res-card__summary__shoot-type">
            <SoonaIcon
              v-if="shootType === 'photo'"
              name="camera"
              size="x-small"
            />
            <SoonaIcon
              v-if="shootType === 'video'"
              name="video"
              size="x-small"
            />
            {{ shootType }}
          </div>
          <div class="res-card__summary__products">
            <SoonaIcon name="products" size="x-small" />
            <p>{{ productCount }}</p>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.res-card {
  background: variables.$white-default;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  /* to clip images with this border-radius */
  overflow: hidden;
  color: variables.$black-default;
  position: relative;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: variables.$gray-10;
  }

  &__image {
    object-fit: cover;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: variables.$gray-10;
    aspect-ratio: 3 / 2;
    border-bottom: 0.0625rem solid variables.$gray-30;
    position: relative;
  }

  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1rem;

    &__badges {
      display: flex;
      flex-flow: row wrap;
      gap: 0.5rem;
    }

    &__upper {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &__lower {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &:empty {
        display: none;
      }
    }
  }

  &__heading {
    @include variables_fonts.u-subheader--heavy;

    & a {
      outline: none;
      color: variables.$black-default;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
        border-radius: 0.5625rem;
      }

      &:active::before,
      &:focus-visible::before {
        border: 0.125rem solid variables.$periwink-blue-60;
      }
    }
  }

  &__unscheduled {
    @include variables_fonts.u-title--heavy;
  }

  &__continuation-link {
    @include variables_fonts.u-label--regular;

    position: relative;
    z-index: 1;
    text-decoration: underline;
  }

  &__summary {
    display: flex;
    justify-content: space-between;

    &__products,
    &__shoot-type {
      @include variables_fonts.u-label--regular;

      align-items: center;
      color: variables.$gray-60;
      display: flex;
      gap: 0.25rem;
    }
  }

  &__draft-options {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;

    a,
    button {
      position: relative;
      z-index: 1;
    }
  }

  :deep(.pulse-live) {
    z-index: 1;
    width: 100%;
    animation: pulse-live 2s infinite;
    box-shadow: 0 0 0 0 rgba(variables.$friendly-red-50, 1);
  }

  @media (prefers-reduced-motion: reduce) {
    :deep(.pulse-live) {
      animation: none;
      box-shadow: none;
    }
  }
}

@keyframes pulse-live {
  0% {
    transform: scale(0.97);
    box-shadow: 0 0 0 0 rgba(variables.$friendly-red-50, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 0.5rem rgba(variables.$friendly-red-50, 0);
  }

  100% {
    transform: scale(0.97);
    box-shadow: 0 0 0 0 rgba(variables.$friendly-red-50, 0);
  }
}

@supports not (aspect-ratio: 3 / 2) {
  .res-card__image::before {
    float: left;
    padding-top: 66.66666667%;
    content: '';
  }

  .res-card__image::after {
    display: block;
    content: '';
    clear: both;
  }
}
</style>
