import { soonaToastVariations } from '@/components/ui_library/soona_toast/soona-toast-variations';
import { warn, info, PREFIX as LOGGER_PREFIX } from './logger';
import * as Sentry from '@sentry/vue';
import throttle from 'lodash/throttle';

const MUTATION_PREFIX = 'SOCKET_';

class Event {
  constructor({ type, body }) {
    const [namespace, action] = type.split('/');
    this.namespace = namespace;
    this.action = action;
    this.body = body;
  }

  mutationName(store) {
    const mutationNamespace = store === 'default' ? '' : `${store}/`;

    return `${mutationNamespace}${MUTATION_PREFIX}${this.namespace.toUpperCase()}_${this.action.toUpperCase()}`;
  }

  actionName(store) {
    const actionNamespace = store === 'default' ? '' : `${store}/`;

    return `${actionNamespace}${MUTATION_PREFIX}${this.namespace.toUpperCase()}_${this.action.toUpperCase()}`;
  }
}

export class StoreEmitter {
  constructor(store = {}, subscriptions) {
    this.store = store;
    this.listeners = new Map();

    this.setup(subscriptions);
  }

  setup(subscriptions) {
    for (const namespace in subscriptions) {
      this.listeners.set(namespace, subscriptions[namespace]);
    }
  }

  emit(type, body) {
    const event = new Event({ type, body });

    if (this.listeners.has(event.namespace)) {
      this.listeners.get(event.namespace).stores?.forEach(store => {
        this.commitMutation(event.mutationName(store), event.body);
        this.dispatchAction(event.actionName(store), event.body);
      });
    } else {
      warn(`not listening for events on namespace ${event.namespace}`);
      Sentry.captureException(
        new Error(
          `${LOGGER_PREFIX} received event for non-subscribed namespace "${event.namespace}"`
        ),
        { extra: { event } }
      );
    }
  }

  commitMutation(mutationType, payload) {
    if (mutationType in this.store._mutations) {
      info(`committing mutation: ${mutationType}`);
      this.store.commit(mutationType, payload);
    } else {
      warn(`no mutation defined for ${mutationType}`);
    }
  }

  dispatchAction(actionName, payload) {
    if (actionName in this.store._actions) {
      info(`dispatching action: ${actionName}`);
      this.store.dispatch(actionName, payload);
    } else {
      warn(`no action defined for ${actionName}`);
    }
  }
}

export class QueryEmitter {
  constructor(queryClient, queryKeys, subscriptions) {
    this.client = queryClient;
    this.keys = queryKeys;
    this.listeners = new Map();
    this.handlers = new Map();
    this.setup(subscriptions);
  }

  setup(subscriptions) {
    for (const namespace in subscriptions) {
      this.listeners.set(namespace, subscriptions[namespace]);
    }
  }

  getHandler(ns) {
    const handler = this[ns].bind(this);
    if (!handler) return false;
    return throttle(handler, 1000);
  }

  emit(type, body) {
    const event = new Event({ type, body });

    if (this.listeners.has(event.namespace)) {
      if (this.listeners.get(event.namespace).query) {
        if (!this.handlers.has(event.namespace)) {
          this.handlers.set(event.namespace, this.getHandler(event.namespace));
        }

        const handler = this.handlers.get(event.namespace);
        if (handler) {
          handler(body);
        }
      }
    } else {
      warn(`not listening for events on namespace ${event.namespace}`);
      Sentry.captureException(
        new Error(
          `${LOGGER_PREFIX} received event for non-subscribed namespace "${event.namespace}"`
        ),
        { extra: { event } }
      );
    }
  }

  albumcollectiondigitalasset(body) {
    this.collectiondigitalasset(body, 'album_collection');
  }

  animatedcollectiondigitalasset(body) {
    this.collectiondigitalasset(body, 'animated_collection');
  }

  bagcollectiondigitalasset() {
    // no-op handled by reservation-line-items
    return true;
  }

  collectiondigitalasset(body, collectionType) {
    const accountId = body['account_id'];
    const collectionDigitalAssetId = body['id'];
    const collectionId = body['collection_id'];
    const digitalAssetId = body['digital_asset_id'];

    this.client.invalidateQueries({
      queryKey: this.keys.collectionDA(accountId, collectionType, collectionId),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.collectionAssets(collectionId),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.collectionAsset(
        collectionId,
        collectionDigitalAssetId
      ),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.collectionAssetByDigitalAsset(
        collectionId,
        collectionType,
        digitalAssetId
      ),
    });
  }

  embeddingcvservicetaskmanager(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.digitalAssets(body['account_id']),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.embeddingsMetrics(body['account_id']),
    });
  }

  digitalasset(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.digitalAssets(body['account_id']),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.digitalAsset(body['account_id'], body['id']),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.mokkerDigitalAsset(body['uuid']),
    });
  }

  digitalassetbulkpublishmanager(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.listingActions(body['account_id']),
    });
  }

  digitalassetbulkactiontask(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.listingActions(body['account_id']),
    });
    if (body['integration_id'] && body['external_product_id']) {
      this.client.invalidateQueries({
        queryKey: this.keys.activeListing(
          body['integration_id'],
          body['external_product_id']
        ),
      });
    }
  }

  digitalassetpublishtask(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.listingActions(body['account_id']),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.activeListing(
        body['integration_id'],
        body['external_product_id']
      ),
    });
  }

  editscollectiondigitalasset(body) {
    this.collectiondigitalasset(body, 'edits_collection');
  }

  favoritescollectiondigitalasset(body) {
    this.collectiondigitalasset(body, 'favorites_collection');
  }

  importtask(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.integrations(body['account_id']),
    });
  }

  integration(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.integrations(body['account_id']),
    });
  }

  note(body) {
    if (body['subject_type'] === 'DigitalAsset') {
      this.client.invalidateQueries({
        queryKey: this.keys.digitalAsset(
          body['account_id'],
          body['subject_id']
        ),
      });
      this.client.invalidateQueries({
        queryKey: this.keys.reservationDigitalAssets(),
      });
    }
  }

  questtask(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.quest(body['quest_id']),
    });
  }

  reeditscollectiondigitalasset(body) {
    this.collectiondigitalasset(body, 're_edits_collection');
  }

  reservation(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.reservation(body['id']),
    });

    this.client.invalidateQueries({
      queryKey: this.keys.reservations(),
    });
  }

  reservationdigitalasset(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.reservationDigitalAssets(body['reservation_id']),
    });

    this.client.invalidateQueries({
      queryKey: this.keys.reservationDigitalAsset(
        body['reservation_id'],
        body['id']
      ),
    });
    this.client.invalidateQueries({
      queryKey: this.keys.reservationDigitalAssetByDigitalAssetId(
        body['reservation_id'],
        body['digital_asset_id']
      ),
    });
  }

  // TODO: use body again
  reservationfile() {
    this.client.invalidateQueries({ queryKey: this.keys.reservationFiles() });
  }

  reservationlineitem(body) {
    const accountId = body['account_id'];
    const reservationId = body['reservation_id'];
    const reservationDigitalAssetId = body['reservation_digital_asset_id'];
    this.client.invalidateQueries({ queryKey: this.keys.bag(accountId) });
    this.client.invalidateQueries({
      queryKey: this.keys.bagCollection(accountId),
    });
    if (reservationId && reservationDigitalAssetId) {
      this.client.invalidateQueries({
        queryKey: this.keys.reservationDigitalAssetMediaAddOns(
          reservationId,
          reservationDigitalAssetId
        ),
      });
    }
  }

  scorelistingtask(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.competitors(body['account_id']),
    });
  }

  staffpickscollectiondigitalasset(body) {
    this.collectiondigitalasset(body, 'staff_picks_collection');
  }

  uncropcvservicetask(body) {
    this.client.invalidateQueries({
      queryKey: this.keys.mokkerChildGenerations(body['subject_uuid']),
    });
    if (body['delivered_id'] && body['delivered_account_id']) {
      this.client.invalidateQueries({
        queryKey: this.keys.digitalAsset(
          body['delivered_account_id'],
          body['delivered_id']
        ),
      });
    }
  }
}

export class ToastEmitter {
  constructor(toaster) {
    this.toaster = toaster;
    this.validVariations = Object.keys(soonaToastVariations);
  }

  emit(type, body) {
    if (body?.anytime_toast?.message) {
      const variation = body.anytime_toast?.variation;
      if (variation) {
        if (this.validVariations.includes(variation)) {
          this.toaster.addToast(body.anytime_toast.message, {
            variation: variation,
          });
          return;
        } else {
          console.warn(
            `Unknown toast variation: ${variation}; for message:`,
            body
          );
        }
      }
      this.toaster.addToast(body.anytime_toast.message);
    }
  }
}
