<script setup>
import { computed, inject } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { MEDIA_POPOVER_BACK_KEY } from '@/components/user/anytime/gallery/media-editor-routing-keys';

const props = defineProps({
  isWide: {
    type: Boolean,
    default: false,
  },
});

const isWide = computed(() => props.isWide);
const backUrl = inject(MEDIA_POPOVER_BACK_KEY);
</script>

<template>
  <div>
    <div class="media-header">
      <div class="media-header__details">
        <div class="media-header__details-top">
          <h3 v-if="$slots['default']" class="media-header__title"><slot /></h3>
          <slot name="details-top" />
        </div>
        <div
          v-if="$slots['details-bottom']"
          class="media-header__details-bottom"
        >
          <slot name="details-bottom" />
        </div>
      </div>
      <SoonaButton
        class="media-header__close"
        :size="isWide ? 'large' : 'medium'"
        variation="icon-gray-outline"
        element="router-link"
        :to="backUrl"
      >
        <SoonaIcon name="xmark" />
        <span class="u-a11y-only">Close</span>
      </SoonaButton>
      <div class="media-header__actions">
        <p
          v-if="$slots['price'] || $slots['price-addendum']"
          class="u-label--regular media-header__cost"
        >
          <strong v-if="$slots['price']" class="media-header__price">
            <slot name="price" />
          </strong>
          <slot name="price-addendum" />
        </p>
        <slot name="actions" />
      </div>
      <div v-if="$slots['warning']" class="media-header__warning">
        <slot name="warning" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/variables';
@use 'src/variables_fonts';

.media-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: variables.$white-default;
  border-bottom: 0.0625rem solid variables.$gray-30;
  padding: 1rem;

  &__details {
    flex: 1 1 calc(100% - 3.25rem);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
  }

  &__details-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    padding-right: 3rem;
  }

  &__title {
    @include variables_fonts.u-subheader--heavy;

    word-break: break-word;
    display: flex;
    align-items: center;
    min-height: 2rem; // prevents layout jank when quickly navigating images
  }

  &__details-bottom {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  :deep(.media-header__close) {
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__actions {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__cost {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  &__price {
    @include variables_fonts.u-title--heavy;

    color: variables.$black-default;
  }

  &__warning {
    flex-basis: 100%;
  }

  @media (min-width: variables.$screen-md-min) {
    gap: 1.25rem;
    position: relative;
    padding: 1.5rem 6.75rem 1.5rem 2rem;

    &__details {
      flex: 1 1 auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      align-self: center;
    }

    &__details-top {
      padding-right: 0;
    }

    &__title {
      @include variables_fonts.u-headline--heavy;
    }

    &__details-bottom {
      flex: 0 1 auto;
      gap: 0.75rem;
    }

    :deep(.media-header__close) {
      top: 1.5rem;
      right: 2rem;
      height: auto;
    }

    &__actions {
      flex: 0 1 auto;
      flex-wrap: nowrap;
      justify-content: flex-end;
      gap: 1rem;
    }

    &__cost {
      flex: 0 1 auto;
    }

    &__price {
      @include variables_fonts.u-headline--heavy;

      &:not(:last-child) {
        margin-right: 0.25rem;
      }
    }
  }
}
</style>
