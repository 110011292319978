<script setup>
import { computed, ref, provide, watch } from 'vue';
import { VALIDATE_INCOMPLETE_STEPS } from 'src/components/crew/booking/useIncompleteSteps.js';
import { onBeforeRouteUpdate, useRouter } from 'vue-router';
import { crewBookingRouteGuard } from '@/lib/booking/validateStep';
import { useReservation } from '@/composables/useReservation';
import {
  createStepSections,
  stepComponentLookup,
} from './crew-booking-step-utils';
import BookingSidecart from 'src/components/booking/v3/p2/BookingSidecart.vue';
import TitleBar from 'src/components/shared/TitleBar.vue';
import { onKeyStroke, useTimeoutFn, useMediaQuery } from '@vueuse/core';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import ConfirmBooking from './ConfirmBooking.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  reservationId: {
    type: String,
    required: true,
  },
});

const { error, isLoading, reservation, isConcierge, isFree } = useReservation(
  computed(() => props.reservationId)
);

const validateIncompleteSteps = ref(false);

provide(VALIDATE_INCOMPLETE_STEPS, validateIncompleteSteps);

watch(reservation, (newVal, oldVal) => {
  if (newVal.id !== oldVal.id) {
    validateIncompleteSteps.value = false;
  }
});

const stepSections = computed(() => {
  return createStepSections(reservation.value?.steps);
});

/* title */
const router = useRouter();

const reservationType = computed(() => {
  // use a generic title while the reservation is loading
  if (!reservation.value) {
    return 'booking';
  }

  if (reservation.value.previous) {
    return 'continuation shoot';
  }
  if (reservation.value.reservation_type === 'internal') {
    return 'internal booking';
  }
  // unlikely to be loaded here, but just in case as it's not suggested if h4a
  if (reservation.value.reservation_type === 'headshots_for_all') {
    return 'headshots for all shoot';
  }
  if (isConcierge.value === true) {
    return 'concierge booking';
  }

  return 'customer booking';
});

const previousPage = () => {
  router.back();
};

const title = computed(() => `new ${reservationType.value}`);

/* sidecart */
const sidecartIsAffixed = useMediaQuery('(max-width: 59.9375rem)');
const sidecartOpen = ref(false);
const sidecartClosing = ref(false);
const { start: closeSidecart, stop: stopCloseSidecart } = useTimeoutFn(
  () => {
    sidecartOpen.value = !sidecartOpen.value;
    sidecartClosing.value = false;
  },
  300,
  { immediate: false }
);

const toggleSidecart = () => {
  if (!sidecartOpen.value || sidecartClosing.value) {
    sidecartClosing.value = false;
    stopCloseSidecart();
    sidecartOpen.value = !sidecartOpen.value;
    return;
  }

  sidecartClosing.value = true;
  closeSidecart();
};

onKeyStroke('Escape', () => {
  if (sidecartOpen.value && !sidecartClosing.value) {
    toggleSidecart();
  }
});

onBeforeRouteUpdate(to => {
  return crewBookingRouteGuard(to);
});
</script>

<template>
  <div class="crew-booking">
    <section
      class="crew-booking__content"
      aria-labelledby="crew-booking-edit-heading"
    >
      <TitleBar
        :reservation-id="reservationId"
        heading-id="crew-booking-edit-heading"
        :title="title"
        :previous-page="previousPage"
      />
      <div class="crew-booking__content__body">
        <SoonaAlert
          v-if="reservationType === 'internal booking'"
          data-cypress="internal-warning-banner"
        >
          do not use internal bookings for customers. for pro services tests or
          crew use only.
        </SoonaAlert>
        <SoonaError v-if="error">
          there was a problem loading the reservation: {{ error.message }}
        </SoonaError>
        <SoonaLoading v-if="isLoading" is-loading loading-text="loading" />

        <section
          v-for="stepSection in stepSections"
          :key="stepSection.id"
          class="crew-booking__content__step-section"
          :aria-labelledby="stepSection.id"
        >
          <h3
            :id="stepSection.id"
            class="crew-booking__content__step-section__heading"
          >
            {{ stepSection.title }}
          </h3>
          <div
            v-for="step in stepSection.steps"
            :key="step.slug"
            class="crew-booking__content__step-section__step"
          >
            <component
              :is="stepComponentLookup(step.slug)"
              :reservation-id="props.reservationId"
              :slug="step.slug"
            />
          </div>
        </section>
        <ConfirmBooking :reservation-id="props.reservationId" />
      </div>
    </section>

    <button
      v-if="reservation"
      type="button"
      class="u-button-reset crew-booking__sidecart-toggle"
      aria-controls="crew-booking-sidecart"
      :aria-expanded="sidecartOpen"
      @click="toggleSidecart"
    >
      <span class="u-a11y-only">toggle </span>Shoot Summary
      <SoonaIcon name="camera" />
    </button>

    <BookingSidecart
      v-if="reservation"
      id="crew-booking-sidecart"
      :show-crew-estimated-text="!isFree"
      :affixed="sidecartIsAffixed"
      :reservation-id="reservationId"
      :show-shipping-location="true"
      class="crew-booking__sidecart"
      :class="{
        'crew-booking__sidecart--open': sidecartOpen,
        'crew-booking__sidecart--closing': sidecartClosing,
      }"
      :toggle-sidecart="toggleSidecart"
      @click.self="toggleSidecart"
    />
    <div
      v-else
      id="crew-booking-sidecart"
      class="crew-booking__sidecart"
      :class="{
        'crew-booking__sidecart--open': sidecartOpen,
        'crew-booking__sidecart--closing': sidecartClosing,
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

@keyframes fade-in-sidecart-bg {
  0% {
    background-color: rgba(63, 67, 75, 0);
  }
  to {
    background-color: rgba(63, 67, 75, 0.75);
  }
}

@keyframes fade-out-sidecart-bg {
  to {
    background-color: rgba(63, 67, 75, 0);
  }
}

.crew-booking {
  flex: 1 0 auto;

  hr {
    background-color: variables.$gray-30;
    height: 0.0625rem;
  }

  &__content {
    &__body {
      margin-top: 1rem;
      padding: 0 1rem;

      > * {
        max-width: 34.875rem;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__step-section {
      border-bottom: 0.0625rem solid variables.$gray-30;

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      &__heading {
        @include variables_fonts.u-title--heavy;
        color: variables.$black-default;
        margin-bottom: 1.5rem;
      }

      &__step {
        margin-bottom: 1.5rem;
      }
    }
  }

  &__sidecart-toggle {
    @include variables_fonts.u-small--heavy;

    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-right: 0;
    text-transform: uppercase;
    padding: 0.5rem 1rem;
    letter-spacing: 0.09375rem;
    border-radius: 0.625rem 0 0 0;
    background-color: variables.$white-default;
    transition: background-color 0.1s ease-out;

    > svg {
      display: block;
      margin-left: 0.5rem;
    }

    &:hover,
    &:focus-visible {
      background-color: variables.$gray-20;
    }
  }

  &__sidecart {
    position: fixed;
    z-index: 4;
    top: var(--app-header-viewport-offset);
    bottom: 0;
    right: 0;
    background-color: rgba(63, 67, 75, 0.75);
    animation: 0.2s ease-out both fade-in-sidecart-bg;

    &--open {
      display: flex;
      overflow: hidden;
    }

    &--closing {
      animation: 0.3s ease-out both fade-out-sidecart-bg;
    }
  }
}

@media (min-width: 60rem) {
  .crew-booking {
    display: grid;
    grid-template-areas: 'content sidecart';
    grid-template-columns: 1fr 22.125rem;
    grid-template-rows: 1fr;

    &__content {
      grid-area: content;
    }

    &__sidecart-toggle {
      display: none;
    }

    &__sidecart {
      grid-area: sidecart;
      /* reset to a more standard layout for use in the grid */
      position: static;
      display: block;
      background-color: rgba(63, 67, 75, 0);
      animation: none;
      border-left: 0.0625rem solid variables.$gray-30;
    }
  }
}

@media (min-width: 75rem) {
  .crew-booking {
    grid-template-columns: 1fr 27.625rem;
  }
}
</style>
