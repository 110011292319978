import { useMutation } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { unref } from 'vue';

export function useCreateAdditionalChargeOrder(reservationId) {
  const createAdditionalChargeOrder = async body => {
    const params = {
      products: body?.products,
      charge_immediately: body?.chargeImmediately,
      reservation_id: unref(reservationId),
      account_id: body.accountId,
      payment_method_id: body.paymentMethodId,
      payment_method_type: body.paymentMethodType,
      save_payment_method: body.savePaymentMethod,
      sales_tax: body.salesTax,
    };

    const response = await http.post('/orders.json', params);

    return response.data;
  };

  return useMutation({
    mutationFn: body => {
      return createAdditionalChargeOrder(body);
    },
  });
}
