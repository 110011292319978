<template>
  <div class="modal-background">
    <div class="modal-content">
      <div class="card-wrapper">
        <div class="custom-tag-card">
          <h3 class="custom-tag-title is-lowercase">
            <b>create new subject</b>
          </h3>
          <SoonaTextfield
            v-model="customTag.title"
            label="subject name"
            :maxlength="48"
            placeholder="type something here"
            @keyup.enter="handleSubmit(customTag)"
            @blur="() => handleInputChange('subject name', 'text', 'onBlur')"
          >
            <template #subtext>
              describe who or what will be the main element in this scene!
            </template>
          </SoonaTextfield>
          <p class="sub-label u-label--heavy">
            subject type
            <span class="u-label--regular">(optional)</span>
          </p>
          <p class="sub-description u-label--regular">
            select one of our common subject types
          </p>
          <ul class="subject-type-list">
            <li v-for="star in stars" :key="star.title">
              <button @click="toggleSelectSubject(star)">
                <span
                  class="star-icon"
                  :class="{ selected: customTag.chosenStar.id === star.id }"
                >
                  {{ star.icon }}
                </span>
                <span class="star-title-text">{{
                  star.title.toLowerCase()
                }}</span>
              </button>
            </li>
          </ul>
          <div class="modal-buttons-wrapper">
            <SoonaButton
              :disabled="!customTag.title"
              @click="handleSubmit(customTag)"
            >
              save
            </SoonaButton>
            <SoonaButton variation="tertiary" @click="handleCancel">
              cancel
            </SoonaButton>
          </div>
        </div>
        <button class="preview-close-btn is-large" @click="handleCancel">
          <SoonaIcon name="xmark" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute } from 'vue-router';
import { useBaseEvents } from 'src/composables/segment/useBaseEvents';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from 'src/components/ui_library/SoonaTextfield.vue';

export default {
  name: 'ShotlistCustomTagModal',
  components: {
    SoonaButton,
    SoonaIcon,
    SoonaTextfield,
  },
  props: {
    tagCategory: Object,
    stars: Array,
    closePreviewModal: Function,
    submitCustomTag: Function,
  },
  setup() {
    const route = useRoute();
    const { buttonClicked, inputChanged, itemSelected } = useBaseEvents();
    const subContext = 'shot builder modal';

    const handleButtonClick = (buttonLabel, buttonAction) => {
      buttonClicked({
        context: route.meta.context,
        subContext: subContext,
        buttonLabel,
        buttonAction,
      });
    };

    const handleInputChange = (inputLabel, inputType, inputValue) => {
      inputChanged({
        context: route.meta.context,
        subContext: subContext,
        inputLabel,
        inputType,
        inputValue,
      });
    };

    const handleItemSelection = (itemId, itemLabel) => {
      itemSelected({
        context: route.meta.context,
        subContext: subContext,
        itemId,
        itemLabel,
        itemQuantity: 1,
      });
    };

    return {
      handleButtonClick,
      handleInputChange,
      handleItemSelection,
    };
  },
  data() {
    return {
      customTag: {
        title: '',
        chosenStar: {},
      },
      customTagReferenceImage: {},
    };
  },
  methods: {
    toggleSelectSubject(star) {
      this.customTag.chosenStar = star;
      this.handleItemSelection(star.id, star.title);
    },
    handleSubmit(customTag) {
      this.submitCustomTag(customTag);
      this.handleButtonClick('save', 'saves & closes shot builder modal');
      this.closePreviewModal();
      this.customTag.title = '';
      this.customTag.chosenStar = {};
    },
    handleCancel() {
      this.handleButtonClick('cancel', 'closes shot builder modal');
      this.closePreviewModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.modal-content {
  display: flex;
  align-items: center !important;
  justify-content: center;
  position: relative;
  height: 100%;
  margin: 0 auto !important;
  padding: 0 !important;
}

.card-wrapper {
  position: relative;
  letter-spacing: 0 !important;
}
.custom-tag-card {
  background: variables.$white-default;
  max-width: 740px;
  max-height: 70vh;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  position: relative;
  flex-wrap: wrap;
  overflow: scroll;
  padding: 60px;
}
.custom-tag-title {
  text-align: center;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 15px;
}
.custom-tag-subtitle {
  font-size: 14px;
  font-weight: 900;
  margin-bottom: 10px;
  letter-spacing: 1px;
}
.sub-label {
  padding-bottom: 0.4375rem;
}
.sub-description {
  margin-bottom: 0.5rem;
}
.subject-type-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  margin-bottom: 20px;
  li {
    width: 90px;
    margin-bottom: 10px;
    button {
      background: none;
    }
    .star-icon {
      background: variables.$tangerine-20;
      border-radius: 50%;
      height: 75px;
      width: 75px;
      font-size: 32px;
      margin: 0 auto;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.selected {
        border: 2px solid variables.$black-default;
      }
    }
    .star-title-text {
      font-weight: 800;
      text-transform: lowercase;
      width: 100%;
      text-align: center;
    }
  }
}
.custom-tag-input {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161) !important;
  border: none;
  width: 98%;
  padding: 15px;
  border-radius: 7px;
}
.preview-close-btn {
  background: transparent !important;
  position: absolute;
  top: 0;
  right: 0;
  margin: 1em;
}
.modal-buttons-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}
</style>
