<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  backgroundColor1: {
    type: String,
    default: '',
  },
  backgroundColor2: {
    type: String,
    default: '',
  },
  imageSide: {
    type: String,
    default: 'left',
    validator: function (imageSide) {
      return ['left', 'right'].includes(imageSide);
    },
  },
  imagePath: {
    type: String,
    default: '',
  },
  hasImageShadow: {
    type: Boolean,
    default: false,
  },
  noPadding: {
    type: Boolean,
    default: false,
  },
});

const color1 = computed(() => {
  return props.backgroundColor1 || props.backgroundColor2;
});

const color2 = computed(() => {
  return props.backgroundColor2 || props.backgroundColor1;
});

const flexDirection = computed(() => {
  return props.imageSide === 'left' ? 'row' : 'row-reverse';
});

const padding1 = computed(() => {
  return props.noPadding ? '0' : '1.25rem';
});

const padding2 = computed(() => {
  return props.noPadding ? '0' : '1.25rem 4.875rem';
});
</script>

<template>
  <div class="image-instructions">
    <div class="image-instructions__image-container">
      <img
        class="image-instructions__image"
        :class="{
          'image-instructions__image--shadow': hasImageShadow,
        }"
        :src="imagePath"
        alt="AI studio landing page image"
      />
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.image-instructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  &__image-container {
    --color-1: v-bind('color1');
    --color-2: v-bind('color2');
    display: flex;
    justify-content: center;
    align-items: center;
    padding: v-bind('padding1');
    border-radius: 0.625rem;
    background: var(
      --grad,
      linear-gradient(110deg, var(--color-1) 20.95%, var(--color-2) 77.85%)
    );
  }

  &__image {
    border-radius: 1rem;

    &--shadow {
      box-shadow: 1rem 0.5rem 0.75rem rgba(0, 0, 0, 0.2);
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .image-instructions {
    --flex-direction: v-bind('flexDirection');
    flex-direction: var(--flex-direction);
    gap: 1.5rem;

    &__image-container {
      min-width: 50%;
      max-width: 50%;
    }
  }
}

@media (min-width: variables.$screen-lg-min) {
  .image-instructions__image-container {
    padding: v-bind('padding2');
  }
}
</style>
