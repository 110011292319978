<script setup>
import { computed, ref, onMounted } from 'vue';
import ExampleListingCTA from './example/ExampleListingCTA.vue';
import ListingOptimizeAccordionSection from '@/components/user/anytime/listing_insights/listing_detail/accordions/ListingOptimizeAccordionSection.vue';
import SoonaFeedback from '@/components/ui_library/SoonaFeedback.vue';
import RecommendedPacks from './RecommendedPacks.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import CompetitorPickerDialog from '@/components/user/anytime/competitive_analysis/pickers/CompetitorPickerDialog.vue';
import PickerCompetitorOption from '@/components/user/anytime/competitive_analysis/pickers/PickerCompetitorOption.vue';
import ProductPickerGroupTitle from '@/components/user/anytime/products/picker/ProductPickerGroupTitle.vue';
import { useRoute } from 'vue-router';
import useCompetitorsPopoverBackRoute from '@/components/user/anytime/competitive_analysis/useCompetitorsPopoverBackRoute';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useSubscription } from '@/composables/subscription/useSubscription';
import SubscriptionsDialog from '@/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { useSuggestedSubscription } from '@/composables/subscription/useSuggestedSubscription';
import useSelectCompetitor from '@/components/user/anytime/competitive_analysis/pickers/useSelectCompetitor';

const props = defineProps({
  listingData: {
    type: Object,
    required: true,
  },
  platform: { type: String, required: true },
  isExample: { type: Boolean, default: false },
});

const emit = defineEmits(['refreshListing']);

const { buttonClicked } = useBaseEvents();

const listingData = computed(() => props.listingData);
const listingId = computed(() => listingData.value.listing_id);
const accountId = computed(() => listingData.value.account_id);
const integrationId = computed(() => listingData.value.integration_id);

// subscription dialog module
const showSubscriptionsDialog = ref(false);
const { suggestedListingsTier } = useSuggestedSubscription(accountId);
const selectedTierSlug = ref(null);

const promptSubscriptionDialog = tier => {
  selectedTierSlug.value = tier ?? suggestedListingsTier.value.slug;
  showSubscriptionsDialog.value = true;
};

const { hasLiveSubscription } = useSubscription(accountId);

// competitive analysis module
const { select, pickerIsOpen } = useSelectCompetitor(
  listingId,
  integrationId,
  accountId
);

const route = useRoute();
const { setBackRoute } = useCompetitorsPopoverBackRoute();

onMounted(() => {
  setBackRoute(route);
});

const handleCompareClicked = () => {
  buttonClicked({
    context: 'listing insights',
    subContext: 'listing detail',
    buttonLabel: 'compare with competitors',
    buttonAction: 'handleCompareClicked',
  });

  if (!hasLiveSubscription.value) {
    promptSubscriptionDialog();
    return;
  }

  pickerIsOpen.value = true;
};

const refreshListing = () => {
  emit('refreshListing');
};

const displayCompareButton = computed(() => {
  return props.platform === 'Amazon' && !props.isExample;
});
</script>

<template>
  <ExampleListingCTA v-if="isExample" />
  <div class="listing-optimize">
    <div class="listing-optimize__header">
      <h2 class="u-headline--heavy">optimize</h2>
      <SoonaButton
        v-if="displayCompareButton"
        :variation="hasLiveSubscription ? 'secondary-black' : 'pizzazz'"
        size="medium"
        @click="handleCompareClicked"
      >
        <SoonaIcon name="binoculars" /> compare with competitors
      </SoonaButton>
    </div>
    <ListingOptimizeAccordionSection
      :account-id="accountId"
      :platform="platform"
      :listing-actions="listingData.listing_actions"
      :listing-data="listingData"
      @refresh-listing="refreshListing"
      @show-subscriptions-dialog="promptSubscriptionDialog"
    />
    <div v-if="!isExample" class="listing-optimize__recommended-packs">
      <RecommendedPacks :suggest-amazon="platform === 'Amazon'" />
    </div>
    <SoonaFeedback class="listing-optimize__feedback" flag-name="beta">
      <template #body>
        <h3 class="u-body--heavy">help us improve this new feature!</h3>
        <a
          class="listing-optimize__feedback--link"
          href="https://forms.gle/BMV67Bf7S2J2QWuJ8"
          target="_blank"
        >
          share feedback
        </a>
      </template>
    </SoonaFeedback>
  </div>
  <CompetitorPickerDialog
    v-if="pickerIsOpen"
    :account-id="accountId"
    :integration-id="integrationId"
    @cancel="pickerIsOpen = false"
  >
    <template #heading>choose competitor</template>
    <template #competitor-options="{ competitors }">
      <ProductPickerGroupTitle>watchlist</ProductPickerGroupTitle>
      <PickerCompetitorOption
        v-for="competitor in competitors"
        :key="competitor.id"
        :competitor="competitor"
        @select="select"
      />
    </template>
  </CompetitorPickerDialog>
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    :selected-tier-slug="selectedTierSlug"
    @close="showSubscriptionsDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-optimize {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__feedback {
    &--link {
      text-decoration: underline;
    }
  }
}
</style>
