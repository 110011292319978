<script setup>
import { computed, ref, toRefs } from 'vue';
import ListingActionTemplate from './ListingActionTemplate.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import ListingActionUploadDialog from './ListingActionUploadDialog.vue';
import { useCreateListingActionDigitalAsset } from '@/queries/useListingActionDigitalAssets';
import { useDeleteListingActionDigitalAssets } from '@/queries/useListingActions';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import { usePriorityError } from '@/composables/usePriorityError';
import { useUpdateListingAction } from '@/queries/useListingActions';
import { useCreatePublishTask } from '@/queries/useDigitalAssetPublishTasks';
import ComparisonViewer from './ComparisonViewer.vue';
import useSubscriptionActionPermissions from '@/components/user/anytime/listing_insights/listing_actions/useSubscriptionActionPermissions';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useTargetSize } from '@/components/user/anytime/listing_insights/listing_actions/actions/useTargetSize';
import { usePlatformName } from '@/components/user/anytime/listing_insights/listing_actions/actions/usePlatformName';
import { useDisablePublishFlow } from '@/composables/useDisablePublishFlow';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  listingAction: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['showSubscriptionsDialog']);

const { accountId, listingAction } = toRefs(props);
const listingActionId = computed(() => listingAction.value.id);

const { disablePublishFlow } = useDisablePublishFlow({
  accountId: accountId,
});

const { buttonClicked } = useBaseEvents();

const hasReplacementAsset = computed(() => {
  return listingAction.value.replacement_asset_preview_url;
});

const { platform } = usePlatformName(listingAction);
const { targetSize } = useTargetSize(listingAction);

// loading state
const optimizeTriggered = ref(false);
const isOptimizing = computed(() => {
  if (listingAction.value.most_recent_bulk_action) {
    return listingAction.value.most_recent_bulk_action.processing;
  } else {
    return optimizeTriggered.value;
  }
});

const publishTriggered = ref(false);
const isPublishing = computed(() => {
  if (listingAction.value.most_recent_publish_task) {
    return listingAction.value.most_recent_publish_task.processing;
  } else {
    return publishTriggered.value;
  }
});

// optimize module
const {
  canTakeAction,
  availableCreditsError,
  isBasicUserAndOutOfCredits,
  userCanUpgrade,
  optimizeButtonVariation,
} = useSubscriptionActionPermissions(accountId);

// upload image module
const isProcessing = ref(false);
const showUploadDialog = ref(false);

const handleUploadClick = () => {
  if (canTakeAction.value) {
    showUploadDialog.value = true;
    buttonClicked({
      context: 'listing insights',
      subContext: 'replace poor quality images',
      buttonLabel: 'upload new',
      buttonAction: 'upload new',
    });
  } else {
    emit('showSubscriptionsDialog');
    buttonClicked({
      context: 'listing insights',
      subContext: 'replace poor quality images',
      buttonLabel: 'upload new',
      buttonAction: 'show subscriptions dialog',
    });
  }
};
const {
  mutate: createListingActionDigitalAsset,
  error: createListingActionDigitalAssetError,
} = useCreateListingActionDigitalAsset(accountId);

const { mutate: updateListingAction } = useUpdateListingAction(
  accountId,
  listingActionId
);
const { mutate: createPublishTask } = useCreatePublishTask(accountId);

const linkUploadedAsset = digitalAssetId => {
  createListingActionDigitalAsset(
    {
      digital_asset_id: digitalAssetId,
      listing_action_id: listingAction.value.id,
    },
    {
      onSuccess: () => {
        isProcessing.value = false;
      },
      onError: () => {
        isProcessing.value = false;
      },
    }
  );
};

const { mutate: createDigitalAsset, error: createDigitalAssetError } =
  useCreateDigitalAsset(accountId);

const handleUploadComplete = blob => {
  isProcessing.value = true;
  showUploadDialog.value = false;

  const digitalAsset = {
    asset_type: 'original',
    file: blob.signed_id,
    title: blob.filename,
    media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
    ownership: 'customer',
    origin: 'customer',
    origin_source: 'manual_upload',
    visibility: 'all',
  };

  createDigitalAsset(digitalAsset, {
    onSuccess: data => {
      linkUploadedAsset(data.id);
    },
  });
};

// undo changes module
const {
  mutate: deleteListingDigitalAssets,
  error: deleteListingDigitalAssetsError,
} = useDeleteListingActionDigitalAssets(accountId);

const undoChanges = () => {
  deleteListingDigitalAssets(listingAction.value.id);
  buttonClicked({
    context: 'listing insights',
    subContext: 'replace poor quality images',
    buttonLabel: 'undo changes',
    buttonAction: 'undo changes',
  });
};

// publish module
const handlePublish = () => {
  publishTriggered.value = true;

  buttonClicked({
    context: 'listing insights',
    subContext: 'replace poor quality images',
    buttonLabel:
      listingAction.value === 'failed_to_publish'
        ? 'retry'
        : `publish to ${platform.value}`,
    buttonAction: 'publish',
  });

  createPublishTask(
    {
      publish_assets: [
        {
          digital_asset_id: listingAction.value.replacement_asset.id,
          external_product_id: listingAction.value.external_product_id,
          image_position: listingAction.value.original_asset_image_position,
        },
      ],
    },
    {
      onSuccess: () => {
        publishTriggered.value = false;
      },
    }
  );
};

// comparison viewer module
const showComparison = ref(false);

const handleUpgradeClick = () => {
  if (userCanUpgrade.value) {
    emit('showSubscriptionsDialog', 'tier-two');
    buttonClicked({
      context: 'listing insights',
      subContext: 'replace poor quality images',
      buttonLabel: 'upgrade for more',
      buttonAction: 'upgrade to standard',
    });
  } else {
    window.location.href =
      "mailto:hey@soona.co?subject=Upgrade Subscription&body=Hi, I'd like to upgrade my subscription!";
    buttonClicked({
      context: 'listing insights',
      subContext: 'replace poor quality images',
      buttonLabel: 'upgrade for more',
      buttonAction: 'send email to customer service',
    });
  }
};

// error handling
const priorityError = usePriorityError(
  createListingActionDigitalAssetError,
  createDigitalAssetError,
  deleteListingDigitalAssetsError,
  availableCreditsError
);
const resetListingAction = () => {
  updateListingAction({
    status: 'pending_approval',
  });
  buttonClicked({
    context: 'listing insights',
    subContext: 'replace poor quality images',
    buttonLabel: 'cancel',
    buttonAction: 'reset listing action',
  });
};

const iconLookup = {
  'low-resolution-replace': 'resolution',
  'poor-quality': 'circle-slash',
  'poor-composite': 'bandage',
};
</script>

<template>
  <ListingActionTemplate
    :is-optimizing="isOptimizing"
    :is-publishing="isPublishing"
    :is-processing="isProcessing"
    :listing-action="listingAction"
    @open-comparison-viewer="showComparison = true"
  >
    <template #error>
      <SoonaError v-if="priorityError" no-margin>
        {{ priorityError }}
      </SoonaError>
    </template>
    <template #listing-rule>
      <div class="listing-action-rule">
        <SoonaIcon
          :name="
            hasReplacementAsset
              ? 'image-square'
              : iconLookup[listingAction.listing_rule_slug]
          "
        />
        <div class="listing-action-rule__text">
          <div v-if="hasReplacementAsset">
            <div class="listing-action-rule__text--replacement u-body--heavy">
              uploaded image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
            <div
              v-if="listingAction.original_asset"
              class="listing-action-rule__text--original"
            >
              original image
              <SoonaButton
                variation="icon-plain-gray"
                size="small"
                @click="showComparison = true"
              >
                <SoonaIcon name="arrow-up-right-from-square" />
              </SoonaButton>
            </div>
          </div>
          <div v-else>
            <template
              v-if="
                listingAction.listing_rule_title === 'low resolution replace'
              "
            >
              <dl>
                <dt>low resolution</dt>
                <dd>{{ listingAction.original_asset.resolution }}</dd>
              </dl>
              <dl>
                <dt>target size</dt>
                <dd>{{ targetSize }}</dd>
              </dl>
            </template>
            <template v-else>
              <div>
                <p>{{ listingAction.listing_rule_title }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <div class="listing-action-active__buttons">
        <template v-if="listingAction.status === 'active'">
          <SoonaButton
            v-if="isBasicUserAndOutOfCredits"
            variation="pizzazz"
            @click="handleUpgradeClick"
          >
            upgrade for more
          </SoonaButton>
          <template v-else>
            <SoonaButton
              v-if="!disablePublishFlow"
              :variation="optimizeButtonVariation"
              size="medium"
              @click="handleUploadClick"
            >
              upload new
            </SoonaButton>
          </template>
        </template>
        <template v-else-if="listingAction.status === 'failed_to_publish'">
          <SoonaButton variation="primary" size="medium" @click="handlePublish">
            retry
          </SoonaButton>
          <SoonaButton
            variation="tertiary"
            size="medium"
            @click="resetListingAction"
          >
            cancel
          </SoonaButton>
        </template>
        <template v-else>
          <SoonaButton
            variation="solid-black"
            size="medium"
            @click="handlePublish"
          >
            publish to {{ platform }}
          </SoonaButton>
          <SoonaButton variation="tertiary" size="medium" @click="undoChanges">
            undo changes
          </SoonaButton>
        </template>
      </div>
    </template>
  </ListingActionTemplate>
  <ListingActionUploadDialog
    v-if="showUploadDialog"
    :account-id="accountId"
    :platform="platform"
    @upload-complete="handleUploadComplete"
    @close="showUploadDialog = false"
  />
  <ComparisonViewer
    v-if="showComparison"
    :listing-action-slug="listingAction.listing_action_slug"
    :original-asset="listingAction.original_asset"
    :replacement-asset="listingAction.replacement_asset"
    @close="showComparison = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-action-rule {
  display: flex;
  gap: 0.5rem;

  &__text {
    div {
      dl {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        dt {
          font-weight: bold;
        }
      }

      p {
        font-weight: bold;
      }
    }
  }
}

.listing-action-active {
  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-end;
  }
}
</style>
