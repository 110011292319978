<script setup>
import { computed, ref } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useDeleteReservationLineItem } from 'src/queries/reservationLineItem/useDeleteReservationLineItem';
import { useFlag } from '@/composables/useFlag';
import { useSubscription } from '@/composables/subscription/useSubscription';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  addOnList: {
    required: true,
    type: Array,
  },
  isPhoto: {
    type: Boolean,
    default: false,
  },
  readOnly: {
    default: false,
    type: Boolean,
  },
  reservationId: {
    required: true,
    type: Number,
  },
  usingPreferred: {
    required: false,
    type: Boolean,
    default: false,
  },
  hideRight: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const accountId = computed(() => props.accountId);
const addOnList = computed(() => props.addOnList);
const isPhoto = computed(() => props.isPhoto);
const reservationId = computed(() => props.reservationId);
const usingPreferred = computed(() => props.usingPreferred);
const hideRight = computed(() => props.hideRight);
const lineItemId = ref(undefined);

const { mutate: deleteAddOn, isPending: isDeleting } =
  useDeleteReservationLineItem(
    reservationId.value,
    computed(() => lineItemId.value)
  );

const handleRemoveClick = addOn => {
  lineItemId.value = addOn.id;
  deleteAddOn();
};

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');

const { hasTierTwoSubscription, hasTierThreeSubscription } =
  useSubscription(accountId);

const isMembershipDiscountEligible = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return (
      isPhoto.value &&
      (usingPreferred.value ||
        hasTierTwoSubscription.value ||
        hasTierThreeSubscription.value)
    );
  } else {
    return isPhoto.value && usingPreferred.value;
  }
});

const assetDisplayPrice = value => `${toCurrency(value, 'USD', 0)}`;
</script>

<template>
  <ul class="asset-add-ons" aria-label="selected asset premium edits">
    <li v-for="addOn in addOnList" :key="addOn.id" class="asset-add-ons__item">
      {{ addOn.product?.name || addOn.name }}
      <span v-if="!hideRight" class="asset-add-ons__item-right">
        <button
          v-if="!readOnly"
          class="u-button-reset"
          :disabled="isDeleting"
          @click="() => handleRemoveClick(addOn)"
        >
          remove
        </button>
        <span v-if="!isMembershipDiscountEligible" class="asset-add-ons__price">
          {{ assetDisplayPrice(addOn.price || addOn.total) }}
          <span class="u-a11y-only">premium edit price</span>
        </span>
      </span>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.asset-add-ons {
  &__item {
    @include variables_fonts.u-label--regular;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__item-right {
    flex: 0 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-right: 0;
  }

  button {
    @include variables_fonts.u-label--small;

    text-decoration: underline;
    transition: color 0.1s ease-out;

    &:hover,
    &:focus-visible {
      color: variables.$periwink-blue-70;
    }

    &:active {
      color: variables.$periwink-blue-80;
      text-decoration: none;
    }
  }

  &__price {
    @include variables_fonts.u-body--heavy;

    margin-right: 0;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__item {
      @include variables_fonts.u-body--regular;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    button {
      @include variables_fonts.u-label--regular;
    }
  }
}
</style>
