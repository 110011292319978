<script setup>
import { computed, useTemplateRef, watch } from 'vue';
import SoonaComboboxRoot from '@/components/ui_library/soona_combobox/SoonaComboboxRoot.vue';
import SoonaComboboxOption from '@/components/ui_library/soona_combobox/SoonaComboboxOption.vue';
import SoonaComboboxListbox from '@/components/ui_library/soona_combobox/SoonaComboboxListbox.vue';
import SoonaComboboxInput from '@/components/ui_library/soona_combobox/SoonaComboboxInput.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useMediaQuery } from '@vueuse/core';

const searchText = defineModel('searchText', {
  type: [String, Number],
  required: true,
});

const soonaComboboxRootRef = useTemplateRef('soonaComboboxRootRef');
const open = computed({
  get: () => soonaComboboxRootRef.value?.open ?? false,
  set: val => {
    soonaComboboxRootRef.value.open = val;
  },
});

watch(searchText, newVal => {
  if (newVal.length > 0) {
    open.value = true;
  }
});

const listboxRef = useTemplateRef('listboxRef');
const listboxPlacement = computed(() => listboxRef.value?.placement);
const isInverted = computed(
  () => open.value && listboxPlacement.value === 'top-start'
);

const isDesktop = useMediaQuery('(min-width: 48rem)');
const isMobile = computed(() => !isDesktop.value);
const teleportIsDisabled = computed(() => isDesktop.value || !open.value);
const displayBackButton = computed(() => isMobile.value && open.value);

const displayCloseButton = computed(() => {
  if (isDesktop.value) {
    return searchText.value.length > 0 || open.value;
  } else {
    return searchText.value.length > 0;
  }
});

const closeButtonClick = () => {
  searchText.value ? (searchText.value = '') : (open.value = false);
};

const placeholderText = computed(() =>
  open.value ? 'what would you like to see?' : 'search & filter'
);
</script>

<template>
  <Teleport to="body" :disabled="teleportIsDisabled">
    <SoonaComboboxRoot
      ref="soonaComboboxRootRef"
      v-model:search-text="searchText"
      class="soona-search"
      :class="{ 'soona-search--expanded': open }"
    >
      <template #trigger>
        <div
          class="soona-search__input--wrapper"
          :class="{
            'soona-search__input--wrapper--expanded': open,
            'soona-search__input--wrapper--expanded--inverted': isInverted,
          }"
        >
          <SoonaButton
            v-if="displayBackButton"
            class="soona-search__input--icon soona-search__input--icon--back"
            variation="icon-plain-gray"
            @click="open = false"
          >
            <SoonaIcon name="arrow-left" />
            <span class="u-a11y-only">close</span>
          </SoonaButton>
          <SoonaIcon v-else name="search" class="soona-search__input--icon" />
          <SoonaComboboxInput
            class="soona-search__input u-title--regular"
            :class="{
              'soona-search__input--expanded': open,
              'soona-search__input--expanded--inverted': isInverted,
            }"
            :is-mobile="isMobile"
            :placeholder-text="placeholderText"
          />
          <SoonaButton
            v-if="displayCloseButton"
            class="soona-search__input--icon soona-search__input--icon--close"
            variation="icon-plain-gray"
            @click="closeButtonClick"
          >
            <SoonaIcon name="xmark" />
            <span class="u-a11y-only">clear search</span>
          </SoonaButton>
        </div>
      </template>

      <template #popover>
        <SoonaComboboxListbox
          ref="listboxRef"
          class="soona-search__listbox"
          :class="{
            'soona-search__listbox--inverted': isInverted,
            'soona-search__listbox--expanded': open,
          }"
          :disable-floating-ui="isMobile"
        >
          <SoonaComboboxOption>Option without value 1</SoonaComboboxOption>
          <SoonaComboboxOption>Option without value 2</SoonaComboboxOption>
          <SoonaComboboxOption v-for="x in 100" :key="x" :value="x">
            Option {{ x }}
          </SoonaComboboxOption>
        </SoonaComboboxListbox>
      </template>
    </SoonaComboboxRoot>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-search {
  position: relative;
  max-width: 36rem;

  input[type='search']::-webkit-search-decoration {
    display: none;
  }

  input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
  }

  &--expanded {
    position: fixed;
    inset: 0;
    max-width: 100%;
    height: 100%;
  }

  &__input {
    width: 100%;
    padding: 0.875rem 3rem;
    border-radius: 1.75rem;
    border-top: 0.125rem solid variables.$gray-20;
    border-left: 0.125rem solid transparent;
    border-right: 0.125rem solid transparent;
    border-bottom: 0.125rem solid variables.$gray-30;
    background: linear-gradient(
        180deg,
        variables.$gray-20 25%,
        variables.$gray-30 100%
      ),
      linear-gradient(
        180deg,
        variables.$periwink-blue-10 23.21%,
        variables.$periwink-blue-20 100%
      );

    &:hover,
    &:focus-visible {
      border: 0.125rem solid transparent;
      background: variables.$periwink-blue-20;
      color: variables.$periwink-blue-80;
      cursor: pointer;

      &::placeholder {
        color: variables.$periwink-blue-80;
      }
    }

    &:focus {
      outline: none;
      border: 0.125rem solid variables.$periwink-blue-30;
      background: variables.$white-default;
      box-shadow: variables.$elevation-3;
      color: initial;
      cursor: initial;

      &::placeholder {
        color: variables.$gray-50 !important;
      }
    }

    &[aria-expanded='true'] {
      border-radius: 0;
      border: 0.125rem solid transparent;
      background: variables.$white-default;
    }

    &--wrapper {
      position: relative;

      &:hover .soona-search__input--icon {
        color: variables.$periwink-blue-80;
      }

      &:focus-within:hover .soona-search__input--icon {
        color: initial;
      }

      &--expanded {
        &::after {
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 1rem;
          right: 1rem;
          border-bottom: 0.125rem solid variables.$gray-30;
        }

        &--inverted {
          &::after {
            display: none;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 1rem;
            right: 1rem;
            border-top: 0.125rem solid variables.$gray-30;
          }
        }
      }
    }

    &--icon {
      position: absolute;
      top: 50%;
      left: 0.875rem;
      transform: translateY(-50%);

      &--back {
        left: 0.5rem;
      }

      &--close {
        right: 0.5rem;
        left: auto;
      }
    }
  }

  [role='listbox'] {
    z-index: 20;
    padding: 1rem;
    background-color: variables.$white-default;
    border: none;
    width: 100%;
    transition: opacity 0.1s ease-out;
    height: 100%;
    overflow-y: auto;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }

    &.menu-fade-enter-from,
    &.menu-fade-leave-to {
      opacity: 0;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    &--expanded {
      position: relative;
      max-width: 36rem;
    }

    &__input {
      &[aria-expanded='true'] {
        border-radius: 1.75rem 1.75rem 0 0;
      }

      &--expanded {
        &--inverted {
          border-radius: 0 0 1.75rem 1.75rem !important;
          border-top: none;
          border-bottom: 0.125rem solid variables.$periwink-blue-30;
        }
      }

      &--wrapper {
        border-radius: 1.75rem 1.75rem 0 0;
        border: 0.125rem solid transparent;
        border-bottom: none;

        &--expanded {
          border: 0.125rem solid variables.$periwink-blue-30;
          border-bottom: none;
        }

        &--expanded {
          &--inverted {
            border-radius: 0 0 1.75rem 1.75rem;
            border-top: none;
            border-bottom: 0.125rem solid variables.$periwink-blue-30;
          }
        }
      }
    }

    [role='listbox'] {
      // padding: 1rem;
      box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.16);
      border-radius: 0 0 1.75rem 1.75rem;
      border: 0.125rem solid variables.$periwink-blue-30;
      border-top: none;
      height: auto;
    }

    .soona-search__listbox--inverted {
      border-radius: 1.75rem 1.75rem 0 0;
      border: 0.125rem solid variables.$periwink-blue-30;
      border-bottom: none;
      box-shadow: none;

      &::before {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 1rem;
        right: 1rem;
        // border-bottom: 0.125rem solid variables.$periwink-blue-30;
      }
    }
  }
}
</style>
