<script setup>
import { computed, onMounted, ref, watchEffect } from 'vue';
import { useTitle } from '@vueuse/core';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import AccountGalleryBreadcrumbs from '@/components/account_gallery/AccountGalleryBreadcrumbs.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import UploadMediaDialog from '@/components/account_gallery/UploadMediaDialog.vue';
import AccountInfoAuto from '@/components/user/anytime/dashboard/AccountInfoAuto.vue';
import AlbumCollectionDigitalAssetsGallery from '@/components/account_gallery/collections/AlbumCollectionDigitalAssetsGallery.vue';
import { useAlbumCollection } from '@/queries/album_collections/useAlbumCollection';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import { useUpdateAlbumCollection } from '@/queries/album_collections/useUpdateAlbumCollection';
import { useDeleteAlbumCollection } from '@/queries/album_collections/useDeleteAlbumCollection';
import { usePriorityError } from '@/composables/usePriorityError';
import { useRouter, useRoute } from 'vue-router';
import DeleteAlbumConfirmDialog from './DeleteAlbumConfirmDialog.vue';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/queries/account/useAccount';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  collectionId: {
    type: [String, Number],
    required: true,
  },
});

const { linkClicked, pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
});

const accountId = computed(() => +props.accountId);
const collectionId = computed(() => props.collectionId);

const { currentAccountId: authedAccountId } = useMe();
const isMyAccount = computed(() => authedAccountId.value === accountId.value);

const route = useRoute();
const router = useRouter();

const showUploadDialog = ref(false);

const {
  data: collection,
  error: collectionError,
  isLoading: isCollectionLoading,
} = useAlbumCollection({
  accountId,
  collectionId,
});

useTitle(
  computed(
    () =>
      `${collection.value?.title ? collection.value.title + ' ' : ''}album | soona`
  )
);

const trackClick = (label, href = null) => {
  linkClicked({
    context: route.meta.context,
    subContext: 'header',
    linkLabel: label,
    linkHref: href,
  });
};

const handleOpenUploadDialog = () => {
  trackClick('add media');
  showUploadDialog.value = true;
};

const handleCloseUploadDialog = () => {
  trackClick('cancel');
  showUploadDialog.value = false;
};

const editCollectionNameMode = ref(false);
const title = ref('');

function closeEditCollectionNameMode() {
  editCollectionNameMode.value = false;
  title.value = collection.value?.title;
}

const {
  data: account,
  isLoading: isLoadingAccount,
  error: errorAccount,
} = useAccount(accountId);

const {
  mutate: updateAlbum,
  isPending: isUpdatingAlbum,
  error: updateAlbumError,
} = useUpdateAlbumCollection(accountId);

const updateAlbumTitle = event => {
  const data = new FormData(event.target);
  const title = data.get('title')?.trim();

  if (title.length < 1) {
    return;
  }

  updateAlbum(
    {
      collectionId,
      body: {
        title,
      },
    },
    {
      onSuccess: () => {
        closeEditCollectionNameMode();
      },
    }
  );
};

watchEffect(() => {
  title.value = collection.value?.title;
});

const isDirty = computed(() => {
  return title.value?.trim().length && title.value !== collection.value?.title;
});

const {
  mutate: deleteAlbumCollection,
  isPending: isDeletingAlbum,
  error: deleteAlbumError,
} = useDeleteAlbumCollection(accountId);

const priorityErrorHeader = usePriorityError(
  updateAlbumError,
  deleteAlbumError
);

const deleteAlbumConfirm = ref(false);

function deleteAlbum() {
  if (!collectionId.value) return;

  deleteAlbumCollection(
    { collectionId: collectionId.value },
    {
      onSuccess: async () => {
        await router.push(`/account/${accountId.value}/gallery/albums`);
      },
      onSettled: () => {
        deleteAlbumConfirm.value = false;
      },
    }
  );
}

const isBusy = computed(() => isUpdatingAlbum.value || isDeletingAlbum.value);

const isLoading = computed(
  () => isCollectionLoading.value || isLoadingAccount.value
);

const priorityErrors = usePriorityErrors(collectionError, errorAccount);
</script>

<template>
  <div class="album-collection-assets">
    <AccountInfoAuto :account-id="accountId" />
    <SoonaLoading v-if="isLoading" is-loading />
    <SoonaError v-else-if="priorityErrors" :priority-errors="priorityErrors" />
    <template v-else>
      <AccountGalleryBreadcrumbs
        :account-name="account?.name"
        :album-title="collection?.title"
        :is-my-account="isMyAccount"
      />
      <div class="album-collection-assets__header">
        <div
          v-if="!editCollectionNameMode"
          class="album-collection-assets__header__heading-wrap"
        >
          <h1 class="u-title--heavy album-collection-assets__header__heading">
            {{ collection?.title }}
          </h1>
          <SoonaTooltip v-if="collection" placement="top">
            <template
              #default="{
                setRef,
                mouseenter,
                focus,
                mouseleave,
                blur,
                ariaDescribedby,
              }"
            >
              <SoonaButton
                :ref="setRef"
                variation="icon-plain-gray"
                :aria-describedby="ariaDescribedby"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
                @on-click="editCollectionNameMode = true"
              >
                <SoonaIcon name="pen-square" />
                <span class="u-a11y-only">edit album name</span>
              </SoonaButton>
            </template>
            <template #tooltip-content>edit album name</template>
          </SoonaTooltip>
        </div>
        <SoonaForm
          v-else
          class="album-collection-assets__header__form"
          @submit="updateAlbumTitle"
        >
          <SoonaTextfield
            v-model="title"
            name="title"
            label="album title"
            placeholder="edit album name"
            class="album-collection-assets__header__title-field"
          />
          <div class="album-collection-assets__header__buttons">
            <SoonaButton
              variation="primary"
              type="submit"
              :disabled="!isDirty || isBusy"
              :loading="isUpdatingAlbum"
            >
              save
            </SoonaButton>
            <SoonaButton
              variation="secondary-gray"
              :disabled="isBusy"
              @on-click="closeEditCollectionNameMode"
            >
              cancel
            </SoonaButton>
            <SoonaTooltip placement="top">
              <template
                #default="{
                  setRef,
                  mouseenter,
                  focus,
                  mouseleave,
                  blur,
                  ariaDescribedby,
                }"
              >
                <SoonaButton
                  :ref="setRef"
                  variation="icon-gray-outline"
                  :disabled="isBusy"
                  :loading="isDeletingAlbum"
                  :aria-describedby="ariaDescribedby"
                  @on-click="deleteAlbumConfirm = true"
                  @mouseenter="mouseenter"
                  @focus="focus"
                  @mouseleave="mouseleave"
                  @blur="blur"
                >
                  <SoonaIcon name="trash" />
                  <span class="u-visually-hidden">delete album</span>
                </SoonaButton>
              </template>
              <template #tooltip-content>delete album</template>
            </SoonaTooltip>
          </div>
        </SoonaForm>
        <SoonaError v-if="priorityErrorHeader">
          {{ priorityErrorHeader }}
        </SoonaError>
        <SoonaButton variation="solid-black" @on-click="handleOpenUploadDialog">
          <SoonaIcon name="plus-large" /> add media
        </SoonaButton>
      </div>
      <AlbumCollectionDigitalAssetsGallery
        :account-id="accountId"
        :album-collection-id="collectionId"
        @upload-media="handleOpenUploadDialog"
      />
      <UploadMediaDialog
        v-if="showUploadDialog"
        :account-id="accountId"
        :album-id="collectionId"
        @close="handleCloseUploadDialog"
      />
      <DeleteAlbumConfirmDialog
        v-if="deleteAlbumConfirm"
        @close="deleteAlbumConfirm = false"
        @delete="deleteAlbum"
      />
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.album-collection-assets {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: space-between;

    &__heading-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      flex-flow: row wrap;
      gap: 0.5rem;
    }

    &__heading {
      align-self: center;
    }

    &__form {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      align-items: flex-end;
    }

    &__title-field {
      padding-bottom: 0;
    }

    &__buttons {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
</style>
