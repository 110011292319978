import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';

export function useCreateSalesTax(
  { orderTotal, billingAddressId, orderTaxBreakdown },
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.salesTax({
      orderTotal,
      billingAddressId,
      orderTaxBreakdown,
    }),
    queryFn: async ({ signal }) => {
      const response = await http.post(
        `/sales_tax/`,
        {
          sales_taxes: {
            order_total: toValue(orderTotal),
            address_id: toValue(billingAddressId),
            order_tax_breakdown: toValue(orderTaxBreakdown),
          },
        },
        { signal }
      );

      return response.data;
    },
    ...queryOptions,
  });
}
