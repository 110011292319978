<template>
  <div class="CancelFastPass">
    <h1 class="u-headline--heavy">next steps to cancel fast pass</h1>
    <p>
      fast pass won’t be canceled until you take action on stored items. choose
      how you’d like to process these items below.
    </p>
    <p>
      once you cancel your fast pass will remain active until your billing
      period ends on {{ convertToMMDDYYYYlong(endDate) }}
    </p>
    <div
      v-for="(inventoryPackage, index) in fastPassPackages"
      :id="'p' + index"
      :key="'p' + index"
    >
      <InventoryCard
        :inventory-package="inventoryPackage"
        :account-id="accountId"
        page-use="cancelFastPass"
        :update-fast-pass-package="updateStoredPackagePSO"
      />
    </div>
    <div class="button-wrapper">
      <SoonaButton variation="tertiary" @on-click="nevermindCancel">
        nevermind. don’t cancel
      </SoonaButton>
      <SoonaButton
        :disabled="updateStoredPackages.length !== fastPassPackages.length"
        @on-click="openCancelFastPassModal"
      >
        cancel fast pass
      </SoonaButton>
    </div>
    <CancellationDialog
      v-if="showCancelReasonModal"
      :account-id="accountId"
      :subscription-id="subscriptionId"
      :subscription-item-price-id="subscriptionItemPriceId"
      :subscription-item-id="subscriptionItemId"
      subscription-item-type="add-on"
      :subscription-current-period-end="currentSubscriptionEnd"
      @close="confirmCancel()"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import * as types from 'src/store/mutation-types';
import InventoryCard from '../inventory/InventoryCard.vue';
import dateTimeMixin from '../../../../mixins/dateTimeMixin';
import { useAccount } from '@/composables/useAccount';
import { useRoute } from 'vue-router';
import CancellationDialog from '@/components/subscriptions/CancellationDialog.vue';
import { convertToDateLong } from '@/lib/date-formatters';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

import * as Sentry from '@sentry/vue';

export default {
  name: 'CancelFastPass',
  components: {
    InventoryCard,
    CancellationDialog,
    SoonaButton,
  },
  mixins: [dateTimeMixin],
  setup() {
    const route = useRoute();

    const accountId = computed(() => route.params.accountId);
    const { account } = useAccount(accountId);

    const subscriptionId = computed(() => {
      return account.value?.subscription?.id ?? null;
    });

    //get price id and not item id
    const subscriptionItemPriceId = computed(() => {
      // we only have 1 add on, but we'll need to refactor this later if we have more
      return (
        account.value?.subscription.subscription_items.find(
          x => x.subscription_item_type === 'add_on'
        )?.price_id ?? null
      );
    });

    const subscriptionItemId = computed(() => {
      return (
        account.value?.subscription.subscription_items.find(
          x => x.subscription_item_type === 'add_on'
        )?.id ?? null
      );
    });

    const currentSubscriptionEnd = computed(() => {
      if (!account.value?.subscription?.current_period_end) {
        return null;
      }
      return convertToDateLong(account.value?.subscription?.current_period_end);
    });

    return {
      accountId,
      currentSubscriptionEnd,
      subscriptionId,
      subscriptionItemPriceId,
      subscriptionItemId,
    };
  },
  data() {
    return {
      cancellationChoice: {},
      updateStoredPackages: [],
      showCancelReasonModal: false,
      error: null,
    };
  },
  computed: {
    ...mapState({
      accountPackages: state => state.inventoryPackages.loadedPackages,
      account: state => state.account,
    }),
    endDate() {
      return this.account.subscription?.current_period_end;
    },
    disableCancel() {
      return Object.keys(this.cancellationChoice).length === 0;
    },
    fastPassPackages() {
      let packages = this.accountPackages;
      return packages
        .filter(
          item =>
            item.post_shoot_option === 'store_at_soona' ||
            (item.status === 'stored' &&
              (item.post_shoot_option === 'store_at_soona' ||
                item.post_shoot_option === null))
        )
        .map(item => {
          const updatedPackage = this.updateStoredPackages.find(
            p => p.inventoryPackageId === item.id
          );
          if (updatedPackage) {
            const { ...rest } = updatedPackage;
            return { ...item, ...rest };
          } else {
            return item;
          }
        });
    },
  },
  mounted() {
    this.loadPackages({ accountId: this.accountId });
    this.loadAccount(this.accountId);
  },
  methods: {
    ...mapActions('inventoryPackages', ['loadPackages', 'updatePackage']),
    ...mapActions('account', ['loadAccount']),
    ...mapMutations('inventoryPackages', [types.SET_CURRENT_PACKAGE]),
    nevermindCancel() {
      this.$router.go(-1);
    },
    confirmCancel() {
      this.confirmPackageUpdates();
      this.showCancelReasonModal = false;
      this.$router.go(-1);
    },
    async confirmPackageUpdates() {
      try {
        this.updateStoredPackages.forEach(updatedPackage =>
          this.updatePackage({
            packageId: updatedPackage.inventoryPackageId,
            packageAttributes: updatedPackage,
          }).then(([, errors]) => {
            if (errors) {
              this.errors = errors;
            } else {
              this.SET_CURRENT_PACKAGE({});
            }
          })
        );
      } catch (error) {
        if (error.response.status !== 422) {
          Sentry.captureException(
            new Error(
              `Updating package (id: ${this.inventoryPackage.id} failed`
            ),
            {
              extra: { error },
            }
          );
        }
      }
    },
    updateStoredPackagePSO(packageOption) {
      const editUpdatedPackage = this.updateStoredPackages.some(
        p => p.inventoryPackageId === packageOption.inventoryPackageId
      );

      if (editUpdatedPackage) {
        const editPackage = this.updateStoredPackages.find(
          p => p.inventoryPackageId === packageOption.inventoryPackageId
        );
        editPackage.post_shoot_option = packageOption.post_shoot_option;
        editPackage.required_action = packageOption.required_action;
      } else {
        this.updateStoredPackages = [
          ...this.updateStoredPackages,
          packageOption,
        ];
      }
    },
    openCancelFastPassModal() {
      this.showCancelReasonModal = true;
      this.error = null;
    },
    closeCancelFastPassModal() {
      this.$router.go(-1);
      this.showCancelReasonModal = false;
      this.error = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.CancelFastPass {
  max-width: 80%;
  margin: 0 auto;

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    margin-top: 3.125rem;
  }
}

.modal-body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
