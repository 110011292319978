<script setup>
import { computed, provide, ref, watch } from 'vue';
import { useElementBounding } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { useMe } from '@/composables/user/useMe';
import { useInfiniteReservationDigitalAssets } from '@/queries/reservation_digital_assets/useInfiniteReservationDigitalAssets';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useCapability } from '@/composables/useCapability';
import { useReservation } from '@/composables/useReservation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import GalleryFilter from './GalleryFilter.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import ReservationDigitalAssetCard from './ReservationDigitalAssetCard.vue';
import ShareGalleryModal from '@/components/user/anytime/gallery/share/ShareGalleryModal.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import RecommendationTiles from '@/components/user/anytime/reservation/gallery/RecommendationTiles.vue';
import SceneSelector from '@/components/user/anytime/scene_selector/SceneSelector.vue';
import { useSetColorCard } from '@/queries/shots/useSetColorCard';
import CompletedEdits from '@/components/user/anytime/reservation/gallery/edits/CompletedEdits.vue';
import { useEditsCollection } from '@/queries/edits_collection/useEditsCollection';
import StaffActions from '@/components/user/anytime/reservation/gallery/staff_actions/StaffActions.vue';
import ReEdits from '@/components/user/anytime/reservation/re_edits/ReEdits.vue';
import ProTips from '@/components/user/anytime/ProTips.vue';
import EmptyGallery from '@/components/user/anytime/reservation/gallery/EmptyGallery.vue';
import UpgradesSection from '@/components/user/anytime/UpgradesSection.vue';
import DigitalAssetMultiSelectActionBar from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/DigitalAssetMultiSelectActionBar.vue';
import ScrollToTop from './ScrollToTop.vue';
import PaidOrderSummary from '@/components/user/anytime/reservation/PaidOrderSummary.vue';
import { useBagCount } from '@/queries/bag/useBag';
import { useReservationGallerySelectionProvider } from '@/components/user/anytime/reservation/useReservationGallerySelection';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useFlag } from '@/composables/useFlag';
import CanvaButton from '@/components/shared/CanvaButton.vue';
import DownloadAll from '@/components/user/anytime/reservation/gallery/edits/DownloadAll.vue';
import { useReservationDigitalAssets } from '@/queries/reservation_digital_assets/useReservationDigitalAssets';

const props = defineProps({
  isChatOpen: {
    default: false,
    type: Boolean,
  },
  userHasScrolledPastBreakpoint: {
    default: false,
    type: Boolean,
  },
  reservationId: {
    type: [Number, String],
    required: true,
  },
  filter: {
    default: null,
    type: [String, null],
  },
});

const { linkClicked } = useBaseEvents();
const route = useRoute();
const router = useRouter();

const reservationId = computed(() => props.reservationId);

const {
  reservation,
  isInProgress,
  isPendingShotlist,
  isPreShoot,
  error: reservationError,
} = useReservation(reservationId);
const accountId = computed(() => reservation.value?.account_id);

const { currentAccountId: authedAccountId } = useMe();

const isCreatedByAuthedUser = computed(() => {
  return reservation.value?.account_id === authedAccountId.value;
});

// capabilities module
const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const toasterMixEditsUnpurchased = useFlag('toaster_mix_edits_unpurchased');
const canvaButtonEnabled = useFlag('apollo_canva_button');

// filter module
const selectedFilter = computed(() => props.filter ?? 'all');

const { data: facetsData, isSuccess: facetsIsSuccess } =
  useReservationDigitalAssets(reservationId, {
    itemsPerPage: 1,
    facets: 1,
  });

const hasEdits = computed(() => facetsData.value?.editCount > 0);

const setFilter = filter => {
  const updatedRoute = {
    name: route.name,
    params: route.params,
  };

  if (filter) {
    updatedRoute.query = { filter: filter.replace(/\s/g, '_') };
  }

  router.replace(updatedRoute);
};

watch(
  hasEdits,
  () => {
    if (props.filter) return;

    if (hasEdits.value && toasterMixEditsUnpurchased.value) {
      setFilter('edits');
    } else {
      setFilter(null);
    }
  },
  { immediate: true }
);

const filterLookup = {
  all: {},
  edits: { ownership: 'customer', asset_type: 'purchased' },
  expert_picks: { collection_type: 'StaffPicksCollection' },
  favorites: { collection_type: 'FavoritesCollection' },
  hidden: { visibility: 'crew' },
  in_bag: { collection_type: 'BagCollection' },
  raws_no_preview: { independent_raws_only: true, visibility: 'crew' },
};
const galleryFilters = computed(() => ({
  origin: 'soona',
  ownership: 'soona',
  asset_type: 'original',
  visibility: 'all',
  ...filterLookup[selectedFilter.value],
}));

// size module
const pageWrapper = ref();
const galleryWrapper = ref();
const itemsPerPage = ref(30);

const { left, width } = useElementBounding(pageWrapper);

const alwaysShowTitles = ref(false);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: galleryWrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const {
  assetRows,
  fetchPage,
  isLoading,
  error: infiniteAssetsError,
  rawData,
} = useInfiniteDigitalAssets(
  reservationId,
  {
    rowWidth,
    itemsPerPage,
    gutter,
    height: rowHeight,
    query: useInfiniteReservationDigitalAssets,
    filters: galleryFilters,
  },
  { enabled: facetsIsSuccess }
);

// TODO: allow scrolling a particular asset into view when
// coming back from MediaPopover
// const initialScrollPosition = computed(() => {
//   if (!startPage.value || startPage.value <= 1) return 0;
//   return (
//     Math.floor(
//       ((startPage.value - 1) * itemsPerPage.value) / itemsPerGridRow.value
//     ) || 0
//   );
// });
//

// share gallery
const showShareGalleryModal = ref(false);

const openShareModal = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'filter bar',
    linkLabel: 'share gallery',
    linkHref: '',
  });
  showShareGalleryModal.value = true;
};

// bulk select module
const {
  bulkSelectActionBarIsVisible,
  isAssetSelected,
  onSelectionClick,
  resetSelection,
} = useReservationGallerySelectionProvider(rawData, reservationId);

// collections module
const { data: editsCollection } = useEditsCollection(accountId);
const { data: favoriteCollection } = useFavoritesCollection(accountId);
const { data: staffPicksCollection } = useStaffPicksCollection(accountId);
const { data: bagCollection } = useBagCollection(accountId);

const { data: bagCount } = useBagCount(authedAccountId);
const itemsInBag = computed(() => bagCount.value ?? null);

// media editor
function generateRouteLocation(rdaAsset) {
  if (rdaAsset.placeholder) return {};

  const query = {};
  if (selectedFilter.value && selectedFilter.value !== 'all') {
    query.filter = selectedFilter.value;
  }

  return {
    name: 'reservation-asset-view',
    params: {
      reservationId: reservationId.value,
      digitalAssetId: rdaAsset.digital_asset.id,
    },
    query,
  };
}

const colorCardSelectModeIsOn = ref(false);
const selectedSceneId = ref(undefined);
const toggleColorCardSelectMode = sceneId => {
  colorCardSelectModeIsOn.value = !colorCardSelectModeIsOn.value;
  if (colorCardSelectModeIsOn.value) {
    resetSelection();
    selectedSceneId.value = sceneId;
  }
};

const {
  mutate: setColorCard,
  isPending: isPendingColorCard,
  error: setColorCardError,
} = useSetColorCard(reservationId);

// todo: we're keeping it in line with the gray card logic but we might end up moving this to be emits/slots
provide('colorCardSelectMode', {
  colorCardSelectModeIsOn,
  toggleColorCardSelectMode,
  isPendingColorCard,
  setColorCardError,
});

// single selection
const actOnSingleSelection = digital_asset => {
  if (colorCardSelectModeIsOn.value) {
    if (!selectedSceneId.value) return;

    setColorCard(
      {
        shotId: selectedSceneId.value,
        params: { digital_asset_id: digital_asset.id },
      },
      {
        onSuccess: () => {
          toggleColorCardSelectMode();
        },
      }
    );
  }
};

const selectMode = computed(() => {
  return colorCardSelectModeIsOn.value ? 'single' : 'bulk';
});

function handleSelectionClick(rda, event) {
  if (selectMode.value === 'single') {
    actOnSingleSelection(rda.digital_asset);
  } else if (selectMode.value === 'bulk') {
    onSelectionClick(rda, event);
  }
}

function trackGoToCheckoutLinkClick() {
  linkClicked({
    context: route.meta.context,
    linkHref: '/bag',
    linkLabel: 'go to checkout',
    subContext: 'content gallery',
  });
}

const galleryPriorityErrors = usePriorityErrors(
  reservationError,
  infiniteAssetsError
);

watch(selectedFilter, () => {
  resetSelection();
});
</script>

<template>
  <div ref="pageWrapper" class="gallery-view">
    <ShareGalleryModal
      v-if="showShareGalleryModal"
      :account-id="accountId"
      :reservation-id="reservationId"
      @close="showShareGalleryModal = false"
    />
    <StaffActions
      v-if="isSoonaStaff && accountId"
      :account-id="accountId"
      :reservation-id="reservationId"
    />
    <UpgradesSection
      v-if="isCreatedByAuthedUser && (isPreShoot || isPendingShotlist)"
      :account-id="accountId"
      :reservation-id="reservationId"
    />
    <ReEdits :reservation-id="reservationId" />
    <CompletedEdits
      v-if="!toasterMixEditsUnpurchased && editsCollection?.id"
      :account-id="accountId"
      :collection-id="editsCollection?.id"
      :reservation-id="reservationId"
    />
    <PaidOrderSummary :account-id="accountId" :reservation-id="reservationId" />
    <SceneSelector
      v-if="isSoonaStaff && accountId"
      :account-id="accountId"
      :reservation-id="reservationId"
      :reservation-is-in-progress="isInProgress"
    />
    <div class="gallery-wrapper">
      <GalleryFilter
        :model-value="selectedFilter"
        :reservation-id="reservationId"
        :always-show-titles="alwaysShowTitles"
        @update:model-value="$event => setFilter($event)"
      >
        <template
          v-if="toasterMixEditsUnpurchased"
          #gallery-filter-header-actions
        >
          <CanvaButton v-if="canvaButtonEnabled" />
          <Capability
            capability-name="manage_collaborators"
            subject-type="account"
            :subject-id="accountId"
          >
            <SoonaButton
              type="button"
              variation="secondary-gray"
              size="medium"
              @click="openShareModal"
            >
              <SoonaIcon name="users" size="medium" /> share gallery
            </SoonaButton>
          </Capability>
          <DownloadAll
            v-if="hasEdits"
            copy="download"
            :show-web="facetsData?.editWithWebCount > 0"
            :account-id="accountId"
            :reservation-id="reservationId"
            variation="solid-black"
          />
        </template>
        <template #gallery-filter-right>
          <SoonaToggle
            :model-value="alwaysShowTitles"
            label="show titles"
            type="switch"
            @update:model-value="() => (alwaysShowTitles = !alwaysShowTitles)"
          />
          <Capability
            v-if="!toasterMixEditsUnpurchased"
            capability-name="manage_collaborators"
            subject-type="account"
            :subject-id="accountId"
          >
            <SoonaButton
              type="button"
              variation="secondary-gray"
              @click="openShareModal"
            >
              <SoonaIcon name="users" size="medium" /> share gallery
            </SoonaButton>
          </Capability>
        </template>
        <template #gallery-filter-bottom="{ staffPickCount }">
          <RecommendationTiles
            :bag-collection-id="bagCollection?.id"
            :favorites-collection-id="favoriteCollection?.id"
            :reservation-id="reservationId"
            :selected-filter="selectedFilter"
            :staff-picks-collection-id="staffPicksCollection?.id"
            :staff-pick-count="staffPickCount"
            @filter-staff-picks="() => setFilter('expert_picks')"
          />
        </template>
      </GalleryFilter>
      <ScrollToTop
        :user-has-scrolled-past-breakpoint="userHasScrolledPastBreakpoint"
        :action-bar-is-visible="bulkSelectActionBarIsVisible"
        :chat-is-open="isChatOpen"
      />
      <SoonaError
        v-if="galleryPriorityErrors"
        :priority-errors="galleryPriorityErrors"
      />
      <section v-else-if="isLoading || assetRows.length" ref="galleryWrapper">
        <InfiniteGallery
          v-slot="{ data }"
          :rows="assetRows"
          :start-page="1"
          :initial-scroll-position="0"
          :height="rowHeight"
          :gap="gutter"
          :offset-top="offsetTop"
        >
          <ReservationDigitalAssetCard
            v-for="rda in data.assets"
            :key="rda.id"
            :reservation-digital-asset="rda"
            :always-show-title="alwaysShowTitles"
            :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
            :to="generateRouteLocation(rda)"
            :selected="isAssetSelected(rda)"
            :select-disabled="
              colorCardSelectModeIsOn &&
              !(
                rda.digital_asset?.media_type === 'photo' ||
                rda.digital_asset?.media_type === 'video'
              )
            "
            @selection-click="handleSelectionClick(rda, $event)"
            @request-page="fetchPage($event)"
          />
        </InfiniteGallery>
        <div
          v-if="itemsInBag > 0 && selectedFilter !== 'edits'"
          class="gallery-view__checkout-btn-wrapper"
        >
          <SoonaButton
            element="router-link"
            to="/bag"
            @on-click="trackGoToCheckoutLinkClick"
          >
            go to checkout
          </SoonaButton>
        </div>
      </section>
      <ProTips v-else-if="isPreShoot || isPendingShotlist" />
      <EmptyGallery
        v-else
        class="empty-gallery"
        :filter-by="selectedFilter"
        :reservation-id="reservationId"
      />
    </div>
  </div>
  <DigitalAssetMultiSelectActionBar
    :account-id="accountId"
    :reservation-id="reservationId"
    :is-chat-open="isChatOpen"
    :page-bounding-rect-left="left"
    :page-bounding-rect-width="width"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.gallery-view {
  position: relative;

  .gallery-wrapper {
    min-height: 100vh;
  }

  .account-info {
    display: inline-block;
  }

  .crew-tools-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;

    .edit-actions-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 0.5rem;
    }
  }

  &__checkout-btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
  }
}
</style>
