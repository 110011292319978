<script setup>
import ShippingTracker from 'src/components/user/anytime/details/shipping_progress/ShippingTracker.vue';
import RemovePackage from 'src/components/user/anytime/inventory/RemovePackage.vue';
import PostShootAndReturnAddress from 'src/components/user/anytime/inventory/PostShootAndReturnAddress.vue';
import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import { packageStatuses } from 'src/lib/inventory/constants';
import { convertToMMDDYYYYlong } from 'src/lib/date-formatters';
import { useCapability } from 'src/composables/useCapability';
import { component as Viewer } from 'v-viewer';
import SoonaTooltip from 'src/components/ui_library/SoonaTooltip.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';

defineProps({
  reservationId: {
    type: Number,
    required: true,
  },
  reservationAccountId: {
    type: Number,
    required: true,
  },
  inventoryPackage: {
    type: Object,
    required: true,
  },
});

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

function deliveryProvider(inventoryPackage) {
  return inventoryPackage.inbound_shipping_detail.shipping_provider == 'other'
    ? inventoryPackage.inbound_shipping_detail.custom_provider
    : inventoryPackage.inbound_shipping_detail.shipping_provider;
}

function titleText(inventoryPackage) {
  if (inventoryPackage.in_studio_date) {
    return 'PACKAGE INTAKE DATE';
  } else if (
    inventoryPackage.inbound_shipping_detail.shipping_provider === 'drop off'
  ) {
    return 'DELIVERY METHOD';
  } else {
    return 'ESTIMATED DELIVERY DATE';
  }
}

function detailsText(inventoryPackage) {
  if (inventoryPackage.in_studio_date) {
    return convertToMMDDYYYYlong(inventoryPackage.in_studio_date);
  } else if (
    inventoryPackage.inbound_shipping_detail.shipping_provider === 'drop off'
  ) {
    return 'drop-off';
  } else if (inventoryPackage.inbound_shipping_detail.eta) {
    return convertToMMDDYYYYlong(inventoryPackage.inbound_shipping_detail.eta);
  } else {
    return 'tbd';
  }
}

function getLocationName(inventoryPackage) {
  if (!inventoryPackage.location) return;
  return inventoryPackage.location.display_name;
}

function tempGetShortName(inventoryPackage) {
  if (!inventoryPackage.location) return;
  if (inventoryPackage.location.slug === 'dfs-mpls' && !isSoonaStaff.value) {
    return 'MPLS';
  } else {
    return inventoryPackage.location.short_name;
  }
}
</script>

<template>
  <div>
    <RemovePackage
      class="delete-btn"
      :inventory-package="inventoryPackage"
      :reservation-id="reservationId"
    />
    <div class="tracking-numbers-container">
      <div class="details-top">
        <div class="shipping-delivery-date">
          <h2 class="u-body--regular">{{ titleText(inventoryPackage) }}</h2>
          <p class="text-l">
            <strong>
              {{ detailsText(inventoryPackage) }}
            </strong>
          </p>
        </div>
        <div class="buttons-wrapper">
          <SoonaButton
            v-if="isSoonaStaff"
            element="router-link"
            :to="{
              name: 'edit-package',
              params: {
                accountId: reservationAccountId,
                packageId: inventoryPackage.id,
              },
              query: {
                reservationId: reservationId,
              },
            }"
            size="medium"
            variation="secondary-gray"
          >
            edit package details
          </SoonaButton>
          <SoonaButton
            v-if="
              !!inventoryPackage.outbound_shipping_detail &&
              inventoryPackage.outbound_shipping_detail.label_url
            "
            element="a"
            :href="inventoryPackage.outbound_shipping_detail.label_url"
            target="_blank"
            size="medium"
            variation="secondary-gray"
          >
            <SoonaIcon name="package" size="small" />
            return label
          </SoonaButton>
          <SoonaButton
            v-if="
              !!inventoryPackage.inbound_shipping_detail &&
              inventoryPackage.inbound_shipping_detail.label_url
            "
            element="a"
            :href="inventoryPackage.inbound_shipping_detail.label_url"
            target="_blank"
            size="medium"
            variation="secondary-gray"
          >
            <SoonaIcon name="package" size="small" />
            <div v-if="inventoryPackage.inbound_shipping_detail.label_url">
              inbound label
            </div>
            <div v-else>print label</div>
          </SoonaButton>
        </div>
      </div>
      <div
        :class="
          titleText(inventoryPackage) === 'ESTIMATED DELIVERY DATE'
            ? 'shipping-details-lg'
            : 'shipping-details-sm'
        "
      >
        <div
          v-if="titleText(inventoryPackage) === 'ESTIMATED DELIVERY DATE'"
          class="shipping-from-to"
        >
          <p class="item-header">from:</p>
          <p
            v-if="inventoryPackage.inbound_shipping_detail.customer_address"
            class="item-details"
          >
            <span>{{
              inventoryPackage.inbound_shipping_detail.customer_address.street1
            }}</span>
            <span>
              {{
                inventoryPackage.inbound_shipping_detail.customer_address
                  .street2
              }}
            </span>
            <span>
              {{
                inventoryPackage.inbound_shipping_detail.customer_address.city
              }},
              {{
                inventoryPackage.inbound_shipping_detail.customer_address.state
              }},
              {{
                inventoryPackage.inbound_shipping_detail.customer_address.zip
              }}
            </span>
          </p>
        </div>
        <div class="shipping-from-to">
          <p class="item-header">to:</p>
          <p class="item-details">
            {{ getLocationName(inventoryPackage) }}
          </p>
        </div>
        <div class="delivery-status">
          <p class="item-header">delivery status:</p>
          <p class="item-details" data-cypress="delivery-status">
            {{
              inventoryPackage.inbound_shipping_detail.shipping_status
                ? inventoryPackage.inbound_shipping_detail.shipping_status
                : 'unknown'
            }}
          </p>
          <p
            v-if="
              inventoryPackage.inbound_shipping_detail.shipping_provider !==
              'drop off'
            "
            class="item-details"
          >
            <a
              v-if="inventoryPackage.inbound_shipping_detail.tracking_url"
              target="_blank"
              :href="inventoryPackage.inbound_shipping_detail.tracking_url"
            >
              {{ deliveryProvider(inventoryPackage) }}
              {{ inventoryPackage.inbound_shipping_detail.tracking_number }}
            </a>
            <span
              v-if="!inventoryPackage.inbound_shipping_detail.tracking_url"
              class="item-details"
            >
              {{ deliveryProvider(inventoryPackage) }}
              {{ inventoryPackage.inbound_shipping_detail.tracking_number }}
            </span>
          </p>
        </div>
      </div>
      <div class="mt-l mb-l tracker">
        <ShippingTracker :inventory-package="inventoryPackage" />
      </div>
      <div class="package-details">
        <div class="storage-label-wrapper">
          <div
            v-if="inventoryPackage.storage_label_qr_code && isSoonaStaff"
            class="storage-label"
          >
            <img :src="inventoryPackage.storage_label_qr_code" />
            <SoonaButton
              size="small"
              element="a"
              variation="tertiary"
              target="_blank"
              :href="`/inventory_packages/${inventoryPackage.id}/storage_label`"
            >
              view label
            </SoonaButton>
          </div>
          <div class="package-status">
            <p class="item-header">
              package ID:
              <span class="item-details">{{ inventoryPackage.id }}</span>
            </p>
            <p class="item-header">package status:</p>
            <p class="item-details" data-cypress="package-status">
              {{ packageStatuses[inventoryPackage.status] }}
            </p>
            <div v-if="inventoryPackage.location">
              <p class="item-header">storage location:</p>
              <p class="item-details">
                {{ tempGetShortName(inventoryPackage) }}
                <span
                  v-if="inventoryPackage.storage_location && isSoonaStaff"
                  class="storage-location"
                >
                  - {{ inventoryPackage.storage_location }}
                </span>
              </p>
            </div>
            <div
              v-if="
                inventoryPackage.height &&
                inventoryPackage.length &&
                inventoryPackage.width
              "
            >
              <p class="item-header">package dimensions:</p>
              <p class="item-details">
                {{ inventoryPackage.height }}" x {{ inventoryPackage.length }}"
                x {{ inventoryPackage.width }}"
              </p>
              <p v-if="inventoryPackage.weight" class="item-details">
                {{ inventoryPackage.weight }} lbs
              </p>
            </div>
          </div>
        </div>

        <div class="package-contents">
          <p class="item-header">package contents:</p>
          <p class="item-details">{{ inventoryPackage.description }}</p>
          <div class="inventory-images">
            <div
              v-for="image in inventoryPackage.inventory_items"
              :key="image.id"
              class="inventory-image"
            >
              <SoonaTooltip placement="bottom">
                <template
                  #default="{
                    setRef,
                    mouseenter,
                    focus,
                    mouseleave,
                    blur,
                    ariaDescribedby,
                  }"
                >
                  <span v-if="image.quantity > 1" class="quantity">{{
                    image.quantity
                  }}</span>
                  <viewer
                    :ref="el => setRef(el)"
                    :options="{
                      toolbar: false,
                      navbar: false,
                      backdrop: true,
                      minZoomRatio: 0.01,
                      maxZoomRatio: 2,
                      url: 'data-source',
                      title: () => `${image.catalog_item_name || ''}`,
                    }"
                    :aria-describedby="ariaDescribedby"
                    @mouseenter="mouseenter"
                    @focus="focus"
                    @mouseleave="mouseleave"
                    @blur="blur"
                  >
                    <img
                      :src="image.image_url"
                      :data-source="image.image_hq_url"
                    />
                  </viewer>
                </template>
                <template v-if="image.catalog_item_name" #tooltip-content>{{
                  image.catalog_item_name
                }}</template>
              </SoonaTooltip>
            </div>
          </div>
        </div>
      </div>

      <PostShootAndReturnAddress
        :account-id="reservationAccountId"
        :inventory-package="inventoryPackage"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.delete-btn {
  float: right;
}

.details-top {
  display: flex;
  justify-content: space-between;

  .edit-package {
    background-color: variables.$white-default;
    color: variables.$periwink-blue-50;
    border: 0.125rem solid variables.$periwink-blue-50;

    svg {
      margin-right: 0.5rem;
    }
  }
}

.shipping-delivery-date {
  .text-l {
    margin-bottom: 0.625rem;
  }
}

.buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.5rem;
}

.shipping-details-lg,
.shipping-details-sm,
.package-details {
  display: flex;
  gap: 2rem;

  .shipping-from-to {
    margin-bottom: 0.625rem;
    padding-right: 1rem;
  }

  .item-header {
    font-size: 0.75rem;
    font-weight: 900;
  }

  .item-details {
    @include variables_fonts.u-small--regular;
    max-width: 37rem;
    font-size: 0.75rem;
    font-weight: 100;

    a {
      text-decoration: underline;
    }

    span {
      display: block;
    }

    .storage-location {
      display: inline;
    }
  }

  .storage-label-wrapper {
    display: flex;
    gap: 1rem;
    flex-shrink: 0;
  }
  .storage-label {
    display: flex;
    flex-direction: column;

    img {
      width: 6.25rem;
      height: 6.25rem;
    }
  }

  .inventory-images {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 0.5rem 0;

    .inventory-image {
      &:hover {
        cursor: pointer;

        img {
          transform: scale(1.05);
        }
      }

      img {
        display: flex;
        justify-content: space-between;
        height: 6.25rem;
        width: 6.25rem;
        object-fit: cover;
        border: 0.0625rem solid variables.$gray-20;
        border-radius: variables.$radius-large;
      }

      .quantity {
        background-color: variables.$white-default;
        box-shadow: variables.$elevation-1;
        border-radius: 50%;
        height: 1.25rem;
        width: 1.25rem;
        display: inline-block;
        text-align: center;
        padding-left: 0.0625rem;
        position: absolute;
        margin-left: 5.4375rem;
        font-size: 0.75rem;
        z-index: 1;
      }
    }
  }
}

.shipping-details-lg,
.shipping-details-sm {
  width: 65%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.shipping-details-sm {
  max-width: 28.125rem;
}

.package-details {
  margin-top: 1.25rem;
  margin-bottom: 1rem;
}

.tracker {
  margin-bottom: 2.5rem;
}

@media only screen and (max-width: variables.$screen-xs-max) {
  .shipping-details-lg {
    width: 100%;
  }

  .buttons-wrapper {
    margin-bottom: 0.625rem;
  }

  .package-details {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 480px) {
  .shipping-details-lg {
    display: initial;
  }

  .shipping-from-to {
    width: 40%;
    display: inline-block;
    vertical-align: top;
  }

  .delivery-status {
    display: block;
    width: 100%;
  }
}
</style>
