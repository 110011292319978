<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCatalogItem } from '@/queries/useCatalog';
import { useTitle } from '@vueuse/core';
import { useUpdateCatalogItem } from '@/queries/useUpdateCatalogItem';
import DownloadProductAssets from './DownloadProductAssets.vue';
import PlaceholderImage from 'images/product-placeholder-periwink.jpg';
import ProductDetails from './ProductDetails.vue';
import ProductVariantsLinkCard from './ProductVariantsLinkCard.vue';
import ProductParentCard from './ProductParentCard.vue';
import ProductConnectedInventory from './ProductConnectedInventory.vue';
import ProductConnectedListings from './ProductConnectedListings.vue';
import ProductReservations from './ProductReservations.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import TitleBar from '@/components/shared/TitleBar.vue';
import { WhiteDefault } from '@/variables.module.scss';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import DigitalAssetCard from '@/components/dam/DigitalAssetCard.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { useDownloadFacets } from '@/composables/digital_assets/useDownloadFacets';
import { useDigitalAssetsFacets } from '@/queries/digital_assets/useDigitalAssetsFacets';

const props = defineProps({
  accountId: { type: String, required: true },
  productId: { type: String, required: true },
});

const accountId = computed(() => props.accountId);
const productId = computed(() => props.productId);

const router = useRouter();
const route = useRoute();

useTitle('product detail | soona');

const { pageViewed } = useBaseEvents();

onMounted(() => {
  pageViewed();
  window.scrollTo(0, 0);
});

// product module
const {
  data: productData,
  isSuccess,
  isLoading,
} = useCatalogItem(accountId, productId);

const product = computed(() => productData.value ?? { name: '' });

const getImage = product => {
  return product.preview_url ?? PlaceholderImage;
};

const isVariant = computed(() => product.value.parent_id !== null);

// infinite gallery
const wrapper = ref();
const itemsPerPage = ref(30);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});
// end infinite gallery

// digital assets module
const filters = computed(() => ({
  ownership: 'customer',
  visibility: 'all',
  catalog_item_id: productId.value,
}));

const {
  assetRows,
  fetchPage,
  isLoading: isLoadingDigitalAssets,
} = useInfiniteDigitalAssets(accountId, {
  startPage: 1,
  rowWidth,
  height: rowHeight,
  itemsPerPage,
  gutter,
  filters,
});

const hasAssets = computed(() => assetRows.value?.length > 0);

// catalog item digital asset facets
const { showWebDownload } = useDownloadFacets([], {
  filterArgs: {
    accountId,
    facets: 1,
  },
  itemPerPage: 1,
  filters,
  query: useDigitalAssetsFacets,
});

const webDownloadAvailable = computed(() => {
  return showWebDownload.value;
});

// navigation module
const previousPage = () => {
  let path = `/account/${accountId.value}/products`;
  if (route.query.returnToInventory) {
    path += `/inventory`;
  } else if (isVariant.value) {
    path += `/${product.value.parent_id}/variants`;
  }
  router.push({
    path: path,
    query: route.query,
  });
};

const backButtonCopy = computed(() =>
  route.query.returnToInventory
    ? 'back to inventory'
    : isVariant.value
      ? 'all variants'
      : 'all products'
);

// archive module
const {
  mutate,
  isPending: isMutating,
  isError: updateError,
} = useUpdateCatalogItem(accountId, productId);

const isArchived = computed(() => product.value.status === 'archived');

const archiveButtonText = computed(() =>
  isArchived.value ? 'unarchive this product' : 'archive this product'
);

const handleArchive = () => {
  mutate({
    status: isArchived.value ? 'active' : 'archived',
  });
};

const loading = computed(() => {
  return isLoading.value || isLoadingDigitalAssets.value;
});
</script>

<template>
  <div class="product-detail">
    <TitleBar
      :back-button-copy="backButtonCopy"
      :title="product.name"
      :previous-page="previousPage"
      :background-color="WhiteDefault"
      :border-color="WhiteDefault"
      justify="left"
      heading-id="product-detail-heading"
    />
    <div class="product-detail__body u-container">
      <SoonaLoading
        v-if="loading"
        is-loading
        :is-contained="true"
        loading-text="loading"
      />
      <div class="product-detail__content">
        <div class="product-detail__main-image">
          <img
            class="product-detail__main-image--product-image"
            :src="getImage(product)"
            alt="product image"
          />
        </div>
        <div class="product-detail__info">
          <ProductDetails
            v-if="isSuccess"
            :account-id="accountId"
            :product-id="productId"
          />
          <ProductVariantsLinkCard
            v-if="isSuccess"
            :account-id="accountId"
            :product-id="productId"
            :variant-count="product.variant_count"
          />
          <ProductParentCard
            v-if="isSuccess && isVariant"
            :account-id="accountId"
            :product="product"
          />
          <ProductReservations
            v-if="isSuccess && productId"
            :account-id="accountId"
            :product-id="productId"
          />
          <ProductConnectedListings
            v-if="isSuccess && productId"
            :account-id="accountId"
            :product="product"
          />
          <ProductConnectedInventory
            v-if="isSuccess && productId"
            :account-id="accountId"
            :product-id="productId"
            :product-name="product.name"
          />
          <div class="product-detail__archive">
            <SoonaButton
              size="medium"
              variation="secondary-gray"
              :disabled="isMutating"
              @click="handleArchive"
            >
              {{ archiveButtonText }}
            </SoonaButton>
            <SoonaError v-if="updateError">{{ updateError }}</SoonaError>
          </div>
        </div>
      </div>
      <div class="product-detail__images">
        <div v-if="hasAssets" class="product-detail__images--digital-assets">
          <div class="product-detail__images--heading">
            <h3 class="product-detail__images--heading--title">assets</h3>
            <DownloadProductAssets
              :account-id="accountId"
              :product-id="productId"
              :show-web="webDownloadAvailable"
            />
          </div>
          <div ref="wrapper" class="product-detail__images--edits-gallery">
            <InfiniteGallery
              v-slot="{ data }"
              :rows="assetRows"
              :start-page="1"
              :height="rowHeight"
              :gap="gutter"
              :offset-top="offsetTop"
            >
              <DigitalAssetCard
                v-for="asset in data.assets"
                :key="asset.id"
                :asset="asset"
                :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
                :to="{
                  name: 'product-assets-media-view',
                  params: {
                    accountId: asset.account_id,
                    productId,
                    digitalAssetId: asset.id,
                  },
                }"
                @request-page="pageNumber => fetchPage(pageNumber)"
              />
            </InfiniteGallery>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-detail {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__main-image {
    display: flex;
    margin-bottom: 1rem;

    &--product-image {
      object-fit: cover;
      max-height: 480px;
      height: fit-content;
      border-radius: 0.625rem;
    }
  }

  &__info {
    display: flex;
    flex: 0 1 40%;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 1.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    justify-content: space-between;
  }

  &__images {
    &--heading {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin-bottom: 1rem;

      &--title {
        @include variables_fonts.u-subheader--heavy;
      }
    }

    &--edits-gallery {
      margin-bottom: 1rem;
    }
  }

  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    &__body {
      flex-direction: column;
      gap: 1.5rem;
    }

    &__images {
      max-width: 100%;
      margin: 0;
    }

    &__main-image {
      flex: 0 1 55%;
    }

    &__info {
      flex: 0 1 45%;
    }

    &__content {
      flex-direction: row;
      width: 100%;
      margin: 0;
    }
  }

  // screen-lg-min = 1200px
  @media (min-width: variables.$screen-lg-min) {
    &__body {
      gap: 2.5rem;
      flex-direction: column;
    }

    &__main-image {
      &--product-image {
        aspect-ratio: 1 / 1;
        margin-bottom: 2rem;
      }

      @supports not (aspect-ratio: 1 / 1) {
        &--product-image::before {
          float: left;
          padding-top: 100%;
          content: '';
        }

        &--product-image::after {
          display: block;
          content: '';
          clear: both;
        }
      }
    }
  }
}
</style>
