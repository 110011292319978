<script setup>
import { computed, toRefs } from 'vue';

import { useFlag } from '@/composables/useFlag';
import { useTiers } from '@/queries/tiers/useTiers';
import { useAccount } from '@/composables/useAccount';

import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTransition from '@/components/ui_library/SoonaTransition.vue';
import SoonaRadioCardItem from '@/components/ui_library/soona_radio_cards/SoonaRadioCardItem.vue';
import SoonaRadioCardGroup from '@/components/ui_library/soona_radio_cards/SoonaRadioCardGroup.vue';

import SoonaMembershipCard from '../SoonaMembershipCard.vue';

import { PeriwinkBlue20, PeriwinkBlue80 } from 'src/variables.module.scss';

const props = defineProps({
  isLoading: { type: Boolean, default: false },
  isDisabled: { type: Boolean, default: false },
  accountId: { type: [String, Number], default: null },
});

const { accountId, isLoading, isDisabled } = toRefs(props);

const membershipLevel = defineModel('membershipLevel');
const membershipBillingCycle = defineModel('membershipBillingCycle');

const { account } = useAccount(accountId);
const phoenixStudioRentalFeeBillingCycleSwitchFlag = useFlag(
  'phoenix_studio_rental_fee_billing_cycle_switch'
);

const { data: tiers } = useTiers();
const findTier = slug => tiers.value?.find(tier => tier.slug === slug);

const existingBillingCycle = computed(() => {
  return account.value?.current_subscription_recurring_interval;
});

const showBillingCycle = () => {
  return existingBillingCycle.value === null;
};

const handleToggleBillingCycle = () => {
  if (membershipBillingCycle.value === 'year') {
    membershipBillingCycle.value = 'month';
  } else {
    membershipBillingCycle.value = 'year';
  }
};

const tierOne = computed(() => findTier('tier-one'));
const tierOnePrice = computed(() => {
  if (membershipBillingCycle.value === 'month') {
    return tierOne.value?.product.prices.find(
      price => price.recurring_interval === 'month'
    )?.recurring_price;
  }

  const tierOneAnnualPrice =
    (tierOne.value?.product.prices.find(
      price => price.recurring_interval === 'year'
    )?.unit_amount ?? 0) / 12;

  return `$${tierOneAnnualPrice}/month`;
});

const tierTwo = computed(() => findTier('tier-two'));
const tierTwoPrice = computed(() => {
  if (membershipBillingCycle.value === 'month') {
    return tierTwo.value?.product.prices.find(
      price => price.recurring_interval === 'month'
    )?.recurring_price;
  }

  const tierTwoAnnualPrice =
    (tierTwo.value?.product.prices.find(
      price => price.recurring_interval === 'year'
    )?.unit_amount ?? 0) / 12;

  return `$${tierTwoAnnualPrice}/month`;
});
const capitalizeString = string => {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
};
</script>

<template>
  <div class="studio-fee-selection">
    <p class="studio-fee-selection__intro-title">
      Without a membership, you can opt to buy the Studio pass for $149 per
      shoot.
      <span class="u-body--heavy">
        Select a membership to use the studios for free & access more perks.
      </span>
    </p>

    <div class="studio-fee-selection__compare">
      <SoonaButton
        element="a"
        size="medium"
        target="_blank"
        variation="tertiary"
        rel="noopener noreferrer"
        href="https://soona.co/pricing#feature-list"
      >
        Compare options
        <SoonaIcon
          size="small"
          name="arrow-right"
          class="studio-fee-selection__compare__icon"
        />
      </SoonaButton>
    </div>

    <SoonaRadioCardGroup
      v-model="membershipLevel"
      label="select membership"
      name="membershipLevel"
      :is-loading="isLoading"
      :disabled="isDisabled"
    >
      <SoonaRadioCardItem value="tier-one" label="basic">
        <template #image>
          <SoonaMembershipCard
            :title="capitalizeString(tierOne?.name)"
            membership="basic"
            description="Free studio passes"
          />
        </template>

        <SoonaFlag
          type="pill"
          title="No studio fees"
          class="radio-card-flag"
          padding-size="0.0625rem 0.25rem"
          :text-color="PeriwinkBlue80"
          :background-color="PeriwinkBlue20"
        />
        <h3 class="u-title--heavy">
          {{ capitalizeString(tierOne?.name) }} membership
        </h3>
        <SoonaTransition name="fade-right" mode="out-in">
          <p :key="membershipBillingCycle + 'tier-one'">
            <span class="u-label--heavy">{{ tierOnePrice }}</span>
            <span
              v-if="membershipBillingCycle === 'year'"
              class="u-label--regular"
            >
              (billed annually)
            </span>
          </p>
        </SoonaTransition>

        <template #aside>
          <ul class="studio-fee-selection__aside">
            <li>Price per photo: <strong>$39</strong></li>
            <li>
              Models + styling:
              <strong>Full price</strong>
            </li>
            <li>Shoot upgrades: <strong>Full price</strong></li>
            <li>Premium edits: <strong>$9+ each</strong></li>
            <li>AI tools: <strong>Basic access</strong></li>
          </ul>
        </template>
      </SoonaRadioCardItem>

      <SoonaRadioCardItem value="tier-two" label="standard">
        <template #image>
          <SoonaMembershipCard
            :title="capitalizeString(tierTwo?.name)"
            membership="standard"
            description="10% off pro services"
          />
        </template>

        <SoonaFlag
          type="pill"
          class="radio-card-flag"
          title="No studio fees + best value"
          :text-color="PeriwinkBlue80"
          padding-size="0.0625rem 0.25rem"
          :background-color="PeriwinkBlue20"
        />
        <h3 class="u-title--heavy">
          {{ capitalizeString(tierTwo?.name) }} membership
        </h3>
        <SoonaTransition name="fade-right" mode="out-in">
          <p :key="membershipBillingCycle + 'tier-two'">
            <span class="u-label--heavy">{{ tierTwoPrice }}</span>
            <span
              v-if="membershipBillingCycle === 'year'"
              class="u-label--regular"
            >
              (billed annually)
            </span>
          </p>
        </SoonaTransition>

        <template #aside>
          <ul class="studio-fee-selection__aside">
            <li>Price per photo: <strong>$39</strong></li>
            <li>
              Models + styling:
              <strong class="strong-periwink">10% OFF</strong>
            </li>
            <li>
              Shoot upgrades:
              <strong class="strong-periwink">10% OFF</strong>
            </li>
            <li>
              Premium edits:
              <strong class="strong-periwink">FREE</strong>
            </li>
            <li>AI tools: <strong>Full access</strong></li>
          </ul>
        </template>
      </SoonaRadioCardItem>

      <SoonaRadioCardItem
        :key="membershipBillingCycle + '3'"
        value="day-pass"
        label="day pass"
      >
        <template #image>
          <SoonaMembershipCard
            membership="day"
            title="Studio pass"
            description="$149 per booking"
          />
        </template>

        <h3 class="u-title--heavy">Pay for a one-time reservation</h3>
        <p
          class="u-small--regular studio-fee-selection__studio-pass-description"
        >
          Unlocks access to our virtual shoot experience, hundreds of props,
          professional sets and more.
        </p>
        <p>
          <span class="u-label--heavy">$149 Studio pass fee </span>
          <span class="u-label--regular">(per booking)</span>
        </p>
      </SoonaRadioCardItem>
    </SoonaRadioCardGroup>

    <div
      v-if="phoenixStudioRentalFeeBillingCycleSwitchFlag && showBillingCycle()"
      class="studio-fee-selection__footer"
    >
      <SoonaButton
        size="medium"
        variation="tertiary"
        @click="handleToggleBillingCycle"
      >
        switch to
        {{ membershipBillingCycle === 'year' ? 'monthly' : 'yearly' }} billing
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.studio-fee-selection {
  max-width: 40rem;

  &__intro-title {
    margin-bottom: 1.5rem;
  }

  &__compare {
    display: flex;
    justify-content: end;
    margin-bottom: 0.5rem;

    .studio-fee-selection__compare__icon {
      transition: transform 0.1s ease;
    }
    & a:hover {
      .studio-fee-selection__compare__icon {
        transform: translateX(0.1875rem);
      }
    }
  }

  &__aside {
    gap: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  &__studio-pass-description {
    margin-top: 0.25rem;
    margin-bottom: 0.5rem;
  }

  & .radio-card-flag {
    margin-bottom: 0.5rem;
  }

  & .strong-periwink {
    color: variables.$periwink-blue-80;
  }

  &__footer {
    display: flex;
    margin-top: 1rem;
    justify-content: center;
  }

  @media (min-width: variables.$screen-md-min) {
    & .radio-card-flag {
      margin-bottom: 1.5rem;
    }
  }
}
</style>
