<script setup>
import { computed, ref, reactive, nextTick } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import CsrCard from 'src/components/user/anytime/customer_shoot_readiness/CsrCard.vue';
import CsrChecklistDialog from '@/components/user/anytime/customer_shoot_readiness/CsrChecklistDialog.vue';
import CsrDropdowns from 'src/components/user/anytime/customer_shoot_readiness/CsrDropdowns.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import ExternalLinkIcon from 'src/components/svgs/ExternalLinkIcon.vue';
import CaretViewDetails from 'src/components/svgs/CaretViewDetails.vue';
import {
  collapseSection,
  expandSection,
} from 'src/composables/useDrawerToggle';
import { useReservationReadinessSteps } from '@/queries/reservation-readiness-steps/useReservationReadinessSteps';
import { useUpdateCustomerShippingStatus } from 'src/queries/reservations/useUpdateCustomerShippingStatus';
import { useConfirmProServiceRequirement } from '@/queries/pro-service-requirements/useConfirmProServiceRequirement';
import { useUpdateShotListStatus } from '@/queries/reservations/useUpdateShotListStatus';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useCapability } from 'src/composables/useCapability';
import { Gray50 } from 'src/variables.module.scss';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useFlag } from '@/composables/useFlag';

const props = defineProps({
  reservationId: {
    default: undefined,
    type: Number,
    required: true,
  },
  currentReservation: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['checklistItemEvent']);

const completedTasksOpen = ref(window.innerWidth >= 768);
const reservationId = computed(() => props.reservationId);
const currentReservation = computed(() => props.currentReservation);
const queryClient = useQueryClient();

const completedToggleVerb = computed(() =>
  completedTasksOpen.value ? 'hide' : 'show'
);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const isDisabled = computed(() => {
  return currentReservation.value?.is_wrapped && !isFtSoonaStaff.value;
});

const { data, isSuccess } = useReservationReadinessSteps(reservationId);
const {
  mutate: updateShotListStatus,
  isPending: isTogglingShotList,
  error: errorShotListUpdate,
} = useUpdateShotListStatus(reservationId);
const {
  mutate: confirmProServiceRequirement,
  isPending: isTogglingGrocery,
  error: errorGroceryUpdate,
} = useConfirmProServiceRequirement(
  currentReservation?.value?.grocery_shopping_pro_service_requirement?.id,
  reservationId
);
const {
  mutate: updateCustomerShippingStatus,
  isPending: isTogglingShipping,
  error: errorShippingUpdate,
} = useUpdateCustomerShippingStatus(reservationId);

const priorityError = usePriorityError(
  errorShotListUpdate,
  errorGroceryUpdate,
  errorShippingUpdate
);

const checkboxIsDisabled = slug => {
  if (slug === 'grocery-list' && isTogglingGrocery.value) {
    return true;
  } else if (slug === 'review-pack-shot-list' && isTogglingShotList.value) {
    return true;
  } else if (
    (slug === 'ship-fast-pass' && isTogglingShipping.value) ||
    (slug === 'ship-non-fast-pass' && isTogglingShipping.value)
  ) {
    return true;
  }
  return false;
};

const handleCheckboxChange = (slug, elTarget) => {
  if (!elTarget.checked) return; // only update if checkbox is checked

  if (slug === 'ship-fast-pass' || slug === 'ship-non-fast-pass') {
    updateCustomerShippingStatus(
      {},
      {
        onError: () => {
          elTarget.checked = false; //revert checkbox to unchecked if error updating
        },
      }
    );
  } else if (slug === 'review-pack-shot-list') {
    updateShotListStatus('pack_shot_list_reviewed', {
      onError: () => {
        elTarget.checked = false; //revert checkbox to unchecked if error updating
      },
    });
  } else if (slug === 'grocery-list') {
    confirmProServiceRequirement(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: queryKeys.reservationTimeline(reservationId),
          });
        },
      },
      {
        onError: () => {
          elTarget.checked = false; //revert checkbox to unchecked if error updating
        },
      }
    );
  }
};

const tasksActive = computed(() => data.value.filter(task => !task.complete));
const tasksCompleted = computed(() => data.value.filter(task => task.complete));

const transitionEnter = element => {
  element.style.height = 'auto';
  const height = getComputedStyle(element).height;
  element.style.height = 0;

  // Force repaint to make sure the animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = height;
  });
};

const transitionAfterEnter = element => {
  element.style.height = 'auto';
};

const transitionLeave = element => {
  const height = getComputedStyle(element).height;
  element.style.height = height;

  // Force repaint to make sure the animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = 0;
  });
};

async function checklistItemEvent(card) {
  emits('checklistItemEvent', card);
}

const handleAnchorLinkClick = async card => {
  checklistItemEvent(card);

  if (card.slug !== 'choose-package-handling') {
    await nextTick(() => {
      document
        .getElementById(card.completed_link.substring(1))
        ?.scrollIntoView({ behavior: 'smooth' });
    });
  }
};

// dialogs
const dialogs = reactive({
  'scheduling-preferences': false,
});

const handleDialogSubmit = card => {
  if (card.slug === 'scheduling-preferences') {
    dialogs[card.slug] = false;
  }
};

// segment
const { linkClicked } = useBaseEvents();

const handleDialogLinkClicked = card => {
  dialogs[card.slug] = true;

  linkClicked({
    context: 'CSR',
    subContext: card.title_text,
    linkLabel: card.cta_text,
    linkHref: null,
  });
};

// pack typeform test to run until 9/1
const packTypeformTestFlag = useFlag('toaster_pack_typeform_test');

const testPacks = [
  {
    name: 'Amazon A+ premium pack',
    typeformUrl: 'https://aeoybcf2761.typeform.com/Aplusprepro',
  },
  {
    name: 'video ads pack',
    typeformUrl: 'https://aeoybcf2761.typeform.com/videoadspack',
  },
  {
    name: 'photo + video full body model pack',
    typeformUrl: 'https://aeoybcf2761.typeform.com/photovideopack',
  },
  {
    name: 'photo + video hand model pack',
    typeformUrl: 'https://aeoybcf2761.typeform.com/photovideopack',
  },
];

const isPackTest = computed(() => {
  if (!currentReservation.value?.pack_configuration) return false;

  return testPacks.some(
    pack => pack.name === currentReservation.value?.pack_configuration?.name
  );
});

const packTestUrl = computed(() => {
  if (!currentReservation.value?.pack_configuration) return null;

  return testPacks.find(
    pack => pack.name === currentReservation.value?.pack_configuration?.name
  )?.typeformUrl;
});
</script>

<template>
  <section v-if="isSuccess" class="csr-checklist">
    <h2 class="csr-checklist__action-title">your to-do list</h2>
    <SoonaError v-if="priorityError">{{ priorityError }}</SoonaError>
    <p
      v-if="tasksActive && tasksActive.length === 0"
      class="csr-checklist__action-items csr-checklist__action-items--done"
    >
      <strong>you’re done!</strong> 🎉 you've completed your to-do list
    </p>
    <TransitionGroup
      v-else
      name="csr-list"
      class="csr-checklist__action-items"
      tag="ol"
      @enter="transitionEnter"
      @after-enter="transitionAfterEnter"
      @leave="transitionLeave"
    >
      <CsrCard
        v-for="(card, index) in tasksActive"
        :key="card.slug"
        :slug="card.slug"
        :current-reservation="currentReservation"
        :is-disabled="isDisabled"
      >
        <template v-if="card.required_for_text" #status>
          {{ card.required_for_text }}
        </template>
        {{ card.title_text }}
        <template #cta>
          <SoonaButton
            v-if="card['incomplete_link?'] && card.incomplete_link_is_external"
            :element="isDisabled ? 'button' : 'a'"
            :variation="index === 0 ? 'primary' : 'secondary-black'"
            size="medium"
            :href="card['incomplete_link']"
            target="_blank"
            :disabled="isDisabled ? isDisabled : undefined"
          >
            {{ card.cta_text }}
            <ExternalLinkIcon
              :stroke="isDisabled ? Gray50 : index === 0 ? '#fff' : '#000'"
            />
          </SoonaButton>
          <!-- pack typeform test to run until 9/1 -->
          <SoonaButton
            v-else-if="
              packTypeformTestFlag &&
              card['incomplete_link?'] &&
              card['slug'] === 'review-pack-shot-list' &&
              isPackTest
            "
            :element="isDisabled ? 'button' : 'a'"
            :variation="index === 0 ? 'primary' : 'secondary-black'"
            size="medium"
            :href="packTestUrl"
            target="_blank"
            :disabled="isDisabled ? isDisabled : undefined"
          >
            provide details
            <ExternalLinkIcon
              :stroke="isDisabled ? Gray50 : index === 0 ? '#fff' : '#000'"
            />
          </SoonaButton>
          <SoonaButton
            v-else-if="
              card['incomplete_link?'] && card.incomplete_link.startsWith('#')
            "
            :data-cypress="`button-internal-${card.slug}`"
            :variation="index === 0 ? 'primary' : 'secondary-black'"
            element="button"
            size="medium"
            :disabled="isDisabled ? isDisabled : undefined"
            @click="handleAnchorLinkClick(card)"
          >
            {{ card.cta_text }}
          </SoonaButton>
          <SoonaButton
            v-else-if="card['incomplete_link?']"
            :data-cypress="`button-internal-${card.slug}`"
            :variation="index === 0 ? 'primary' : 'secondary-black'"
            :element="isDisabled ? 'button' : 'router-link'"
            size="medium"
            :to="card['incomplete_link']"
            :disabled="isDisabled ? isDisabled : undefined"
          >
            {{ card.cta_text }}
          </SoonaButton>
          <CsrDropdowns
            v-else-if="card['select?']"
            :account-id="currentReservation.account_id"
            :slug="card.slug"
            :index="index"
            :is-disabled="isDisabled"
            :reservation-id="reservationId"
          />
          <SoonaButton
            v-else-if="card['dialog?']"
            :data-cypress="`button-internal-${card.slug}`"
            :variation="index === 0 ? 'primary' : 'secondary-black'"
            size="medium"
            @on-click="handleDialogLinkClicked(card)"
          >
            {{ card.cta_text }}
          </SoonaButton>
          <label v-if="card['has_checkbox']" class="csr-checklist__checkbox">
            <input
              type="checkbox"
              :checked="card.checkbox_is_checked"
              :disabled="isDisabled || checkboxIsDisabled(card.slug)"
              :data-cypress="`checkbox-complete-${card.slug}`"
              @change="$event => handleCheckboxChange(card.slug, $event.target)"
            />
            {{ card.checkbox_text }}
          </label>
        </template>
        <CsrChecklistDialog
          v-if="dialogs[card.slug]"
          :reservation-id="reservationId"
          :slug="card.slug"
          :title="card.title_text"
          @close-modal="dialogs[card.slug] = false"
          @submit="handleDialogSubmit(card)"
        />
      </CsrCard>
    </TransitionGroup>
    <Transition
      name="csr-list"
      @enter="transitionEnter"
      @after-enter="transitionAfterEnter"
      @leave="transitionLeave"
    >
      <div v-if="tasksCompleted?.length > 0">
        <h2 class="csr-checklist__completed-title">
          <a
            id="csr-completed-items-title"
            href="#csr-completed-items"
            class="csr-checklist__completed-toggle"
            :class="{
              'csr-checklist__completed-toggle--open': completedTasksOpen,
            }"
            aria-controls="csr-completed-items"
            aria-label="toggle completed tasks panel"
            :aria-expanded="completedTasksOpen ? 'true' : 'false'"
            role="button"
            @click.prevent="
              completedTasksOpen
                ? collapseSection($refs.csrCompletedItems, () => {
                    completedTasksOpen = false;
                  })
                : ((completedTasksOpen = true),
                  expandSection($refs.csrCompletedItems))
            "
          >
            {{ completedToggleVerb }} completed items
            <CaretViewDetails aria-hidden="true" fill="#000" />
          </a>
        </h2>
        <ul
          id="csr-completed-items"
          ref="csrCompletedItems"
          class="u-drawer"
          :class="{ 'u-drawer--open': completedTasksOpen }"
          aria-labelledby="csr-completed-items-title"
        >
          <TransitionGroup
            name="csr-list"
            @enter="transitionEnter"
            @after-enter="transitionAfterEnter"
            @leave="transitionLeave"
          >
            <CsrCard
              v-for="card in tasksCompleted"
              :key="card.slug"
              :slug="card.slug"
              :completed="true"
            >
              <p>{{ card.title_text }}</p>
              <template #cta>
                <button
                  v-if="card.completed_link.startsWith('#')"
                  type="button"
                  class="u-button-reset csr-checklist__complete-link"
                  @click="handleAnchorLinkClick(card)"
                >
                  {{ card.completed_link_text }}
                </button>
                <router-link
                  v-else
                  class="csr-checklist__complete-link"
                  :to="card.completed_link"
                >
                  {{ card.completed_link_text }}
                </router-link>
              </template>
            </CsrCard>
          </TransitionGroup>
        </ul>
      </div>
    </Transition>
  </section>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.csr-checklist {
  margin-bottom: 1rem;

  &__action-title {
    @include variables_fonts.u-body--heavy;

    margin-bottom: 1rem;
    color: variables.$black-default;
  }

  &__action-items {
    &:last-child {
      margin-bottom: 2rem;
    }

    &--done {
      @include variables_fonts.u-subheader--regular;

      display: block;
      color: variables.$gray-60;
      padding-bottom: 1rem;

      strong {
        color: variables.$avo-toast-60;
      }
    }
  }

  &__completed-title {
    @include variables_fonts.u-label--regular;

    display: flex;
  }

  &__completed-toggle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: variables.$black-default;
    padding: 1rem 0;

    > svg {
      display: block;
      margin-left: 0.25rem;
    }

    &--open {
      > svg {
        transform: rotate(180deg);
      }
    }
  }

  &__complete-link {
    @include variables_fonts.u-label--regular;

    color: variables.$black-default;
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }

  &__checkbox {
    @include variables_fonts.u-label--regular;

    cursor: pointer;
    display: flex;
    align-items: center;
    color: variables.$black-default;

    input {
      margin-right: 0.5rem;
    }
  }

  @media (min-width: variables.$screen-sm-min) {
    margin-bottom: 1.75rem;

    &__action-title {
      @include variables_fonts.u-subheader--heavy;
    }

    &__action-items:last-child {
      margin-bottom: 2.75rem;
    }
  }
}

// Animating CSR cards in/out
.csr-list-leave-active,
.csr-list-enter-active {
  overflow: hidden;
  transition:
    opacity 0.3s ease-out,
    height 0.3s ease-out;
}

.csr-list-leave-to,
.csr-list-enter-from {
  opacity: 0;
  height: 0;
}
</style>
