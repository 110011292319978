<script setup>
import { computed, ref, toRefs, watch } from 'vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import DigitalAssetCard from '@/components/account_gallery/DigitalAssetCard.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { refDebounced, useElementBounding } from '@vueuse/core';
import SortBy from '@/components/account_gallery/SortBy.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';
import MultiSelectActionBar from '@/components/account_gallery/MultiSelectActionBar.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useAccount } from '@/composables/useAccount';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import {
  useGenerateEmbeddings,
  useEmbeddingsMetrics,
} from '@/queries/digital_assets/useEmbeddings';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  incompleteTraitsExist: {
    type: Boolean,
    default: false,
  },
});

const { accountId } = toRefs(props);
const { hasEmbeddingsEnabled } = useAccount(accountId);

const wrapper = ref(null);

const { left, width } = useElementBounding(wrapper);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 0.75,
});

const itemsPerPage = ref(25);
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 200);
const similarityText = ref('');
const similarityTextSubmitted = ref('');
const similarityThreshold = ref(0.2);
const selectedSort = ref('date modified');
const sortDirection = ref('desc');
const startPage = ref(1);

const filters = computed(() => ({
  origin: ['customer', 'soona'],
  ownership: 'customer',
  visibility: 'all',
}));

const {
  assetRows,
  fetchPage,
  pagination,
  error: inifiniteDigitalAssetsError,
  rawData,
  isFetching,
} = useInfiniteDigitalAssets(accountId, {
  rowWidth,
  itemsPerPage,
  height: rowHeight,
  gutter,
  filters,
  searchQuery: searchDebounced,
  similarityText: similarityTextSubmitted,
  similarityThreshold,
  sortBy: selectedSort,
  sortDirection: sortDirection,
});

const changeSortDirection = () => {
  if (sortDirection.value === 'desc') sortDirection.value = 'asc';
  else sortDirection.value = 'desc';
};

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, da => da);

const { mutate: generateEmbeddings } = useGenerateEmbeddings(accountId);

const { data: metricsData, error: metricsError } =
  useEmbeddingsMetrics(accountId);

const searchQueryHistory = ref([]);
const similaritySearchBeganAt = ref(null);

const setSimilaritySearch = () => {
  similaritySearchBeganAt.value = new Date();
  similarityTextSubmitted.value = similarityText.value;
};

watch(
  () => isFetching.value,
  (newVal, oldVal) => {
    if (oldVal === true && newVal === false && similaritySearchBeganAt.value) {
      const searchFinishedAt = new Date();
      const latency = searchFinishedAt - similaritySearchBeganAt.value + 'ms';

      searchQueryHistory.value.unshift({
        query: similarityTextSubmitted.value,
        similarity_threshold: similarityThreshold.value,
        latency: latency,
        number_of_results: pagination.value?.totalCount ?? 0,
      });

      similaritySearchBeganAt.value = null;
    }
  }
);

const accountMetricsData = computed(() => {
  return metricsData.value?.account_embeddings_generation_metrics;
});

const generatingEmbeddings = computed(() => {
  return accountMetricsData.value?.status === 'generating';
});

const priorityErrors = usePriorityErrors(
  inifiniteDigitalAssetsError,
  metricsError
);
</script>

<template>
  <div class="ai-search-test-gallery">
    <SoonaAlert v-if="!hasEmbeddingsEnabled" type="warning">
      this account does not have image embeddings enabled. click 'generate
      embeddings' to create them.
    </SoonaAlert>
    {{ metricsData }}
    <div class="ai-search-test-gallery__filters">
      <SoonaTextfield
        v-model="searchText"
        type="search"
        label="classic search"
        name="classic-search"
        placeholder="no AI functionality here"
      >
        <template #icon-left>
          <SoonaIcon name="search" size="small" />
        </template>
      </SoonaTextfield>
      <div class="ai-search-test-gallery__filters--section">
        <SoonaTextfield
          v-model="similarityText"
          type="search"
          label="embeddings search"
          name="embeddings search"
          placeholder="click search or press enter"
          :disabled="isFetching || !hasEmbeddingsEnabled"
          @keydown.enter="setSimilaritySearch"
        >
          <template #icon-left>
            <SoonaIcon name="search" size="small" />
          </template>
        </SoonaTextfield>
        <SoonaButton
          variation="secondary-black"
          size="medium"
          :disabled="isFetching || !similarityText"
          @click="setSimilaritySearch"
        >
          search
        </SoonaButton>
      </div>
      <div class="ai-search-test-gallery__filters--section">
        <div class="ai-search-test-gallery__filters--similarity-threshold">
          <label for="similarityThreshold" class="u-label--heavy">
            similarity threshold
          </label>
          <input
            id="similarityThreshold"
            v-model="similarityThreshold"
            type="number"
            step="0.01"
            min="0"
            max="1"
            :disabled="!hasEmbeddingsEnabled"
          />
        </div>
        <SoonaButton
          variation="secondary-black"
          size="medium"
          :disabled="generatingEmbeddings"
          @click="generateEmbeddings"
        >
          generate embeddings
        </SoonaButton>
      </div>
    </div>
    <div class="ai-search-test-gallery__metrics">
      <!-- <pre>{{ metricsData }}</pre> -->
      <div
        v-if="accountMetricsData?.status"
        class="ai-search-test-gallery__metric"
      >
        <p class="ai-search-test-gallery__metric--header u-label--heavy">
          account embeddings
        </p>
        <table>
          <thead>
            <th>assets</th>
            <th>status</th>
            <th>duration</th>
            <th>throughput</th>
            <th>updated at</th>
          </thead>
          <tbody>
            <tr>
              <td>
                {{ accountMetricsData?.assets_count }}
              </td>
              <td>
                {{ accountMetricsData?.status }}
              </td>
              <td>
                {{ accountMetricsData?.duration }}
                seconds
              </td>
              <td>
                {{
                  (
                    accountMetricsData?.assets_count /
                    accountMetricsData?.duration
                  ).toFixed(2)
                }}
                assets per second
              </td>
              <td>
                {{ accountMetricsData?.updated_at }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ai-search-test-gallery__metric">
        <p class="ai-search-test-gallery__metric--header u-label--heavy">
          search query performance
        </p>
        <table>
          <thead>
            <th>query</th>
            <th>similarity threshold</th>
            <th>latency</th>
            <th># of results</th>
          </thead>
          <tbody>
            <tr v-for="(result, i) in searchQueryHistory" :key="i">
              <td>
                {{ result.query }}
              </td>
              <td>
                {{ result.similarity_threshold }}
              </td>
              <td>{{ result.latency }}</td>
              <td>
                {{ result.number_of_results }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="ai-search-test-gallery__sort-wrapper">
      <p class="ai-search-test-gallery__items">
        {{ isFetching ? 'fetching' : pagination.totalCount.toLocaleString() }}
        items
      </p>
      <SortBy
        v-model:selected-sort="selectedSort"
        :sort-direction="sortDirection"
        @change-sort-direction="changeSortDirection"
      />
    </div>
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <section ref="wrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :start-page="startPage"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <DigitalAssetCard
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :selected="isAssetSelected(asset)"
          :to="{
            name: 'all-assets-media-view', // to do - create ai search media view
            params: {
              accountId: asset.account_id,
              digitalAssetId: asset.id,
            },
          }"
          @request-page="pageNumber => fetchPage(pageNumber)"
          @selection-click="onSelectionClick(asset, $event)"
        />
      </InfiniteGallery>
      <MultiSelectActionBar
        :account-id="accountId"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        :selected-assets="selectedAssets"
        @close="resetSelection"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.ai-search-test-gallery {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__filters {
    margin-bottom: 3rem;

    &--similarity-threshold {
      display: flex;
      flex-direction: column;
    }

    &--section {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      gap: 1rem;
    }

    :deep(.soona-textfield) {
      flex-grow: 1;
    }
  }

  &__sort-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
  }

  &__items {
    color: variables.$gray-60;
  }

  &__metrics {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  &__metric {
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    padding: 1rem;

    &--header {
      margin-bottom: 1rem;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        padding: 0.5rem;
        text-align: left;
        border: 0.0625rem solid variables.$gray-20;
      }

      td {
        padding: 0.5rem;
        border: 0.0625rem solid variables.$gray-20;
      }
    }
  }
}
</style>
