import { computed, ref, watch, watchEffect } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { refDebounced } from '@vueuse/core';
export const useProductCatalogQueryParams = () => {
  const router = useRouter();

  const route = useRoute();

  // filters
  const searchText = ref(route.query.search ?? '');

  const searchDebounced = refDebounced(searchText, 200);

  const selectedFilter = ref(route.query.filter ?? 'active');

  const sortBy = ref({
    orderBy: route.query.order_by ?? 'name',
    direction: route.query.direction ?? 'asc',
  });

  const orderBy = computed(() => sortBy.value.orderBy);

  const direction = computed(() => sortBy.value.direction);

  // pagination
  const currentPage = ref(
    route.query.current_page ? Number(route.query.current_page) : 1
  );

  watchEffect(() => {
    if (route.query.current_page) {
      currentPage.value = Number(route.query.current_page);
    }
  });

  const itemsPerPage = ref(
    route.query.items_per_page ? Number(route.query.items_per_page) : 10
  );

  const packsPerPage = ref(itemsPerPage.value == 10 ? 24 : itemsPerPage);

  watch(
    [itemsPerPage, searchDebounced, orderBy, direction, selectedFilter],
    () => {
      currentPage.value = 1;
    }
  );

  // URL query params
  const queryObj = computed(() => {
    if (route.query.returnToInventory) {
      return { returnToInventory: true };
    }
    let result = {
      filter: selectedFilter.value,
      order_by: orderBy.value,
      direction: direction.value,
      current_page: currentPage.value,
      items_per_page: itemsPerPage.value,
    };
    if (searchDebounced.value) result.search = searchDebounced.value;
    return result;
  });

  watchEffect(() => {
    router.replace({
      query: queryObj.value,
    });
  });

  function resetFilters() {
    searchText.value = '';
    selectedFilter.value = 'active';
  }

  return {
    searchText,
    searchDebounced,
    selectedFilter,
    sortBy,
    orderBy,
    direction,
    currentPage,
    itemsPerPage,
    packsPerPage,
    queryObj,
    resetFilters,
  };
};
