<template>
  <div class="shipping">
    <div class="column is-full is-centered soona-container">
      <div class="columns is-centered">
        <div class="column is-half">
          <h2 class="title has-text-centered is-size-4-mobile">
            we're almost ready!
          </h2>

          <!-- order summary -->

          <p class="soona-title-a1 mb-m"><b>ORDER CONFIRMATION:</b></p>

          <div class="shipping-form-container u-small--regular">
            <p class="soona-title-a1 mb-s"><b>SHIP TO:</b></p>

            <div class="pl-m mb-m">
              <p>{{ currentShipment.address_to.name }}</p>
              <p>
                {{
                  [
                    currentShipment.address_to.street1,
                    currentShipment.address_to.street2,
                  ].join(' ')
                }}, {{ currentShipment.address_to.city }},
                {{ currentShipment.address_to.state }}
                {{ currentShipment.address_to.zip }}
              </p>
            </div>

            <p class="soona-title-a1 mb-s"><b>FROM:</b></p>

            <div class="pl-m mb-m">
              <p>{{ currentShipment.address_from.name }}</p>
              <p>
                {{
                  [
                    currentShipment.address_from.street1,
                    currentShipment.address_from.street2,
                  ].join(' ')
                }}, {{ currentShipment.address_from.city }},
                {{ currentShipment.address_from.state }}
                {{ currentShipment.address_from.zip }}
              </p>
            </div>

            <p class="soona-title-a1 mb-s"><b>PACKAGE DETAILS:</b></p>

            <div class="pl-m mb-m">
              <p>
                1 package x {{ currentShipment.parcels.length }}" x
                {{ currentShipment.parcels.width }}" x
                {{ currentShipment.parcels.height }}" ({{
                  currentShipment.parcels.weight
                }}
                lbs)
              </p>
            </div>

            <p class="soona-title-a1 mb-s"><b>CARRIER:</b></p>

            <div class="pl-m">
              <p>
                <b class="soona-title-a1">
                  {{ currentRate.provider }} {{ currentRate.service_level }}
                </b>
              </p>
              <p class="is-lowercase">
                {{ overrideDurationTerms(currentRate.duration_terms) }}
              </p>
              <p>${{ currentRate.amount }}</p>
            </div>
          </div>

          <!-- total cost of shipping -->

          <p class="mt-l u-small--regular mb-m">
            <b class="soona-title-a1">TOTAL:</b> ${{ currentRate.amount }}
          </p>

          <h2 class="u-title--heavy payment-info-title">payment info</h2>
          <BillingInfo class="shipping__billing-info" :account-id="accountId" />
          <SoonaPayment
            stripe-payment-required
            :on-payment-action="submitPaymentClicked"
            action-text="checkout"
          >
            <template #action-block>
              <div class="soona-refund-policy">
                <p class="u-small--regular">
                  shipping fees are non-refundable. please review our
                  <a
                    class="soona-link"
                    @click.prevent="
                      onRefundPolicyClick(
                        'cancellation and refund policy',
                        'https://www.soona.co/refund-policy'
                      )
                    "
                    >cancellation and refund policy</a
                  >
                  for more information.
                </p>
              </div>
            </template>
          </SoonaPayment>

          <div v-if="errors" class="soona-errors" data-cypress="errors">
            <p
              v-for="(error, index) in getErrorMessages"
              :key="index"
              class="u-small--regular"
            >
              {{ error }}
            </p>
          </div>

          <div class="buttons is-centered">
            <a class="soona-link u-small--regular" @click.prevent="onCancel">
              cancel
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, computed } from 'vue';
import { mapActions, mapState, mapGetters, useStore } from 'vuex';
import { triggerShippingCheckoutStartedEvent } from '@/api/shipping_detail';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useMe } from '@/composables/user/useMe';
import { useRoute } from 'vue-router';
import { useTitle } from '@vueuse/core';
import SoonaPayment from 'src/components/SoonaPayment.vue';
import BillingInfo from '@/components/checkout/BillingInfo.vue';

export default {
  name: 'Payment',
  components: {
    SoonaPayment,
    BillingInfo,
  },
  setup() {
    const { buttonClicked, linkClicked, pageViewed } = useBaseEvents();
    const { currentAccountId } = useMe();
    const store = useStore();
    const reservationId = computed(
      () => store.state.reservation?.currentReservation?.id
    );
    const currentRate = computed(() => store.state.reservation?.currentRate);
    const accountId = computed(() => +currentAccountId.value);
    const route = useRoute();
    const title = useTitle();

    const onRefundPolicyClick = (linkLabel, linkHref) => {
      linkClicked({
        context: route.meta.context,
        subContext: 'shipping checkout',
        linkLabel: linkLabel,
        linkHref: linkHref,
      });

      window.open(linkHref, '_blank', 'noopener noreferrer');
    };

    onMounted(() => {
      title.value = `${route.meta.page_title} | soona`;

      pageViewed();
      triggerShippingCheckoutStartedEvent(
        reservationId.value,
        currentRate.value
      );
    });

    return {
      buttonClicked,
      linkClicked,
      onRefundPolicyClick,
      accountId,
    };
  },
  computed: {
    ...mapState({
      currentReservation: state => state.reservation.currentReservation,
      currentRate: state => state.reservation.currentRate,
      currentShipment: state => state.reservation.currentShipment,
      errors: state => state.errors.errors,
    }),
    ...mapGetters('errors', ['getErrorMessages']),
  },
  methods: {
    ...mapActions('reservation', ['submitPayment']),
    submitPaymentClicked(stripe, card, saveCard) {
      this.buttonClicked({
        context: this.$route.meta.context,
        subContext: 'shipping checkout',
        buttonLabel: 'checkout',
        buttonAction: 'submit payment',
      });

      return this.submitPayment({
        stripe: stripe,
        card: card,
        saveCard: saveCard,
        shipping_amount: this.currentRate.amount,
        shipping_details: {
          rate_id: this.currentRate.rate_id,
          shipping_provider: this.currentRate.provider,
          customer_address: this.currentShipment.address_from,
          parcels: this.currentShipment.parcels,
          shipping_direction: 'inbound',
        },
      });
    },
    overrideDurationTerms(terms) {
      if (
        terms ===
        'Delivery within 1, 2, or 3 days based on where your package started and where it’s being sent.'
      ) {
        return 'delivery guaranteed within 3 business days based on your location';
      } else {
        return terms;
      }
    },
    onCancel() {
      this.linkClicked({
        context: this.$route.meta.context,
        subContext: 'shipping checkout',
        linkLabel: 'cancel',
        linkHref: '/user/anytime#/reservation/info',
      });

      this.$router.push({ name: 'info' });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';

.shipping {
  &__billing-info {
    margin-bottom: 1rem;
  }
}

.payment-info-title {
  margin-bottom: 1.5rem;
  text-align: center;
}

.soona-refund-policy {
  background-color: variables.$gray-10;
  border-radius: variables.$control-radius;
  padding: 0.5rem;
}

.buttons {
  padding-top: 1rem;
}
</style>
