<script setup>
import { computed } from 'vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import { BubbletapePink20 } from '@/variables.module.scss';
import { useRuleIcon } from '../../competitive_analysis/compare/useRuleIcon';

const props = defineProps({
  listingAction: {
    type: Object,
    required: true,
  },
  tagName: {
    type: String,
    default: 'li',
  },
});

const listingAction = computed(() => props.listingAction);

const actionType = computed(() => {
  let result;
  if (
    listingAction.value.listing_action_slug === 'main-image-product-on-white'
  ) {
    result = 'listing';
  } else {
    result =
      listingAction.value.action_type === 'listing' ? 'listing' : 'image';
  }
  listingAction.value.number_of_actions > 1 ? (result += 's') : result;
  return result;
});

const quickFix = computed(() =>
  ['lifestyle', 'main-image-product-on-white', 'low-resolution'].includes(
    listingAction.value.listing_action_slug
  )
);

const ctaText = computed(() => {
  switch (listingAction.value.listing_action_slug) {
    case 'low-resolution':
      return 'review and optimize';
    case 'lifestyle':
      return 'review and create';
    case 'main-image-product-on-white':
      return 'review and optimize';
    default:
      return 'learn more';
  }
});

const toListingAction = computed(() => {
  return {
    name: 'listing-insights-actions',
    params: { actionSlug: listingAction.value.listing_action_slug },
  };
});

const missingContentIcons = [
  'house-tree',
  'user-heart-alt-1',
  'closeup',
  'colors',
  'ruler-triangle',
];
</script>

<template>
  <component :is="tagName">
    <router-link class="listing-action-card" :to="toListingAction">
      <div
        class="listing-action-card__content listing-action-card__content--top"
      >
        <div class="listing-action-card__header">
          <div class="listing-action-card__header--count u-headline--heavy">
            {{ listingAction.number_of_actions.toLocaleString() }}
            <span
              class="listing-action-card__header--action-type u-label--regular"
            >
              {{ actionType }}
            </span>
          </div>
          <div class="listing-action-card__header--icon">
            <SoonaIcon :name="useRuleIcon(listingAction.listing_action_slug)" />
          </div>
        </div>
        <div class="listing-action-card__action-text u-body--heavy">
          {{ listingAction.action_text }}
        </div>
        <SoonaFlag
          v-if="quickFix"
          title="quick fix"
          type="pill"
          :background-color="BubbletapePink20"
        />
      </div>

      <div
        class="listing-action-card__content listing-action-card__content--bottom"
      >
        <ul class="listing-action-card__images">
          <template
            v-if="listingAction.listing_action_slug === 'missing-content'"
          >
            <li
              v-for="(icon, i) in missingContentIcons"
              :key="i"
              class="listing-action-card__image listing-action-card__missing-content-image"
            >
              <SoonaIcon :name="icon" />
            </li>
          </template>
          <template v-else>
            <li
              v-for="(image, i) in listingAction.top_images"
              :key="i"
              class="listing-action-card__image"
            >
              <img :src="image" alt="listing image" />
            </li>
          </template>
        </ul>
        <div class="listing-action-card__cta">
          {{ ctaText }}
          <SoonaIcon
            class="listing-action-card__cta--icon"
            name="arrow-right"
            size="medium"
          />
        </div>
      </div>
    </router-link>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';

.listing-action-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  padding: 1.5rem;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  box-shadow: variables.$elevation-0;
  width: 17rem;
  height: 100%;
  position: relative;
  margin-right: 1rem;

  &:hover {
    background-color: variables.$gray-10;
  }

  &:focus-visible {
    outline: 0.125rem solid variables.$periwink-blue-60;
    outline-offset: -0.125rem;
  }

  &:active {
    background-color: variables.$gray-20;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    &--bottom {
      overflow: hidden;
      margin-right: -1.5rem;
    }
  }

  &__header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;

    &--count {
      display: flex;
      flex-flow: row wrap;
      align-items: baseline;
      gap: 0.25rem;
      font-size: 2.25rem;
    }

    &--icon {
      background-color: variables.$periwink-blue-20;
      color: variables.$periwink-blue-70;
      border-radius: 50%;
      padding: 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  &__images {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    width: fit-content;
  }

  &__image {
    width: 3rem;
    height: 3rem;
    border-radius: 0.25rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__missing-content-image {
    background-color: variables.$gray-20;
    display: flex;
    justify-content: center;
    align-items: center;
    color: variables.$gray-60;
    border: 0.0625rem dashed variables.$gray-60;
  }

  &__cta {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    color: variables.$gray-70;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: 1;
    }

    &--icon {
      transition: transform 0.1s ease-out;
    }

    &:hover,
    &:focus-visible {
      .listing-action-card__cta--icon {
        transform: translateX(0.1875rem);
      }
    }
  }
}
</style>
