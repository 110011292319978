import io from 'socket.io-client';
import { info, error, warn } from './logger';
import { StoreEmitter, QueryEmitter, ToastEmitter } from './emitter';
import Listener from './listener';
import { uuidv4 } from '@/lib/uuid';

export const MESSAGE_SUBSCRIPTIONS = {
  albumcollectiondigitalasset: { query: true },
  animatedcollectiondigitalasset: {},
  bagcollectiondigitalasset: { query: true },
  collectionitem: {
    stores: ['dashboard', 'order'],
  },
  collectiondigitalasset: {},
  scorelistingtask: { query: true },
  digitalasset: { query: true },
  digitalassetpublishtask: { query: true },
  digitalassetbulkpublishmanager: { query: true },
  digitalassetbulkactiontask: { query: true },
  editscollectiondigitalasset: { query: true },
  embeddingcvservicetaskmanager: { query: true },
  favoritescollectiondigitalasset: { query: true },
  importtask: { query: true },
  integration: { query: true },
  questtask: { query: true },
  reeditscollectiondigitalasset: { query: true },
  reservation: { query: true, stores: ['reservation'] },
  reservationdigitalasset: { query: true },
  note: { query: true },
  reservationfile: { query: true },
  reservationlineitem: { query: true },
  staffpickscollectiondigitalasset: { query: true },
  uncropcvservicetask: { query: true },
};

export default class VueSocketManager {
  constructor({ store, queryClient, queryKeys, soonaToaster }) {
    if (!store) error('vuex store required');

    this.store = store;
    this.queryClient = queryClient;
    this.queryKeys = queryKeys;
    this.connections = new Map();
    this.soonaToaster = soonaToaster;
    /**
     * @description Map of anytime tokens to a Set of component subscription ids
     * @type {Map<string, Set<string>>}
     */
    this.subscriptions = new Map();
  }

  install(app) {
    app.provide('$socket', this);

    info('installed on app');
  }

  /**
   * @private
   * @param {string} anytimeToken
   * @param {Object} [subscriptions]
   * @returns {Promise<void>}
   */
  async connect(anytimeToken, subscriptions = MESSAGE_SUBSCRIPTIONS) {
    if (!this.isConnected(anytimeToken) && import.meta.env.VITE_ANYTIME_HOST) {
      try {
        const connection = await io(import.meta.env.VITE_ANYTIME_HOST, {
          transportOptions: {
            polling: {
              extraHeaders: {
                Authorization: anytimeToken,
              },
            },
          },
        });

        const storeEmitter = new StoreEmitter(this.store, subscriptions);
        const queryEmitter = new QueryEmitter(
          this.queryClient,
          this.queryKeys,
          subscriptions
        );
        const toastEmitter = new ToastEmitter(this.soonaToaster);
        this.connections.set(anytimeToken, {
          io: connection,
          listener: new Listener(connection, [
            storeEmitter,
            queryEmitter,
            toastEmitter,
          ]),
        });

        info(`added new connection for user with id - ${anytimeToken}`);
      } catch (e) {
        warn(e);
        error(
          `failed to establish connection for user with id - ${anytimeToken}`
        );
      }
    }
  }

  /**
   * @private
   * @param {string} anytimeToken
   * @returns {boolean}
   */
  isConnected(anytimeToken) {
    return this.connections.has(anytimeToken);
  }

  /**
   * @private
   * @param {string} anytimeToken
   */
  disconnect(anytimeToken) {
    if (this.isConnected(anytimeToken)) {
      this.connections.get(anytimeToken).io.disconnect();
      this.connections.delete(anytimeToken);
      info(`removed connection for user with id - ${anytimeToken}`);
    } else {
      warn(
        `attempted to disconnect from non-existent connection user: ${anytimeToken}`
      );
    }
  }

  /**
   *
   * @param {string} anytimeToken
   * @returns {Function} unregister function
   */
  register(anytimeToken) {
    // no token, no problem
    if (!anytimeToken) {
      return () => {};
    }

    const id = uuidv4();

    if (this.subscriptions.has(anytimeToken)) {
      // if duplicate, a Set ignores the new addition
      this.subscriptions.get(anytimeToken).add(id);
    } else {
      this.subscriptions.set(anytimeToken, new Set([id]));
      /*
       * only connect on the first subscription. this should protect against
       * race conditions as we're updating the subscriptions before connecting.
       */
      this.connect(anytimeToken);
    }

    return () => {
      if (!this.subscriptions.has(anytimeToken)) {
        error(
          `attempted to unregister id [${id}] from non-existent token [${anytimeToken}]`
        );
        return;
      }
      const subscriptionSet = this.subscriptions.get(anytimeToken);
      subscriptionSet.delete(id);

      if (subscriptionSet.size === 0) {
        this.subscriptions.delete(anytimeToken);
        this.disconnect(anytimeToken);
      }
    };
  }
}
