<script setup>
import { provide, toRefs } from 'vue';

import { RADIO_BUTTON_GROUP_KEY } from './radio-button-group-key';

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  labelVisuallyHidden: {
    type: Boolean,
    default: true,
  },
  name: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const { disabled, name } = toRefs(props);
const modelValue = defineModel({ type: [String, Number] });

provide(RADIO_BUTTON_GROUP_KEY, {
  name,
  modelValue,
  disabled: disabled,
  changeModelValue: value => {
    modelValue.value = value;
  },
});
</script>

<template>
  <fieldset class="radio-card-root" :disabled="disabled">
    <legend :class="{ 'u-a11y-only': labelVisuallyHidden }">{{ label }}</legend>

    <slot :model-value="modelValue" />
  </fieldset>
</template>

<style lang="scss" scoped>
@use '@/variables';

.radio-card-root {
  gap: 0.125rem;
  display: flex;
  padding: 0.25rem;
  flex-direction: row;
  border-radius: 2.125rem;
  background: variables.$gray-20;
}
</style>
