<script setup>
import { computed, ref } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import ReturnAddressModal from './ReturnAddressModal.vue';
import SelectReturnAddress from './SelectReturnAddress.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import { useAddresses } from 'src/queries/useAddresses';
import { useUpdateAddress } from 'src/queries/useUpdateAddress';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const accountId = computed(() => props.accountId);

const { data: addressData, isLoading } = useAddresses(accountId);

const addresses = computed(() => addressData.value?.addresses || []);

const defaultAddress = computed(() => addressData.value?.default || undefined);

const newAddressModalOpen = ref(false);

function closeModal() {
  newAddressModalOpen.value = false;
}
function openModal() {
  newAddressModalOpen.value = true;
}

const {
  mutate,
  isPending: isMutating,
  isError: updateError,
} = useUpdateAddress(accountId);

const setDefaultReturnAddress = address => {
  const selectedAddress = addresses.value.find(x => x.id === address);
  mutate({ ...selectedAddress, is_default: true });
};
</script>

<template>
  <ReturnAddressModal
    v-if="newAddressModalOpen"
    :account-id="accountId"
    :is-forced-default="defaultAddress === undefined"
    @cancel="closeModal"
    @success="closeModal"
  />
  <div class="manage-return-address" v-bind="$attrs">
    <div class="manage-return-address__emoji">📬</div>
    <div class="manage-return-address__body-wrapper">
      <div class="manage-return-address__header">return address</div>
      <div class="manage-return-address__body">
        <span class="manage-return-address__label">
          choose “send it back to me” on any package below to get it returned!
        </span>
        <br />
        <span class="manage-return-address__body-text">
          $20 handling + cost of shipping.
        </span>
      </div>
      <div v-if="!isLoading" class="manage-return-address__actions">
        <SoonaButton
          v-if="!defaultAddress"
          variation="secondary-black"
          @click="openModal"
        >
          add return address
        </SoonaButton>
        <div v-else class="manage-return-address__default-return-address">
          <div class="manage-return-address__default-return-address-label">
            default return address
          </div>
          <SelectReturnAddress
            :model-value="defaultAddress.id"
            :account-id="accountId"
            :disabled="isMutating"
            @update:model-value="setDefaultReturnAddress"
          />
          <SoonaError v-if="updateError">
            oops, something went wrong! please try again.
          </SoonaError>
          <div class="manage-return-address__default-return-address-text">
            your default return address will be automatically assigned to new
            packages, but packages can be individually re-assigned below.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.manage-return-address {
  background: variables.$periwink-blue-10;
  border: 1px solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  flex-direction: row;

  &__body-wrapper {
    margin-left: 0.625rem;
  }

  &__header {
    @include variables_fonts.u-subheader--heavy;
    color: variables.$black-default;
  }

  &__label {
    @include variables_fonts.u-label--heavy;
  }

  &__body-text {
    @include variables_fonts.u-body--regular;
  }

  &__actions {
    margin-top: 1.25rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
  }

  &__default-return-address-label {
    @include variables_fonts.u-label--heavy;
    margin-bottom: 0.4375rem;
  }

  &__default-return-address-text {
    @include variables_fonts.u-label--regular;
    margin-top: 1rem;
  }
}
</style>
