<script setup>
import { computed, onMounted, ref } from 'vue';
import { useTitle } from '@vueuse/core';
import AutoOpenSubscriptionsDialog from '@/components/platform-home/AutoOpenSubscriptionsDialog.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaSubscriptionBadge from '@/components/ui_library/SoonaSubscriptionBadge.vue';
import SubscriptionProductTiers from '@/components/subscriptions/SubscriptionProductTiers.vue';
import ChatBannerV2 from '@/components/modal-payment-flows/subscriptions/ChatBannerV2.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { useCapability } from '@/composables/useCapability';
import { useAccount } from '@/composables/useAccount';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { PeriwinkBlue20, PeriwinkBlue80 } from 'src/variables.module.scss';
import { useFlag } from '@/composables/useFlag';
import { convertToMMDDYYYY } from '@/lib/date-formatters';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
});

const { pageViewed } = useBaseEvents();
const route = useRoute();

useTitle('manage plan | soona');

const accountId = computed(() => props.accountId);
const { account } = useAccount(accountId);
const context = computed(() => route.meta?.context);
const subContext = 'chat with us banner';

onMounted(() => {
  pageViewed();
});

const subscriptionUpgradesFlag = useFlag('apollo_subscription_upgrades');

// trialing ff check
const pegasusFastPassOnTrialFlag = useFlag('pegasus_fast_pass_on_trial');

const isTrialing = computed(() => account.value?.is_trialing);

// addons
const activeAddOnSubscriptionItem = computed(() => {
  if (!account.value?.subscription) return null;

  // we only have 1 add on, but we'll need to refactot this later if we have more
  return account.value?.subscription.subscription_items.find(
    x => x.subscription_item_type === 'add_on' && x.status === 'active'
  );
});

const pendingCancelAddOnSubscriptionItem = computed(() => {
  if (!account.value?.subscription) {
    return null;
  }
  // we only have 1 add on, but we'll need to refactor this later if we have more
  return account.value?.subscription.subscription_items.find(
    x =>
      x.subscription_item_type === 'add_on' &&
      x.status === 'pending_cancelation'
  );
});

const { hasCapability: canManageAccountSubscription } = useCapability({
  capability: 'manage_account_subscription',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: canCrewManageSubscription } = useCapability({
  capability: 'crew_manage_subscription',
});

const showPaywallDialog = ref(false);
const subscriptionTier = ref('tier-one');

const subscriptionItemEndCopy = computed(() => {
  // we only have 1 add on, but we'll need to refactot this later if we have more
  let fastpassItem = account.value?.subscription?.subscription_items.find(
    x =>
      x.subscription_item_type === 'add_on' &&
      x.status === 'pending_cancelation'
  );

  let planInfo = {};
  if (pendingCancelAddOnSubscriptionItem.value) {
    planInfo.buttonCopy = 'pending cancellation';
    planInfo.endDateCopy = 'ends';
    planInfo.endDate = fastpassItem.ended_at;
  }

  return planInfo;
});
</script>

<template>
  <div class="subscriptions-page">
    <SoonaBackButton
      element="router-link"
      :to="`/account/${accountId}/profile`"
      button-text="profile"
    />
    <h1 class="u-headline--heavy subscriptions-page__headline">manage plan</h1>
    <SubscriptionProductTiers
      :account-id="accountId"
      @show-paywall-dialog="() => (showPaywallDialog = true)"
      @set-subscription-tier="slug => (subscriptionTier = slug)"
    />
    <ChatBannerV2
      :context="context"
      :sub-context="subContext"
      :full-page="true"
    />
    <div v-if="(isTrialing && pegasusFastPassOnTrialFlag) || !isTrialing">
      <h2 class="u-body--heavy">add ons</h2>
      <div class="subscriptions-page__add-on">
        <SoonaFlag
          v-if="
            activeAddOnSubscriptionItem || pendingCancelAddOnSubscriptionItem
          "
          class="subscriptions-page__add-on--enrolled-flag"
          :background-color="PeriwinkBlue20"
          :text-color="PeriwinkBlue80"
          title="enrolled"
        />
        <div class="subscriptions-page__add-on--info">
          <SoonaSubscriptionBadge
            icon-name="box-archive"
            icon-size="large"
            tier-slug="fast-pass"
            tier-title="fast pass"
          />
          <div>
            <h3 class="u-body--heavy">fast pass</h3>
            <p>
              store your inventory at the soona studios and enjoy priority
              scheduling
            </p>
          </div>
        </div>
        <template
          v-if="canManageAccountSubscription || canCrewManageSubscription"
        >
          <SoonaButton
            v-if="
              pendingCancelAddOnSubscriptionItem && subscriptionUpgradesFlag
            "
            class="subscription-product-tiers__current-tier--cancel"
            variation="tertiary"
          >
            re-enroll
          </SoonaButton>
          <SoonaButton
            v-else-if="activeAddOnSubscriptionItem"
            class="subscription-product-tiers__current-tier--cancel"
            variation="tertiary"
            element="router-link"
            :to="`/account/${accountId}/cancel-fast-pass`"
          >
            cancel add-on
          </SoonaButton>
          <SoonaButton
            v-else-if="!pendingCancelAddOnSubscriptionItem"
            variation="solid-black"
            element="router-link"
            :to="`/account/${accountId}/subscriptions/add-ons/fast-pass`"
          >
            learn more
          </SoonaButton>
          <div
            v-if="pendingCancelAddOnSubscriptionItem"
            class="subscriptions-page__pending-cancel"
          >
            <SoonaFlag
              :background-color="PeriwinkBlue20"
              :text-color="PeriwinkBlue80"
              :title="subscriptionItemEndCopy.buttonCopy"
            />
            <p class="u-label--small">
              {{ subscriptionItemEndCopy.endDateCopy }}
              {{ convertToMMDDYYYY(subscriptionItemEndCopy.endDate) }}
            </p>
          </div>
        </template>
      </div>
    </div>
    <AutoOpenSubscriptionsDialog />
    <SubscriptionsDialog
      v-if="showPaywallDialog"
      :selected-tier-slug="subscriptionTier"
      flow="checkoutOnly"
      @close="() => (showPaywallDialog = false)"
    />

    <SoonaLink
      :to="`/account/${accountId}/orders/subscription-invoices`"
      variation="black"
    >
      view billing history
      <SoonaIcon name="arrow-right" size="small" />
    </SoonaLink>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
.subscriptions-page {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__headline {
    margin-top: -1.5rem;
  }

  &__add-on {
    margin-top: 0.75rem;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    position: relative;

    &--info {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    &--enrolled-flag {
      position: absolute;
      top: -0.75rem;
      left: 1rem;
    }

    > :deep(.soona-button) {
      flex-shrink: 0;
    }
  }

  &__pending-cancel {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
  }

  @media (min-width: variables.$screen-sm-min) {
    &__add-on {
      padding: 2rem 1rem;
      flex-wrap: nowrap;
    }
  }

  .chat-banner {
    color: variables.$black-default;
  }
}
</style>
