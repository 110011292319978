import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';
import { useStore } from 'vuex';

/**
 *
 * @param {number | Ref<Number>} orderId
 * @param {Object} [params]
 */
export function useOrder(orderId, params) {
  const store = useStore();
  return useQuery({
    queryKey: queryKeys.order(orderId, params),
    queryFn: async ({ signal }) => {
      const response = await http.get(`orders/${toValue(orderId)}`, {
        params,
        signal,
      });

      store.commit('reservation/SET_ADDITIONAL_CHARGE_ORDER', response.data);

      return response.data;
    },
  });
}
